<template>
  <div>
    <PageBanner pageTitle="就医指南" :type="type"/>
    <medical-guidelines/>

  </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import medicalGuidelines from "@/components/about-us/MedicalGuidelines.vue";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";


export default {
  components: {
    PageBanner,
    medicalGuidelines,
    /*FunFacts,
    OurDentist,
    Pricing,
    OurClients,*/
  }, data: () => {
    return {
      type: ''
    }
  },
  created() {
    this.type = this.$route.query?.type
    if (this.$route.path.startsWith("/app")&&this.$route.query&&this.$route.query.openid) {
      useUserXcxStore().setOpenid(this.$route.query?.openid)
    }
  }
}
</script>
