<template>
  <div class="doctor-area" style="padding-bottom: 50px">
    <div v-if="isDesktop" class="container max-width-cust">
      <!--      <div class="section-title">-->
      <!--        <span class="sub-title">-->
      <!--          <i class="flaticon-hashtag-symbol"></i>-->
      <!--          特色科室-->
      <!--        </span>-->
      <!--        <h2 id="scroll">院内的特色科室</h2>-->
      <!--      </div>-->

      <div class="row" >

        <div class="title-container">
          <div class="title">院内的特色科室</div>
          <div class="underline"></div>
        </div>

        <!--        <div class="col-lg-3 col-md-6" v-for="(roomInfo, index) in RoomInfos" :key="roomInfo.title">-->

        <!--          <div class="single-doctor">-->
        <!--            <div class="doctor-content">-->
        <!--              <h3>-->
        <!--                <router-link-->
        <!--                  :to="{ path: detailUrl, query: { index: (pagination.pageNum - 1) * pagination.pageSize + index, 'type': source } }">-->
        <!--                  {{ roomInfo.title }}-->
        <!--                </router-link>-->
        <!--              </h3>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="col-lg-3 col-md-6 d-flex" v-for="(roomInfo, index) in RoomInfos" :key="roomInfo.title">

          <div class="single-core-features center-class">
            <router-link
                :to="{ path: detailUrl, query: { index: (pagination.pageNum - 1) * pagination.pageSize + index, 'type': source } }">

              <div class="d-flex justify-content-center">
                <div class="image-container" style="flex-shrink: 0">
                  <img alt="avatar" :src="roomInfo.cover" class="img-fluid">
                </div>
              </div>
              <div class="room-name" style="font-size: 1rem; padding-top: 1rem;text-align: center;">
                {{ roomInfo.title}}
              </div>
              <div class="room-name" style="font-size: 0.8rem;text-align: center;">
                {{ roomInfo.desc}}
              </div>

            </router-link>

          </div>

        </div>


        <!--                分页-->
        <div class="col-lg-12 col-md-12">
          <div class="pagination-area">
            <a class="prev page-numbers" v-if="pagination.pageNum !== 1" @click="handlePageNum(-1, true)">
              <i class='flaticon-left-arrow'></i>
            </a>
            <span v-for="curPage in pagination.pages">
              <span class="page-numbers current" aria-current="page" v-if="pagination.pageNum === curPage">{{
                  curPage
                }}</span>
              <a class="page-numbers" v-if="pagination.pageNum !== curPage" @click="handlePageNum(curPage, false)">{{
                  curPage
                }}</a>
            </span>
            <a class="next page-numbers" v-if="pagination.pageNum !== pagination.pages" @click="handlePageNum(1, true)">
              <i class='flaticon-right-arrow'></i>
            </a>
          </div>
        </div>
      </div>


    </div>
    <div v-else class="container d-flex justify-content-center">
      <div>
        <router-link :to="{ path: detailUrl, query: { index , 'type': source} }" v-for="(roomInfo, index) in RoomInfos"
                     :key="roomInfo.title" class="d-flex gap-3" style="padding-top: 1rem" aria-current="true">
          <div class="border-bottom d-flex gap-3" style="width: 100%; padding-bottom: 1rem">
            <div class="d-flex align-items-center">
              <div class="image-container-little" style="flex-shrink: 0">
                <img alt="avatar" :src="roomInfo.cover" class="img-fluid">
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-between">
              <div class="d-flex  flex-column">
                <div class="mb-0 title-class" style="font-size: 1rem">{{ roomInfo.title }}</div>
                <div class="mb-0 desc-class">{{ roomInfo.desc }}</div>
              </div>
            </div>
          </div>
        </router-link>


<!--        <div class="col-lg-6 col-md-6 d-flex justify-content-center"  v-for="(roomInfo, index) in RoomInfos" :key="roomInfo.title">-->

<!--          <div class="single-core-features center-class" >-->
<!--            <router-link-->
<!--                :to="{ path: detailUrl, query: { index: (pagination.pageNum - 1) * pagination.pageSize + index, 'type': source } }">-->

<!--              <div class="d-flex justify-content-center">-->
<!--                <div class="image-container" style="flex-shrink: 0">-->
<!--                  <img alt="avatar" :src="roomInfo.cover" class="img-fluid">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="room-name" style="font-size: 1.3rem; padding-top: 1rem;text-align: center">-->
<!--                {{ roomInfo.title}}-->
<!--              </div>-->

<!--            </router-link>-->

<!--          </div>-->

<!--        </div>-->




        <div v-if="load" class="d-flex justify-content-center mt-3" style="color: #492b10" @click="loadMore">
          点击加载更多科室信息
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {getDoctorInfo, getJSDoctorInfo} from "@/api/doctor/doctorApi";
import {useRoute} from "vue-router";

const props = defineProps({
  name: {
    type: String,
    default: 'Rooms'
  },
  source: {
    type: String,
    default: ''
  }
});
let load = ref(true)

// console.log('rooms = ', this.type)
const route = useRoute()
const detailUrl = computed(() => {
  if (!route.path.startsWith('/app')) {
    return "/room-details";
  } else {
    return "/app/room-details";
  }
})
const windowWidth = ref(window.innerWidth);
const handleResize = () => {
  windowWidth.value = window.innerWidth; // 更新窗口宽度
  if (isDesktop.value) {
    handlePageNum(pagination.value.pageNum, false)
  } else {
    RoomInfos.value = wholeRooms.slice(0, Math.min(wholeRooms.length, 8));
  }
};


function loadMore(){
  console.log("====")
  load.value = false;
  RoomInfos.value = wholeRooms;
}


const isDesktop = computed(() => windowWidth.value >= 768);

const pagination = ref({
  pageNum: 1,
  pageSize: 8,
  total: 0,
  pages: 1,
})
let wholeRooms = [];
const RoomInfos = ref([]);
const curPageNum = route.query.page;


onMounted(() => {
  window.addEventListener('resize', handleResize); // 添加事件监听器
  getJSDoctorInfo()
      .then((data) => {
        // 这里处理成功返回的数据
        wholeRooms = data.data[1].contents;
        if (isDesktop.value) {
          RoomInfos.value = wholeRooms.slice(0, pagination.value.pageSize);
          pagination.value.total = data.data[1].contents.length;
          if(data.data[1].contents.length % pagination.value.pageSize==0){
            pagination.value.pages = Math.floor(data.data[1].contents.length / pagination.value.pageSize);
          }else{
            pagination.value.pages = Math.floor(data.data[1].contents.length / pagination.value.pageSize) + 1;
          }
          // pagination.value.pages = Math.floor(data.data[1].contents.length / pagination.value.pageSize) + 1;
          if (curPageNum) {
            handlePageNum(curPageNum, false);
          }
        } else {
          RoomInfos.value = wholeRooms.slice(0, Math.min(wholeRooms.length, 8));
        }
      })
      .catch((error) => {
        // 这里处理错误
        console.error('Failed to get doctor info:', error);
      });
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize); // 移除事件监听器
});

function handlePageNum(pageNum, isArrow) {
  if (isArrow) {
    if (pageNum === 1) {
      pagination.value.pageNum++;
    } else if (pageNum === -1) {
      pagination.value.pageNum--;
    }
  } else {
    pagination.value.pageNum = parseInt(pageNum);
  }
  const pageSize = pagination.value.pageSize
  RoomInfos.value = wholeRooms.slice((pagination.value.pageNum - 1) * pageSize, pagination.value.pageNum * pageSize);
  document.getElementById('scroll').scrollIntoView()
}
</script>
<style scoped>
.title-container {
  width: 100%;
  text-align: left;
  //margin: 20px 0;
  margin-bottom: 20px;
  position: relative;
}

.title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
}

.underline {
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #804e22;
  z-index: -1;
}

.image-container {
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.image-container-little {
  width: 50px; /* 固定宽度 */
  height: 50px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.image-container-little img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.center-class {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.room-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-width-cust{
  max-width: 57rem;
}

@media (max-width: 992px) {
  .max-width-cust{
    max-width: 45rem;
  }
}
.desc-class {
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  //line-height: 1.8;
  //max-height: 3.6em;
}

@media (max-width: 767.98px) {
  .page-banner-content .pages-list {
    margin-left: -5.5%;
  }
}

</style>