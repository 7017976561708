<template>
    <div class="services-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">
                    <i class="flaticon-hashtag-symbol"></i>
                    Our Best Dental Services
                </span>
                <h2>Enjoy Specialized Care Through Precision, Artistry, and Experience</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-1.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-chair"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    General Dentistry
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-2.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-dental-care"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Cosmetic Dentistry
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-3.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-dental-implant"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Dental Implants
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-4.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-tooth"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Orthodontics
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-5.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-tooth-1"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Teeth Whitening
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-6.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-tooth-2"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Teeth Cleaning
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="/services" class="prev page-numbers">
                            <i class='flaticon-left-arrow'></i>
                        </a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="/services" class="page-numbers">2</a>
                        <a href="/services" class="page-numbers">3</a>
                        <a href="/services" class="page-numbers">4</a>
                        <a href="/services" class="page-numbers">5</a>
                        <a href="/services" class="next page-numbers">
                            <i class='flaticon-right-arrow'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DentalServices'
}
</script>