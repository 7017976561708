<template>
  <div>
    <footer class="footer-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget">
              <h2 style="font-size: 1.7rem">
                <router-link to="/">
                  北京京师中医医院
                </router-link>
              </h2>
              <p style="font-weight: bold">精致细节、诚信为上、贴心服务、换位思考</p>
<!--              <img style="padding:20px;" src='@/assets/微信截图_20240911145917.png'>-->
              <div class="image-container111">
                <img class="image-item" style="max-width: 140px" src='@/assets/jsgzh.jpg'>
                <img class="image-item" style="max-width: 158px" src='@/assets/jsxcx.png'>
              </div>


              <!--                            <div class="signature">-->
              <!--                                <img src="../../assets/images/footer/signature.png" alt="image">-->
              <!--                            </div>-->

              <!--                            <div class="footer-info">-->
              <!--                                <img src="../../assets/images/footer/footer-1.jpg" alt="image">-->
              <!--                                <h4>Dr. Thomas Albin</h4>-->
              <!--                                <span>CEO & Founder</span>-->
              <!--                            </div>-->
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
              <h3>快捷导航</h3>
              <div class="row">
                <div class="col-5"> <!-- 第一列 -->
                  <ul class="quick-links">
                    <li>
                      <router-link to="/about-us">医院简介</router-link>
                    </li>
                    <li>
                      <router-link to="/blog">新闻动态</router-link>
                    </li>
                    <li>
                      <router-link to="/dentist">专家介绍</router-link>
                    </li>
                    <li>
                      <router-link to="/rooms">特色科室</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-7"> <!-- 第二列 -->
                  <ul class="quick-links">
                    <li>
                      <router-link to="/privacy-policy">就医指南</router-link>
                    </li>
                    <li>
                      <router-link to="/services">来院导航</router-link>
                    </li>
                    <li>
                      <router-link to="/dentist">预约挂号</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
              <h3>近期动态</h3>

              <div class="footer-widget-blog" v-for="(info, index) in news" :key="info.title">
                <article class="item">
                  <router-link :to="{ path: detailUrl, query: { index: info.article_id }}" class="thumb" style="margin-top: 8px">
                                        <span class="fullimage" role="img">
                                          <img :src="info.thumb_url" style="border-radius: 0.3rem" alt="image">
                                        </span>
                  </router-link>
                  <div class="info">
                    <h4>
                      <router-link :to="{ path: detailUrl, query: { index: info.article_id }}">
                        <div style="padding-top: 3px" class="desc-class">
                          {{ info.title }}
                        </div>
                      </router-link>
                    </h4>
                  </div>
                </article>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
              <h3>联系方式</h3>

              <ul class="footer-information">
                <li>
                  <i class="flaticon-emergency-call"></i>
                  联系电话
                  <span><a>{{ phone }}</a></span>
                </li>

                <li>
                  <i class="flaticon-wall-clock"></i>
                  开放时间
                  <span>08:00 AM to 17:00 PM</span>
                </li>

                <li>
                  <i class="flaticon-red-cross"></i>
                  地址
                  <span>{{ address }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="copyright-area">
      <div class="container">
        <div class="copyright-area-content">
          <p>
            &copy; {{ currentYear }}
            北京京师中医医院
            <!--                      jszyy is proudly created by-->
            <!--                        <a href="https://hibootstrap.com" target="_blank">-->
            <!--                            HiBootstrap-->
            <!--                        </a>-->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref} from "vue";
import {getDoctorInfo} from "@/api/doctor/doctorApi";
import {useRoute} from "vue-router";
import Api from "@/request/api";

defineProps({
  name: {
    type: String,
    default: 'Rooms'
  }
});
const route = useRoute()
const detailUrl = computed(() => {
  if (!route.path.startsWith('/app')) {
    return "/blog-details";
  } else {
    return "/app/blog-details";
  }
})
const currentYear = ref(new Date().getFullYear());
const phone = ref();
const address = ref();
const news = ref([]);
onMounted(() => {
  getDoctorInfo().then((res) => {
    phone.value = res.data[3].contents[0].attributes.phone;
    address.value = res.data[3].contents[0].attributes.address;
    console.log(news.value);
  })
  Api.findNewsList(3, 0)
      .then(res => {
        console.log("新闻接口返回", res);
        news.value = res.data

      })
})
</script>
<style scoped>
.desc-class {
  //color: #818181;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  //line-height: 1.8;
  //max-height: 3.6em;
}
.image-container111 {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 中间对齐 */
  align-items: center; /* 垂直方向对齐 */
  gap: 20px; /* 添加间距，使图像间距变大 */
}

.image-item {
  padding: 10px; /* 给图像添加间距 */
  //max-width: 150px; /* 设置最大宽度以控制图像大小 */
  height: auto; /* 保持图像纵横比 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 给图像添加阴影效果 */
  border-radius: 8px; /* 给图像添加圆角效果 */
}


</style>