<template>
  <div>
    <PageBanner pageTitle="新闻动态详情" secondPageTitle="新闻动态" :secondPageUrl="secondPageUrl" :type="type"/>
    <!-- <PageBanner v-else pageTitle="新闻动态详情" :type="type"/> -->
    <BlogDetails @sendPage="sendPage" :source="type"/>
  </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import BlogDetails from '../Blog/BlogDetails.vue'
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

export default {
  components: {
    PageBanner,
    BlogDetails,
  },
  data() {
    return {
      secondPageUrl: {
        url: '',
        query: {page: 1},
      },
      type: ""
    }
  },
  methods: {
    sendPage(data) {
      this.secondPageUrl.query.page = data;
    }
  },
  created() {
    this.type = this.$route.query?.type
    const route = this.$route;
    if (route.path.startsWith('/app')) {
      this.secondPageUrl.url = '/app/blog?type=' + this.type;
      this.secondPageUrl.query = {page: 1, type: this.type};
    } else {
      this.secondPageUrl.url = '/blog';
    }
    if (this.$route.path.startsWith("/app")&&this.$route.query&&this.$route.query.openid) {
      useUserXcxStore().setOpenid(this.$route.query?.openid)
    }
  },
  computed: {
    isAppRoute() {
      return this.$route.path.startsWith('/app');
    }
  }
}
</script>
