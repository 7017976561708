<template>
    <div class="pricing-area pt-100 pb-70">
        <div class="container">
            <div class="pricing-list-tab">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            {{tab.name}}
                        </li>
                    </ul>
                </div>
                <div class="tabs__container">
                    <div 
                        class="tabs__list"
                        ref='tabsList'
                    >
                        <div 
                            class="tabs__list-tab"
                            v-for="tab in tabs"
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <div class="row">
                                <div 
                                    class="col-lg-3 col-sm-6"
                                    v-for="item in tab.items"
                                    :key="item.id"
                                >
                                    <div class="single-pricing-table">
                                        <div class="pricing-header">
                                            <h3>{{item.title}}</h3>
                                        </div>

                                        <div class="price">${{item.price}}</div>

                                        <ul class="pricing-features">
                                            <li
                                                v-for="features in item.pricingFeatures"
                                                :key="features.id"
                                                :class="features.class"
                                            >
                                                {{features.feature}}
                                            </li>
                                        </ul>

                                        <div class="pricing-btn">
                                            <router-link :to="item.btnLink" class="default-btn">
                                                Buy Now
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    name: 'Monthly',
                    items: [
                        {
                            id: 1,
                            title: 'Basic',
                            price: '39',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: 'color-gray',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: 'color-gray',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: 'color-gray',
                                },
                            ]
                        },
                        {
                            id: 2,
                            title: 'Standard',
                            price: '59',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: '',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: 'color-gray',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: 'color-gray',
                                },
                            ]
                        },
                        {
                            id: 3,
                            title: 'Premium',
                            price: '79',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: '',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: '',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: 'color-gray',
                                },
                            ]
                        },
                        {
                            id: 4,
                            title: 'Gold',
                            price: '99',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: '',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: '',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: '',
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Yearly',
                    items: [
                        {
                            id: 1,
                            title: 'Basic',
                            price: '139',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: 'color-gray',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: 'color-gray',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: 'color-gray',
                                },
                            ]
                        },
                        {
                            id: 2,
                            title: 'Standard',
                            price: '259',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: '',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: 'color-gray',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: 'color-gray',
                                },
                            ]
                        },
                        {
                            id: 3,
                            title: 'Premium',
                            price: '279',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: '',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: '',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: 'color-gray',
                                },
                            ]
                        },
                        {
                            id: 4,
                            title: 'Gold',
                            price: '399',
                            btnLink: '/contact-us',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    feature: 'Cancer Services',
                                    class: '',
                                },
                                {
                                    id: 2,
                                    feature: 'Cardiovascular Services',
                                    class: '',
                                },
                                {
                                    id: 3,
                                    feature: 'Weight Management',
                                    class: '',
                                },
                                {
                                    id: 4,
                                    feature: 'Dental Services',
                                    class: '',
                                },
                                {
                                    id: 5,
                                    feature: 'Womens Health',
                                    class: '',
                                },
                                {
                                    id: 6,
                                    feature: 'Emergency Medicine',
                                    class: '',
                                },
                                {
                                    id: 7,
                                    feature: 'Family Medicine',
                                    class: '',
                                },
                                {
                                    id: 8,
                                    feature: 'Gynecologic Oncology',
                                    class: '',
                                },
                                {
                                    id: 9,
                                    feature: 'Neurosurgery',
                                    class: '',
                                },
                                {
                                    id: 10,
                                    feature: 'Radiation Oncology',
                                    class: '',
                                },
                            ]
                        },
                    ]
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>