<template>
    <div>
<!--        <MainBanner />-->
      <PageBanner pageTitle="首页"/>
      <Features class="bg-ffffff"/>
<!--        <Features />-->
        <blog-home v-if="isDesktop"/>
        <BlogMobileHome v-else/>
        <rooms-home v-if="isDesktop"/>
        <RoomsMobileHome v-else/>
        <doctor-home v-if="isDesktop"/>
        <DoctorMobileHome v-else/>
<!--        <DentalServices />-->
<!--        <NervousTroubled />-->
<!--        <Appointment />-->
<!--        <AboutHospital />-->
<!--        <SpecialtyFeatures />-->
<!--        <OurDentist />-->
<!--        <OurClients />-->
<!--        <Blog />-->
    </div>
</template>

<script>
import MainBanner from '../Index/MainBanner'
import PageBanner from '../Common/PageBanner'
import Features from '../Appointment/Features'
import DentalServices from '../Index/DentalServices'
import NervousTroubled from '../Index/NervousTroubled'
import Appointment from '../Index/Appointment'
import AboutHospital from '../Index/AboutHospital'
import SpecialtyFeatures from '../Index/SpecialtyFeatures'
import OurDentist from '../Common/OurDentist'
import OurClients from '../Common/OurClients'
import Blog from '../Common/Blog'
import BlogHome from "@/components/Blog/BlogHome.vue";
import RoomsHome from "@/components/Room/RoomsHome.vue";
import DoctorHome from "@/components/Doctor/DoctorHome.vue";
import DoctorMobileHome from "@/components/Doctor/DoctorMobileHome.vue";
import RoomsMobileHome from "@/components/Room/RoomsMobileHome.vue";
import BlogMobileHome from "@/components/Blog/BlogMobileHome.vue";
export default {
    name: 'HomePageOne',
    components: {
        MainBanner,
        Features,
        PageBanner,
        BlogHome,
        RoomsHome,
        DoctorHome,
        DoctorMobileHome,
      RoomsMobileHome,
      BlogMobileHome,
        DentalServices,
        NervousTroubled,
        Appointment,
        AboutHospital,
        SpecialtyFeatures,
        OurDentist,
        OurClients,
        Blog,
    },
    data() {
      return {
        windowWidth: window.innerWidth,
      };
    },
    computed: {
      isDesktop() {
        return this.windowWidth >= 768; // 适用于桌面
      },
    },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth; // 更新窗口宽度
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize); // 添加事件监听器
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize); // 移除事件监听器
  }
}
</script>
