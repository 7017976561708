<script setup>

import {onMounted, ref, defineProps, reactive, h, onBeforeUnmount} from "vue";
import router from "@/router";
import {useRoute} from "vue-router";
import Api from "@/request/api";
import {useAppointmentStore} from "@/stores/useAppointmentStore.ts";
import {ElMessageBox, ElNotification} from "element-plus";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";
import QrcodeVue from 'qrcode.vue';
import api from "@/request/api";
import wx from "weixin-js-sdk"

const route = useRoute();
const doctor = ref({})
let cxb = ref({})
let notes =ref("")
const patient = reactive({ data: {} });
const openid = route.query.openid
const msg = route.query.msg

if(msg){
  ElNotification({
  title: '预约挂号',
  message: h(
      'i',
      { style: 'color: black' },
      msg,
  ),
});
}

const props = defineProps({
  ysId: {
    type: String,
    required: true
  },
  czb_index: {
    type: String,
    required: true
  }
});



onMounted(() => {
  Api.getRegistrationNotice()
      .then((res) => {
        console.log(res)
        notes.value = res.data
      })
  // Api.getSchedulingDetail(useAppointmentStore().getYsId, useAppointmentStore().getCzbIndex)
  Api.getSchedulingDetail(useAppointmentStore().getScId)
      .then(res => {
        console.log(res)
        cxb.value = res.data
      })

  if(useAppointmentStore().isAppointment){
    Api.findPatientInfoBy_Id(useAppointmentStore().getPaId)
        .then(res => {
          console.log(res)
          patient.data = res.data
          console.log(patient)
        })
  }
})

onBeforeUnmount(() =>{
  useAppointmentStore().setOpenId('')
  useAppointmentStore().setDocumentNumber('')
  useAppointmentStore().setPaId('')
})

function toSelectPatient(){
  if (!route.path.startsWith('/app')) {
    router.push({ path: '/select-patient' })
  } else {
    router.push({ path: '/app/select-patient', query: {type: 'xcx', openid: openid}})
  }
}

function decodeHtmlEntities(text) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
}

const payButton = ref(false)
const qrCode = ref("")
const timers = ref(null)

function commityuyue(){

  //先判断有没有预约费
  //如果有预约费的话 就判断是小程序登录还是 pc登录
  //  小程序登录就调用h5支付的api 返回url跳转支付
  //  pc登录的就调用native支付, 返回二维码 扫码支付
  //如果没有预约费就直接普通预约就可以了

  const yuyue = ref({
    openId: patient.data.openId,
    ysxm: cxb.value.ysxm,
    jzrq : cxb.value.jzrq,
    czb_xh: cxb.value.xh,
    name: patient.data.name,
    phone: patient.data.phone,
    gender: patient.data.gender,
    documentType: patient.data.documentType,
    documentNumber: patient.data.documentNumber,
    address: patient.data.address,
    ksmc: cxb.value.ksmc,
    sxw: cxb.value.sxw,
    ghf: cxb.value.ghf,
    zhicheng: cxb.value.zhicheng,
    payerTotal: cxb.value.payerTotal,
    yyf: cxb.value.yyf,
    methods: ''
  })

  let title = ""
  console.log("预约信息", yuyue.value)
  if (cxb.value.payerTotal != 0){
    title = '预约该医师需要支付预约费，到医院取号预约费即退还，是否支付预约费进行预约挂号？'
  } else {
    title = '是否确认预约！'
  }

  ElMessageBox.confirm(title, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
      .then(() => {

        if (useAppointmentStore().getPaId == null || useAppointmentStore().getPaId == '' || useAppointmentStore().getPaId == undefined){
          ElNotification({
            title: '预约挂号',
            message: h(
                'i',
                { style: 'color: black' },
                "请先选择就诊人！",
            ),
          });
          return;
        }

        if (cxb.value.payerTotal != 0){
          //需要预约费 先试一下使用小程序的openId 如果有就是小程序登录的 如果没有就是pc登录的
          console.log('1111111')       

          if(useUserXcxStore().isLogin()){
            //小程序登录
            yuyue.value.methods = 'jsapi'
            console.log("yuyue.value = ",yuyue.value)
            Api.appointmentH5(yuyue.value)
              .then(res => {
                console.log("下单结果 => ", res)
                if (res.status == 1){
                  const methods = "jsapi"
                  wx.miniProgram.navigateTo({
                    url: '/pages/pay/pay?ts=' + res.data.timeStamp+ '&ns=' + res.data.nonceStr+ '&pk=' + encodeURIComponent(res.data.package)+ '&ps=' + encodeURIComponent(res.data.paySign)+ '&url=' + encodeURIComponent("app/yuyue-doctor"),
                    success: function () {
                      console.log("111")
                    },
                    fail: function () {
                      console.log("222")
                    }
                  })

                } else {
                  ElNotification({
                    title: '预约挂号',
                    message: h(
                        'i',
                        { style: 'color: black' },
                        res.data,
                    ),
                  });
                }
              })

            
          } else {
            //pc 登录
            yuyue.value.methods = 'native'
            Api.appointmentH5(yuyue.value)
              .then(res => {
                console.log("下单结果 => ", res)
                if (res.status == 1){
                  //这里订单号也传过来了
                  // const {h5Url, orderNo} = res.data
                  const {payUrl, methods, orderNo} = res.data
                  if (methods == "h5"){
                    window.location.href = payUrl;

                  } else if(methods == "native") {
                    qrCode.value = payUrl;
                    payButton.value = true;
                    timers.value = setInterval(() => {
                      console.log("执行定时器里面的操作")
                      api.findOrderStatus(orderNo)
                          .then((res) => {
                            console.log("查询支付情况 => ", res)
                            if (res.data == "支付成功"){
                              console.log('清除定时器')
                              clearInterval(timers.value)
                              timers.value = null
                              ElNotification({
                                title: '预约挂号',
                                message: h(
                                    'i',
                                    { style: 'color: black' },
                                    res.data,
                                ),
                              });

                              if (!route.path.startsWith('/app')) {
                                router.push({path: "/my-appointment"})
                              } else {
                                router.push({path: "/app/my-appointment", query: {type: 'xcx',openid:openid}})
                              }
                            }
                          })

                    }, 2000)
                  }

                } else {
                  ElNotification({
                    title: '预约挂号',
                    message: h(
                        'i',
                        { style: 'color: black' },
                        res.data,
                    ),
                  });
                }
              })
          }

         
        } else {
          //不需要预约费的普通预约
          Api.appointment(yuyue.value)
              .then(res => {
                console.log(res)
                if (res.data === '预约成功'){
                  ElNotification({
                    title: '预约挂号',
                    message: h(
                        'i',
                        { style: 'color: black' },
                        res.data,
                    ),
                  });
                  //跳转
                  if (!route.path.startsWith('/app')) {
                    router.push({path: "/my-appointment"})
                  } else {
                    router.push({path: "/app/my-appointment", query: {type: 'xcx',openid:openid}})
                  }

                } else {
                  ElNotification({
                    title: '预约挂号',
                    message: h(
                        'i',
                        { style: 'color: black' },
                        res.data,
                    ),
                  });
                }

              })

        }

      })
}

const cancelPay = () =>{
  payButton.value = false
  console.log("停止计时器")
  clearInterval(timers.value)
  timers.value = null
}

/*
timers.value = setInterval(() => {
  console.log("1111")
},2000)
*/

</script>

<template>
<!--  <div>-->
<!--    <button @click="payButton = true" type="button" class="btn btn-outline-secondary" style="margin-top: 10px;">-->
<!--      点击-->
<!--    </button>-->
<!--  </div>-->
<!--    <div>
      <button @click="cancelPay" type="button" class="btn btn-outline-secondary" style="margin-top: 10px;">
        点击
      </button>
    </div>-->

  <div v-if="payButton" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; z-index: 1000;">
    <div style="min-width: 20rem; padding: 20px; background-color: #fff; border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); border: 1px solid #3E3A4A;">
      <div class="card-body">
        <p style="justify-content: center; display: flex; margin-bottom: 20px; font-size: 1.2rem; font-weight: bold; color: #333;">扫描下方二维码完成支付。</p>

        <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px;">
          <qrcode-vue :text="qrCode" :value="qrCode" :size="250" />
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <button @click="cancelPay" type="button" class="btn btn-outline-secondary" style="margin-top: 10px;">
          返回
        </button>
      </div>
    </div>
  </div>

  <div style="display: flex; justify-content: center">
    <div style="width: 55rem;padding:0 15px 50px 15px;">
<!--      <div class="title-position" style="margin-top: 10px; font-size: 20px;">-->
<!--        预约信息-->
<!--      </div>-->
      <div class="container " style="margin-top: 10px;">
        <div class="row">
          <div class="col">
            <div class="img-position">
              <div class="image-container">
                <img :src="cxb.headimg" alt="avatar" >
              </div>
            </div>
          </div>
          <div class="col ming-class" >
            <div style="font-size: 1.2rem">
              {{cxb.ysxm}}
            </div>
            <div style="font-size: 1rem">
              {{cxb.ksmc}}
              {{cxb.zhicheng}}
            </div>
          </div>
        </div>
      </div>
      <div class="border-top" style="margin-top: 10px">
        <div class="key-value">
          <div class="key" style="margin-top: 10px">
            预约日期
          </div>
          <div class="value" style="margin-top: 10px">
            {{cxb.jzrq}}
          </div>
        </div>
        <div class="key-value">
          <div class="key">
            就诊科室
          </div>
          <div class="value">
            {{cxb.ksmc}}
          </div>
        </div>
        <div class="key-value">
          <div class="key">
            预约时段
          </div>
          <div class="value">
            {{cxb.sxw}}
          </div>
        </div>
        <div class="key-value">
          <div class="key">
            挂号费
          </div>
          <div class="value" style="color: #bb0000">
            ￥{{cxb.ghf}}
          </div>
        </div>
        <div v-if="cxb.payerTotal" class="key-value">
          <div class="key">
            预约费
          </div>
          <div class="value" style="color: #bb0000">
            ￥{{cxb.yyf}}
          </div>
        </div>
        <div class="key-value">
          <div class="key">
            就诊人
          </div>
          <div class="value" style="cursor: pointer" @click="toSelectPatient">
            <div v-if="patient.data && patient.data.name" style="display: inline-block">
              {{patient.data.name}}
            </div>
            <div v-else style="display: inline-block">
              请选择就诊人
            </div>
            <svg style="display: inline-block;margin-top: -1px" class="bi bi-chevron-right" width="0.8em" height="0.8em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
        </div>

        <div style="padding-top: 5px; display: block;">
          挂号须知：
        </div>
        <div style=";margin-top: 5px; padding-left: 5px" >
<!--          {{notes}}-->
          <p v-html="decodeHtmlEntities(notes)"></p>
<!--          <p style="color: #333333; font-size: 1rem">
            1.线上可预约近7日号源（当日号源仅可现场挂号）线上可预约近7日号源
          </p>-->
        </div>
        <div class="commit-button">
          <button type="button" class="custom-button" @click="commityuyue">提交</button>
        </div>

      </div>

    </div>
  </div>
</template>

<style scoped>

/*.alignText{

  text-align: justify;
}*/
/*
>>> p::before{
  content: ''; !* 创建一个伪元素 *!
  display: inline-block; !* 确保其行为像块元素 *!
  width: 2em; !* 设置空格宽度 *!
}
*/

>>> p{
  color: #333333;
  font-size: 0.9rem;
  text-indent: 0rem !important;
}

.image-container {
  width: 80px;          /* 固定宽度 */
  height: 80px;         /* 固定高度 */
  overflow: hidden;     /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%;   /* 使容器为圆形 */
  position: relative;    /* 以便于定位内部的 img 标签 */
}

.image-container img {
  width: 100%;           /* 图片填满容器 */
  height: 100%;          /* 图片填满容器 */
  object-fit: cover;     /* 确保保持比例的同时填充整个容器 */
}
.title-position{
  justify-content: center;
  display: flex;
}

.img-position{
  justify-content: end;
  display: flex;
}
.ming-class {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
/*
.border-top {
  width: 80%;
  margin: 20px auto;
}
*/

.key-value {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 10px 5px;
  font-size: 1rem;
  /*border-bottom: 1px solid #ddd;*/
}

.key {
  text-align: left; /* 键左对齐 */
  color: #727272;
}

.value {
  text-align: right; /* 值右对齐 */
}

.commit-button{
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button{
  width: 100%;
  font-size: 20px;
  margin: 20px auto;
  color: #ffffff;
  background-color: #804e22;
  border-radius: 3px;
  border: none;
}

@media (min-width:768px) {
  >>> .custom-button {
    width: 9% !important;
    font-size: 16px;
  }
}


</style>