import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from 'pinia';

import "./assets/custom.scss";
import {useUserStore} from "@/stores/useUserStore.ts";
import Api from "@/request/api";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入样式
import EventBus from "@/utils/event-bus";
/*import {useDoctorStore} from "@/stores/useDoctorStore.ts";*/

const pinia = createPinia();


const app = createApp(App)

app.config.globalProperties.$EventBus = EventBus
app.use(ElementPlus);
app.use(router)
app.use(pinia)

app.mount("#app")
  /*  Api.getUserInfo("oDXzy6gQoUhNXx-HpJG4Kg0o2h2E")
    .then(res => {
        console.log("userInfo", res)
        console.log("userInfo", res.data)
        useUserStore().setUserInfo(res.data.data)
    })*/
/*router.afterEach((to) => {
    if (to.path.startsWith("/app")){

    }
})*/
/*
const userStore = useUserStore();
const isUserValid = userStore.loadUserInfo();
if (!isUserValid){
    console.log("跳转没？")
    //跳转到登录页
    EventBus.emit("goLogin", "")
}
*/

// 在路由实例上注册全局前置守卫
router.beforeEach((to, from, next) => {
    const flag = !to.query.auth
    if (!(to.path.startsWith("/app"))) {
        if(to.path.startsWith("/my-appointment")
            || ((to.path.startsWith("/select-patient") && flag))
            || to.path.startsWith("/add-patient")){
            console.log("Redirecting or taking action for non-app route:", to.path);
            const userStore = useUserStore();
            const isUserValid = userStore.loadUserInfo();
            if (!isUserValid){
                console.log("跳转没？")
                //跳转到登录页
                EventBus.emit("goLogin", "")
            }
        }
    }
    // 继续导航
    next();
});


