import { defineStore } from 'pinia';

export const useAppointmentStore = defineStore('appointment', {
    state: () => ({
        ysId: '',
        czb_index: '',
        openId: '',
        documentNumber: '',
        paId:'',
        scId:'',
    }),
    actions: {
        setYsId(id) {
            this.ysId = id; // 设置 ysId
        },
        setPaId(id) {
            this.paId = id; // 设置 ysId
        },
        setScId(id) {
            this.scId = id; // 设置 ysId
        },
        setCzbIndex(index) {
            this.czb_index = index; // 设置 czb_index
        },
        setOpenId(id) {
            this.openId = id; // 设置 openId
        },
        setDocumentNumber(number) {
            this.documentNumber = number; // 设置 documentNumber
        },
        clearAppointment() {
            this.ysId = '';
            this.czb_index = '';
            this.paId = '';
            this.scId = '';
            //this.openId = '';
            //this.documentNumber = ''; // 清空所有存储的值
        }
    },
    getters: {
        getYsId: (state) => state.ysId,
        getPaId: (state) => state.paId,
        getScId: (state) => state.scId,
        getCzbIndex: (state) => state.czb_index,
        getOpenId: (state) => state.openId,
        getDocumentNumber: (state) => state.documentNumber,
        isAppointment() {
            return this.scId
                    && this.paId !== ''
                /*this.openId !== '' &&
                this.ysId !== '' &&
                    this.czb_index !== ''
                this.documentNumber !== '';*/
        }
    }
});