<script>
import PageBanner from "@/components/Common/PageBanner.vue";
import SelectPatient from "@/components/patient/SelectPatient.vue"
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

export default {
  name: "SelectPatientPage",
  components: {SelectPatient, PageBanner},
  data:() => {
    return {
      type: '',
      isCenter: false,
      auth: '',
      openid:'',
      secondPageUrl: {
        url: '/dentist',
        type: '',
        query: {page: 1},
        openid:''
      },
    }
  },
  created() {
    this.type = this.$route.query?.type
    this.isCenter = this.$route.query?.isCenter
    this.auth = this.$route.query?.auth
    this.openid = this.$route.query?.openid
    if(!this.openid) this.openid=useUserXcxStore().getOpenid()
    console.log("---------------------------------")
    const route = this.$route;
    if (route.path.startsWith('/app')) {
      this.secondPageUrl.url = '/app/dentist';
      // this.secondPageUrl.url = this.type;
      this.secondPageUrl.query = {page: 1,'type':this.type, 'openid':this.openid};
    } else {
      this.secondPageUrl.url = '/dentist';
    }
    if (this.$route.path.startsWith("/app")&&this.$route.query&&this.$route.query.openid) {
      useUserXcxStore().setOpenid(this.$route.query?.openid)
    }
  },
}
</script>

<template>
  <div>
    <PageBanner v-if="!isCenter" pageTitle="选择就诊人" secondPageTitle="专家介绍" :secondPageUrl="secondPageUrl" :type="type" :isCenter="isCenter"/>
    <PageBanner v-else pageTitle="选择就诊人" :type="type" :isCenter="isCenter" />
    <SelectPatient :type="type" :isCenter="isCenter" :auth="auth" :openid="openid"/>
  </div>
</template>

<style scoped>

</style>