<template>
    <div class="blog-area pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="section-title-warp">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            Update News and Blogs
                        </span>
                        <h2>Stay Updated with Our Latest News and Blog</h2>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="section-warp-btn">
                        <router-link to="/blog" class="default-btn">View All</router-link>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-1.jpg" alt="image">
                            </router-link>

                            <div class="tag">10 Jun</div>
                            <div class="tag-two">
                                <router-link to="/blog">
                                    Technology
                                </router-link>
                            </div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/blog-details">
                                    New Technology Make for Dental Operation
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                            <router-link to="/blog-details" class="blog-btn">
                                Read More
                            </router-link>

                            <ul class="entry-meta">
                                <li>
                                    <i class='bx bxs-user'></i>
                                    <span>By 
                                        <router-link to="/blog-right-sidebar">
                                            Daisy Gabriela
                                        </router-link>
                                    </span>
                                </li>
                                <li>
                                    <i class='bx bxs-comment'></i>
                                    2 Comments
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-2.jpg" alt="image">
                            </router-link>

                            <div class="tag">11 Jun</div>
                            <div class="tag-two">
                                <router-link to="/blog">
                                    Dental
                                </router-link>
                            </div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/blog-details">
                                    Regular Dental care make Your Smile Brighter
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                            <router-link to="/blog-details" class="blog-btn">
                                Read More
                            </router-link>
                            
                            <ul class="entry-meta">
                                <li>
                                    <i class='bx bxs-user'></i>
                                    <span>By 
                                        <router-link to="/blog-right-sidebar">
                                            Ronnie Aaron
                                        </router-link>
                                    </span>
                                </li>
                                <li>
                                    <i class='bx bxs-comment'></i>
                                    2 Comments
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-blog">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-3.jpg" alt="image">
                            </router-link>

                            <div class="tag">12 Jun</div>
                            <div class="tag-two">
                                <router-link to="/blog">
                                    Hygiene
                                </router-link>
                            </div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/blog-details">
                                    Dental Hygiene for All Age to Make Smile
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                            <router-link to="/blog-details" class="blog-btn">
                                Read More
                            </router-link>
                            
                            <ul class="entry-meta">
                                <li>
                                    <i class='bx bxs-user'></i>
                                    <span>By 
                                        <router-link to="/blog-right-sidebar">
                                            Babatunde Jon
                                        </router-link>
                                    </span>
                                </li>
                                <li>
                                    <i class='bx bxs-comment'></i>
                                    2 Comments
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>