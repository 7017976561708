<script>
import PageBanner from "@/components/Common/PageBanner.vue";
import PersonCenter from "@/components/person-center/PersonCenter.vue";

export default {
  name: "PersonCenterPage",
  components: {PersonCenter, PageBanner}
}
</script>

<template>
  <div>
    <PageBanner pageTitle="个人中心"/>
    <PersonCenter/>
  </div>
</template>

<style scoped>

</style>