<script>
import PageBanner from "@/components/Common/PageBanner.vue";
import MyAppointment from "@/components/my-appointment/MyAppointment.vue";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

export default {
  name: "MyAppointmentPage",
  components: {MyAppointment, PageBanner},
  data: () => {
    return {
      type: '',
      openid:''
    }
  },
  created() {
    this.type = this.$route.query?.type
    this.openid = this.$route.query?.openid
    if (this.$route.path.startsWith("/app")&&this.$route.query&&this.$route.query.openid) {
      useUserXcxStore().setOpenid(this.$route.query?.openid)
    }
  }
}
</script>

<template>
  <div>
    <PageBanner pageTitle="我的预约" :type="type"/>
    <MyAppointment :type="type" :openid="openid"/>
  </div>
</template>

<style scoped>

</style>