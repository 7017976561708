<template>
  <div>
    <PageBanner pageTitle="来院导航" :type="type"/>
    <to-hospitaln-nav :type="type"/>
  </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import toHospitalnNav from "@/components/to-hospitaln-nav/ToHospitalnNav.vue";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";


export default {
  components: {
    PageBanner,
    toHospitalnNav
    /*FunFacts,
    OurDentist,
    Pricing,
    OurClients,*/
  }, data: () => {
    return {
      type: ''
    }
  },
  created() {
    this.type = this.$route.query?.type
    if (this.$route.path.startsWith("/app")&&this.$route.query&&this.$route.query.openid) {
      useUserXcxStore().setOpenid(this.$route.query?.openid)
    }
  }
}
</script>
