<template>

  <div v-if="isDesktop">
    <div>
      <!--    <div class="page-banner-area" >-->
      <div class="area" style="margin-top: 2rem">
        <div class="container">
          <div class="row">
<!--            <div class="col-1 d-flex align-items-center">-->
<!--&lt;!&ndash;              <div>&ndash;&gt;-->
<!--&lt;!&ndash;                <svg t="1723009060303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2606" width="4rem" height="4rem"><path d="M937.642667 385.024a68.266667 68.266667 0 0 0-51.2-23.210667H756.053333l63.146667-63.317333a85.333333 85.333333 0 0 0-120.661333-120.661333L513.365333 361.813333H136.533333a68.266667 68.266667 0 0 0-68.266666 77.312C84.309333 563.2 156.16 726.528 307.2 793.088a70.656 70.656 0 0 0 18.773333 52.224c25.258667 25.770667 77.653333 36.181333 181.077334 36.181333 165.034667 0 201.898667-26.794667 207.018666-86.528C866.474667 729.258667 938.666667 564.053333 954.368 438.954667a68.266667 68.266667 0 0 0-16.725333-53.930667zM733.866667 214.016a34.133333 34.133333 0 1 1 48.128 48.469333l-98.133334 99.328h-97.962666z m-54.272 539.306667a25.258667 25.258667 0 0 0-17.066667 23.210666v5.12c0 27.136-1.024 48.64-156.501333 48.64-102.4 0-135.338667-11.264-144.725334-20.821333C358.4 805.546667 358.4 799.232 358.4 785.066667v-9.045334a25.770667 25.770667 0 0 0-17.066667-23.893333C200.704 698.538667 133.802667 546.133333 119.466667 432.64a17.066667 17.066667 0 0 1 17.066666-19.626667h749.738667a18.261333 18.261333 0 0 1 13.141333 5.802667 17.066667 17.066667 0 0 1 4.266667 13.824c-14.336 115.029333-81.578667 267.946667-223.573333 320.682667z" fill="#ffffff" p-id="2607"></path><path d="M336.213333 686.08a23.893333 23.893333 0 0 1-9.557333-1.877333c-95.914667-38.229333-132.949333-164.864-134.485333-170.666667a25.6 25.6 0 0 1 49.152-13.994667c0 1.024 31.914667 107.861333 104.448 136.533334a25.6 25.6 0 0 1-9.557334 49.322666z" opacity=".6" fill="#ffffff" p-id="2608"></path></svg>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
            <div class="col-12">
              <div class="home-slides">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                >
                  <slide
                      v-for="(image, index) in imgList"
                      :key="index"
                  >
                    <div class="main-slides-item-customer"
                         :style="{ backgroundImage: 'url(' + image + ')', flexShrink : 0 }">
                    </div>
                  </slide>
                  <template #addons>
                    <Navigation/>
                  </template>
                </carousel>
              </div>
            </div>
<!--            <div class="col-1 d-flex align-items-center">-->
<!--&lt;!&ndash;              <div>&ndash;&gt;-->
<!--&lt;!&ndash;                <svg t="1723002979867" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11150" width="3rem" height="3rem"><path d="M318.450022 1018.363716A312.701013 312.701013 0 0 1 97.169529 484.720387L484.720387 96.944077a312.588287 312.588287 0 0 1 533.643329 221.280494 310.671951 310.671951 0 0 1-91.307793 221.280493L539.61779 926.943197a311.911933 311.911933 0 0 1-221.280494 91.533245z m-166.15764-146.543373a235.258476 235.258476 0 0 0 332.31528 0L872.045795 484.720387a235.033025 235.033025 0 0 0-332.428005-332.653456L152.179657 539.505064a235.258476 235.258476 0 0 0 0 332.315279z m0 0" fill="#ffffff" p-id="11151"></path><path d="M662.376046 609.507706l-41.257596 41.257596a39.003082 39.003082 0 0 1-55.122853 0l-192.760899-192.760899a39.003082 39.003082 0 0 1 0-55.122853l41.257596-41.257596z m0 0" fill="#ffffff" p-id="11152"></path></svg>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->

          </div>

          <!--                <div class="page-banner-content">-->

          <!--                  <h2>{{pageTitle}}</h2>-->
          <!--                  <ul class="pages-list">-->
          <!--                    <li v-if="type=='xcx'" @click="navToXcx">-->
          <!--                      首页-->
          <!--                    </li>-->
          <!--                    <li v-else>-->
          <!--                      <router-link to="/" exact>-->
          <!--                        首页-->
          <!--                      </router-link>-->
          <!--                    </li>-->
          <!--                    <li v-if="secondPageTitle">-->
          <!--                      <router-link :to="{path: secondPageUrl.url, query: secondPageUrl.query}">-->
          <!--                        {{ secondPageTitle }}-->
          <!--                      </router-link>-->
          <!--                    </li>-->
          <!--                    <li>{{pageTitle}}</li>-->
          <!--                  </ul>-->

          <!--                </div>-->
        </div>
      </div>
    </div>

    <div v-if="pageTitle != '首页'">
      <div class="container page-banner-content">

        <!--      <h2>{{pageTitle}}</h2>-->
        <div class="pages-list">
          <div class="d-flex justify-content-center">
            <ul class="max-width-cust-banner">
              <li v-if="type=='xcx'" @click="navToXcx">
                首页
              </li>
              <li v-else>
                <router-link to="/" exact>
                  首页
                </router-link>
              </li>
              <li v-if="secondPageTitle">
                <router-link :to="{path: secondPageUrl.url, query: secondPageUrl.query}">
                  {{ secondPageTitle }}
                </router-link>
              </li>
              <li>{{pageTitle}}</li>
            </ul>
          </div>

        </div>


      </div>
    </div>
  </div>

  <div v-else >
    <div>
      <!--    <div class="page-banner-area" >-->
      <div class="area">
        <div class="home-slides">
          <carousel
              :autoplay="5000"
              :wrap-around="true"

          >
            <slide
                v-for="(image, index) in imgList"
                :key="index"
            >
              <div class="main-slides-item-customer"
                   :style="{ backgroundImage: 'url(' + image + ')', flexShrink : 0 }">
              </div>
            </slide>
            <template #addons>
              <Navigation/>
            </template>
          </carousel>
        </div>
      </div>

      <div v-if="pageTitle != '首页'">
        <div class="container page-banner-content">

          <!--      <h2>{{pageTitle}}</h2>-->
          <ul class="pages-list" >
            <li v-if="type=='xcx'" @click="navToXcx">
              首页
            </li>
            <li v-else>
              <router-link to="/" exact>
                首页
              </router-link>
            </li>
            <li v-if="secondPageTitle">
              <router-link :to="{path: secondPageUrl.url, query: secondPageUrl.query, openid: toXcxQuery}">
                {{ secondPageTitle }}
              </router-link>
            </li>
            <li>{{pageTitle}}</li>
          </ul>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import wx from "weixin-js-sdk"
import api from "@/request/api";
import {Carousel, Slide, Navigation} from 'vue3-carousel';
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

export default {
  name: 'PageBanner',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: ['pageTitle', 'secondPageTitle', 'secondPageUrl', 'type', 'openid'],
  data: () => ({
    imgList: [],
    windowWidth: window.innerWidth
  }),
  methods: {
    updateWidth() {
      this.windowWidth = window.innerWidth; // 更新宽度
      this.toggleNavigationButtons();
    },
    navToXcx() {
      console.log("navToXcx=>type=",this.type)
      wx.miniProgram.reLaunch({
        url: '/pages/index/index?current=homePage',
        success: function () {
          console.log("111")
        },
        fail: function () {
          console.log("222")
        }
      })
    },
    init() {
      api.getRHYYInfo()
          .then(res => {
            console.log(res);
            if (res) {
              this.imgList = res.data[6].contents[0].images;
            }
          })
          .catch(err => {
            console.log("About :: init :: getRHYYInfo() :: error: ", err);
          })
    },
    toggleNavigationButtons() {
      const prevButton = document.querySelector('.carousel__prev');
      const nextButton = document.querySelector('.carousel__next');

      // 根据窗口宽度来显示或隐藏按钮
      if (prevButton && nextButton) {
        if (this.windowWidth < 768) {
          prevButton.style.display = 'none'; // 隐藏左按钮
          nextButton.style.display = 'none'; // 隐藏右按钮
        } else {
          prevButton.style.display = ''; // 显示左按钮
          nextButton.style.display = ''; // 显示右按钮
        }
      }
    },
  },
  created() {
    this.init()
    this.toggleNavigationButtons();
  },
  computed: {
    isDesktop() {
      console.log(this.windowWidth)
      return this.windowWidth >= 768; // 判断宽度
    },
    toXcxQuery(){
      return useUserXcxStore().getOpenid();
    }
  },
  mounted() {
    this.toggleNavigationButtons(); // 初始化时调用
    // 监听窗口大小变化
    window.addEventListener('resize', this.updateWidth);
  },
  beforeUnmount() {
    // 清理监听器
    window.removeEventListener('resize', this.updateWidth);
  },
}
</script>
<style scoped>

.area {
  position: relative; /* 确保可以使用绝对定位 */
  overflow: hidden; /* 隐藏溢出的内容 */
}

/*.area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //ackground: linear-gradient(to bottom, #804e22, #a2622a);
  opacity: 0.8; !* 可以调整透明度来得到你想要的效果 *!
  backdrop-filter: blur(10px); !* 模糊效果 *!
  z-index: -1; !* 确保在背景 *!

}*/
.home-slides {
  max-width: 55rem; /* 根据需要调整 */
  margin: 0 auto; /* 居中 */
  //border: 2px solid white; /* 添加白色边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* 添加阴影效果 */
}

@media (max-width:768px) {
  >>> .home-slides {
    max-width: 100%;
  }
}

.main-slides-item-customer {
  background-size: cover; /* 确保背景图片覆盖整个容器 */
  background-position: center; /* 背景居中 */
  width: 100%; /* 宽度满容器 */
  aspect-ratio: 16 / 9; /* 按需设置宽高比 */
}

/*>>> .carousel__prev,
>>> .carousel__next {
  display: none; !* 隐藏左右导航按钮 *!
}*/


.max-width-cust-banner{
  width: 53rem !important;
  
}

@media (max-width: 992px) {
  .max-width-cust-banner{
    width: 44rem !important;
  }
}


</style>