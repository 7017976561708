<template>
    <div class="appointment-area">
        <div class="container">
            <div class="appointment-inner-box">
                <div class="appointment-form">
                    <h4><i class="flaticon-calendar"></i> Make An Appointment</h4>

                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Name">
                        </div>

                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email">
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Phone">
                        </div>

                        <div class="form-group">
                            <input type="date" class="form-control" placeholder="Date">
                        </div>

                        <button type="submit" class="default-btn">Book Appointment</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MakeAnAppointment'
}
</script>