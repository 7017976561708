import { defineStore } from 'pinia';
import mitt from "mitt";

export const useUserStore = defineStore('user', {
    state: () => ({
        userInfo: null,
        expiryTime: null,
    }),
    actions: {
        // 设置用户信息，并更新过期时间
        setUserInfo(userInfo) {
            this.userInfo = userInfo;

            const twoHoursInMillis = 2 * 60 * 60 * 1000; // 两小时的毫秒数
            this.expiryTime = Date.now() + twoHoursInMillis; // 设置过期时间

            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            localStorage.setItem('expiryTime', this.expiryTime);
        },
        getUserInfo(){
            if (!this.isLogin){
                this.loadUserInfo();
            }
            return this.userInfo;
        },
        isLogin(){
            return this.userInfo != null && this.userInfo != ""
        },
        loadUserInfo() {
            const storedUserInfo = localStorage.getItem('userInfo');
            const storedExpiryTime = localStorage.getItem('expiryTime');

            if (storedUserInfo && storedExpiryTime) {
                this.userInfo = JSON.parse(storedUserInfo);
                this.expiryTime = parseInt(storedExpiryTime, 10);

                if (Date.now() > this.expiryTime) {
                    this.clearUserInfo();
                    return false;
                }
               // mitt().emit("hideLogin", '');
                return true;
            }
            return false;
        },
        // 清除用户信息
        clearUserInfo() {
            this.userInfo = null;
            this.expiryTime = null;
            localStorage.removeItem('userInfo');
            localStorage.removeItem('expiryTime');
        },
    },
});