<template>
    <div class="overview-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="overview-content">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            We Care For Every Patient
                        </span>
                        <h3>Nervous Troubled Attractive Staring Right Anxiously!</h3>
                        <p>Contact Us Today <a href="tel:088123654987">+088 123 654 987</a></p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="overview-image">
                        <img src="../../assets/images/overview.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NervousTroubled'
}
</script>