<template>
    <div class="features-area-two pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">
                    <i class="flaticon-hashtag-symbol"></i>
                    Our Features
                </span>
                <h2>Our Clients are Our Priority, We Offer Quality Dental Services of Specialists</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-box">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-caduceus"></i>
                            </div>
                            <h3>
                                <router-link to="/services-details">
                                    Quality Brackets
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-box">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-policy"></i>
                            </div>
                            <h3>
                                <router-link to="/services-details">
                                    Certified Dentist
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-box">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-diamond"></i>
                            </div>
                            <h3>
                                <router-link to="/services-details">
                                    Experience
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-box">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-hospitalisation"></i>
                            </div>
                            <h3>
                                <router-link to="/services-details">
                                    Patient Care
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurFeatures'
}
</script>