<template>
  <div class="blog-details-area">
    <div class="container">
      <div class="row container-center">
        <div class="content">

          <div class="article-content">

            <div style="font-size: 1.2rem;font-weight: bold;margin-bottom: 6px">{{ newsInfo.title }}</div>

<!--            <div class="img-center">
              <img :src="newsInfo.thumb_url" alt="image">
            </div>-->
            <div  v-html="newsInfo.content"></div>

<!--
            <div class="entry-meta" style="color: #777777;margin-bottom: 1.5rem">
              作者:
              {{ newsInfo.author }}
            </div>


            <div v-html="decodeHtmlEntities(text)" class="alignText">

            </div>
-->

          </div>

          <div class="prev-next">
            <div>
              <router-link :to="{path: secondPageUrl.url, query: secondPageUrl.query, openid: useUserXcxStore().getOpenid()}">
                <div class="column-class" style="cursor: pointer">
                  <svg t="1723104778968" class="icon" viewBox="0 0 1024 1024" version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1275" width="1rem" height="1rem">
                    <path
                        d="M774.583359 108.632842L389.197299 511.551926 774.583359 914.471009a59.351269 59.351269 0 0 1 0 89.480833 74.38768 74.38768 0 0 1-98.218974 0L248.252221 556.434195a60.259128 60.259128 0 0 1-20.313341-44.882269 60.259128 60.259128 0 0 1 20.313341-44.88227L676.364385 19.15201A74.330939 74.330939 0 0 1 774.583359 19.095268a59.40801 59.40801 0 0 1 0 89.537574z"
                        fill="#6a6c72" p-id="1276"></path>
                  </svg>
                  <div class="prev-next-width prev-title">
                    新闻动态
                  </div>
                </div>
              </router-link>
            </div>


            <!--            <div >
            &lt;!&ndash;              <router-link&ndash;&gt;
            &lt;!&ndash;                  :to="{ path: detailUrl, query: { index: index -1 }} ">&ndash;&gt;

                            <div class="column-class" v-if="prevFlag" @click="reloadPage(-1,source)" style="cursor: pointer">
                              <svg t="1723104778968" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                   xmlns="http://www.w3.org/2000/svg" p-id="1275" width="1rem" height="1rem">
                                <path
                                    d="M774.583359 108.632842L389.197299 511.551926 774.583359 914.471009a59.351269 59.351269 0 0 1 0 89.480833 74.38768 74.38768 0 0 1-98.218974 0L248.252221 556.434195a60.259128 60.259128 0 0 1-20.313341-44.882269 60.259128 60.259128 0 0 1 20.313341-44.88227L676.364385 19.15201A74.330939 74.330939 0 0 1 774.583359 19.095268a59.40801 59.40801 0 0 1 0 89.537574z"
                                    fill="#6a6c72" p-id="1276"></path>
                              </svg>
                              <div class="prev-next-width prev-title">
                                {{ prev.title }}
                              </div>
                            </div>
            &lt;!&ndash;              </router-link>&ndash;&gt;
                        </div>

                        <div >
                          <div v-if="nextFlag" style="cursor: pointer" @click="reloadPage(1,source)">
                            &lt;!&ndash;              <router-link&ndash;&gt;
                            &lt;!&ndash;                  :to="{ path: detailUrl, query: { index: index + 1 }}">&ndash;&gt;
                            <div class="column-class">
                              <div class="prev-next-width next-title">
                                {{ next.title }}
                              </div>
                              <svg t="1723104802487" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                   xmlns="http://www.w3.org/2000/svg" p-id="1575" width="1rem" height="1rem">
                                <path
                                    d="M774.144814 556.533638l-427.563702 447.416089a74.30465 74.30465 0 0 1-98.127515 0 59.330278 59.330278 0 0 1 0-89.449185l384.90943-402.8333-384.90943-402.719857a59.330278 59.330278 0 0 1 0-89.449185 74.191208 74.191208 0 0 1 98.127515 0l427.563702 447.359368c13.613066 12.365201 20.362877 28.644159 20.306156 44.866396 0.056721 16.222237-6.693091 32.444473-20.306156 44.809674z"
                                    fill="#6a6c72" p-id="1576"></path>
                              </svg>
                            </div>
                            &lt;!&ndash;              </router-link>&ndash;&gt;
                          </div>
                        </div>-->


          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {getDoctorInfo} from "@/api/doctor/doctorApi";
import router from "@/router";
import Api from "@/request/api";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

defineProps({
  name: {
    type: String,
    default: 'BlogDetails'
  },
  source:{
    type: String,
    default: ''
  }
});

const route = useRoute();

const detailUrl = computed(() => {
  if (!route.path.startsWith('/app')) {
    return "/blog-details";
  } else {
    return "/app/blog-details";
  }
})
const index = route.query.index;
let wholeNews = [];
const newsInfo = ref({});
const text = ref();
const page = ref(1);

let prevFlag = ref(false)
let nextFlag = ref(false)
let prev = ref({})
let next = ref({})

const secondPageUrl = ref({
  url: '',
  query: { page : 1}
})
const type = ref("");

const emit = defineEmits(['sendPage']);

const sendPage = () => {
  emit('sendPage', (Math.floor(index / 9) + 1))
}

onMounted(() => {

Api.findNewsById(index)
    .then(res => {
      console.log('findNewsById', res)
      newsInfo.value = res.data
      newsInfo.value.content = res.data.content.replace(/data-src=/g,'src=')
      // console.log('findNewsById', newsInfo.value.url)

    })

  /* getDoctorInfo()
       .then((data) => {
         // 这里处理成功返回的数据
         wholeNews = data.data[4].contents;
         newsInfo.value = wholeNews[index];
         const indexNum = parseInt(index, 10);
         if (indexNum > 0) {
           prevFlag.value = true
           prev.value = wholeNews[indexNum - 1];
         }
         if (indexNum < wholeNews.length - 1) {
           nextFlag.value = true
           next.value = wholeNews[indexNum + 1];
         }
         text.value = newsInfo.value.attributes.text
         sendPage();
       })
       .catch((error) => {
         // 这里处理错误
         console.error('Failed to get doctor info:', error);
       });*/

  type.value = route.query?.type
  if (route.path.startsWith('/app')){
    secondPageUrl.value.url = '/app/blog?type=' + route.query.type
    secondPageUrl.value.query = {page:1, type: route.query.type}
  } else {
    secondPageUrl.value.url = '/blog'
  }
})

const reloadPage = (num, source) => {
  const path = detailUrl.value;
  const query = {index: parseInt(index, 10) + parseInt(num, 10), 'type': source};
  //使用 Vue Router 进行导航
  router.push({path, query}).then(() => {
    window.location.reload();
  }).catch(err => {
    console.error(err);
  });
};

function decodeHtmlEntities(text) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
}
</script>

<style scoped>
/*================================================
小程序两端文字对齐 CSS
=================================================*/
.alignText {
  text-align: justify;
}

.alignText::after {
  width: 100%;
  display: inline-block;
  /*content: '';*/
}

/*>>> span::before{
  //content: ''; !* 创建一个伪元素 *!
  display: inline-block; !* 确保其行为像块元素 *!
  width: 2em; !* 设置空格宽度 *!
}*/
>>> p::before {
  content: '';
  display: inline-block;
  width: 2em;
}

/*
.content img {
  max-width: 92% !important;
}

@media (max-width: 768px) {
  .content img {
    width: 100% !important;
  }
}
*/

.content {
  max-width: 56.5rem;
}

.container-center {
  display: flex;
  justify-content: center;
}

/*>>> span {
  display: inline-block;
  text-indent: 2em;
  font-size: 1rem;
}*/

>>> p {
  margin-bottom: 0rem;
}

>>> img {
  margin: 0.4rem 0;
  border-radius: 0.5rem;
}

.img-center {
  justify-content: center;
  display: flex;
}

.prev-next {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
  margin-top: 50px;
}

.prev-next-width {
  max-width: 140px;
  font-size: 1rem;
  color: #6a6c72;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column-class {
  flex-direction: row;
  display: flex;
}


.prev-title {
  margin-top: -3px;
  margin-left: 4px;
}

.next-title {
  margin-top: -3px;
  margin-right: 4px;
}
</style>