<template>
    <div>
        <PageBanner pageTitle="Pricing" />
        <Pricing />
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import Pricing from '../Common/Pricing'

export default {
    components: {
        PageBanner,
        Pricing,
    }
}
</script>
