<template>
    <div>
        <PageBanner pageTitle="Services Details" />
        <ServicesDetails />
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import ServicesDetails from '../services-details/ServicesDetails'

export default {
    components: {
        PageBanner,
        ServicesDetails,
    }
}
</script>
