<template>
    <div class="gallery-area pt-100 pb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">
                    <i class="flaticon-hashtag-symbol"></i>
                    Our Recent Work
                </span>
                <h2>Our Cases & Patient Stories We Create  Beautiful Smiles</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-1.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Aesthetic Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-2.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Pediatric Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-3.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Orthodontics Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-4.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Prosthodontists Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-5.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Periodontists Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-6.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    General Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-7.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Aesthetic Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-8.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Cosmetic Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-9.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Family Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="/gallery" class="prev page-numbers">
                            <i class='flaticon-left-arrow'></i>
                        </a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="/gallery" class="page-numbers">2</a>
                        <a href="/gallery" class="page-numbers">3</a>
                        <a href="/gallery" class="page-numbers">4</a>
                        <a href="/gallery" class="page-numbers">5</a>
                        <a href="/gallery" class="next page-numbers">
                            <i class='flaticon-right-arrow'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Gallery'
}
</script>