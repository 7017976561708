<template>
    <div class="preloader">
        <div class="loader">
            <div class="sbl-half-circle-spin"></div>
            <div class="sbl-half-circle-spin"></div>
            <div class="sbl-half-circle-spin"></div>
            <div class="sbl-half-circle-spin"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Preloader',
    }
</script>