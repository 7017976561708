import { defineStore } from 'pinia';

export const useUserXcxStore = defineStore('userXcx', {
    state: () => ({
        openid: "",
    }),
    actions: {
        setOpenid(openid) {
            this.openid = openid;
        },
        getOpenid() {
            return this.openid;
        },
        isLogin() {
            return this.openid !== null && this.openid != '' && this.openid != undefined;
        }
    }
});