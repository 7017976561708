import axios from "axios";
// import { ElMessage } from 'element-plus';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
function errorInfo(msg) {
    console.error('请求失败', msg);
    // ElMessage.error(msg)
    alert(msg)
}

// export const CONTENT_PATH = '/rh-hospital';

// 创建一个 Axios 实例
const instance = axios.create({
    timeout: 0, // 请求超时的时间限制
    withCredentials: true,
    // baseURL: CONTENT_PATH
});

//禁用进度环
NProgress.configure({ showSpinner: false })

// 你可以在这里添加请求拦截器
instance.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    NProgress.start() // 设置加载进度条(开始..)
    config.headers['source'] = '_jszyy';
    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 你可以在这里添加响应拦截器
instance.interceptors.response.use(response => {
    NProgress.done() // 设置加载进度条(结束..)
    // 对响应数据做点什么
    if (response && response.status === 200){
        return response.data
    }
    return response;
}, error => {
    // 对响应错误做点什么
    console.log("error",error)
    if (!error||!error.response||!error.response.status) {
        return;
    }
    switch (error.response.status){
        case 404:
            errorInfo('接口不存在');
            break;
        case 0:
            errorInfo('服务器开小差了，请您稍后重试');
            break;
        case 490:
            errorInfo('登录信息失效，请您注销后重新登录。');
            break;
        case 500:
            errorInfo('接口异常');
            break;
        case 502:
            // errorInfo()
            break;
        case 1000:
            errorInfo('掉线请重新登录');
            break;
        case 1001:
            errorInfo('用户重复登录,如不是本人操作，请重新登录！');
            break;
        default:
    }
    return Promise.reject(error);
});

export default instance;