<script setup>

import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import Api from "@/request/api";
import {useUserStore} from "@/stores/useUserStore.ts";

onMounted(() => {
  getUserInfo();
})
let userInfo = ref({})

function getUserInfo(){
  const route = useRoute(); // 获取当前路由信息
  // 从路由查询参数中获取 openid
  const openid = route.query.auth;


  Api.getUserInfo("oVH4o6YdbzM47q6lXPFPQlJBL8t4")
      .then(res => {
        console.log("userInfo", res)
        console.log("userInfo", res.data)
        userInfo.value = res.data.data
        useUserStore().setUserInfo(res.data.data)
      })
 /* if (useUserStore().getUserInfo() === null){

  }*/



}
</script>
<div>
</div>
<template>
  <div class="container d-flex flex-column align-items-center" style="max-width: 56.5rem;">

    <div class="user-info" style="text-align: center; margin-bottom: 20px;">
      <div class="image-container" style="flex-shrink: 0;">
        <img :src="userInfo.headImg" alt="avatar" class="img-fluid" />
      </div>
      <div>
        {{ userInfo.userName }}
      </div>
    </div>

    <div class="card" @click="navigateToHome" style="margin-bottom: 20px;">
      <div>
        我的预约
      </div>
    </div>

    <div class="card" @click="navigateToHome" style="margin-bottom: 20px;">
      <div>
        患者信息
      </div>
    </div>

  </div>
</template>
<style scoped>
.image-container {
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.card {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  cursor: pointer; /* 设置鼠标样式为指针 */
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 增加阴影效果 */
}

</style>