<template>
  <div class="blog-area d-flex justify-content-center" style="margin-bottom: 50px">
    <div class="container max-width-cust">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="section-title-warp">
              <span class="sub-title">
                <i class="flaticon-hashtag-symbol"></i>
                新闻动态
              </span>
            <div style="font-size: 1.4rem; margin-top: 10px;margin-bottom: 1rem">
              随时关注医院的最新动态
            </div>

          </div>
        </div>

        <div class="col-lg-5">
          <div class="section-warp-btn">
            <router-link to="/blog" class="default-btn">
              查看更多
            </router-link>
          </div>
        </div>
      </div>


      <div class="row">
        <!--        <div class="col-lg-4 col-md-6" v-for="(info, index) in newsInfo" :key="info.title">-->
        <!--          <div class="single-blog">-->
        <!--            <div class="blog-image">-->
        <!--              <router-link :to="{ path: '/blog-details', query: { index: index }}">-->
        <!--                <img class="img-cropping-blog" :src="info.cover" alt="image">-->
        <!--              </router-link>-->
        <!--            </div>-->
        <!--            <div class="blog-content">-->
        <!--              <h3>-->
        <!--                <router-link :to="{ path: '/blog-details', query: { index: index }}">-->
        <!--                  {{ info.title }}-->
        <!--                </router-link>-->
        <!--              </h3>-->
        <!--              <p>{{ info.desc }}</p>-->
        <!--              <router-link :to="{ path: '/blog-details', query: { index:  index }}" class="blog-btn">-->
        <!--                查看详情-->
        <!--              </router-link>-->

        <!--              <ul class="entry-meta">-->
        <!--                <li>-->
        <!--                  <i class='bx bxs-user'></i>-->
        <!--                  <span>作者:{{ info.author }}</span>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-lg-4 col-md-6" v-for="(info, index) in newsInfo" :key="info.title">

          <div class="single-blog">

            <router-link :to="{ path: '/blog-details', query: { index: info.article_id }}">
              <div class="blog-image">
                <img class="newsImage" :src="info.thumb_url" alt="image">
              </div>

              <div class="blog-content">
                <div class="title-class">
                  {{ info.title }}
                </div>
                <div class="desc-class">
                  {{ info.digest }}
                </div>

                <ul class="entry-meta">
                  <li>
                    <svg style="margin: -3px 3px 0 -25px" t="1724297023783" class="icon" viewBox="0 0 1024 1024"
                         version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10705" width="1.5rem" height="1.5rem">
                      <path
                          d="M768 213.333333h-42.666667V170.666667c0-25.6-17.066667-42.666667-42.666666-42.666667s-42.666667 17.066667-42.666667 42.666667v42.666666H384V170.666667c0-25.6-17.066667-42.666667-42.666667-42.666667s-42.666667 17.066667-42.666666 42.666667v42.666666H256C183.466667 213.333333 128 268.8 128 341.333333v426.666667c0 72.533333 55.466667 128 128 128h512c72.533333 0 128-55.466667 128-128V341.333333c0-72.533333-55.466667-128-128-128z m42.666667 554.666667c0 25.6-17.066667 42.666667-42.666667 42.666667H256c-25.6 0-42.666667-17.066667-42.666667-42.666667v-256h597.333334v256zM213.333333 426.666667V341.333333c0-25.6 17.066667-42.666667 42.666667-42.666666h42.666667v42.666666c0 25.6 17.066667 42.666667 42.666666 42.666667s42.666667-17.066667 42.666667-42.666667V298.666667h256v42.666666c0 25.6 17.066667 42.666667 42.666667 42.666667s42.666667-17.066667 42.666666-42.666667V298.666667h42.666667c25.6 0 42.666667 17.066667 42.666667 42.666666v85.333334H213.333333z"
                          fill="#804e22" p-id="10706"></path>
                      <path
                          d="M341.333333 682.666667h341.333334c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666667H341.333333c-25.6 0-42.666667 17.066667-42.666666 42.666667s17.066667 42.666667 42.666666 42.666667z"
                          fill="#804e22" p-id="10707"></path>
                    </svg>
                    <span style="font-size: 0.8rem">{{ info.update_time }}</span>
                  </li>
                </ul>
              </div>

            </router-link>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import api from "@/request/api";
import Api from "@/request/api";

defineProps({
  name: {
    type: String,
    default: 'BlogHome'
  }
});

let wholeNews = [];
const newsInfo = ref([]);

/*api.getRHYYInfo()
    .then(res => {
      console.log(res)
      wholeNews = res.data[4].contents;
      newsInfo.value = wholeNews.slice(0, 3);
    })
    .catch(err => {
      console.log(err);
    })*/


Api.findNewsList(3, 0)
    .then(res => {
      console.log("新闻接口返回", res);
      newsInfo.value = res.data.map(item => {
        const date = new Date(item.update_time * 1000);
        const formattedDate = date.toISOString().split('T')[0];
        return {
          ...item,
          update_time: formattedDate // 更新为格式化后的日期
        };
      });

    })


</script>
<style scoped>
.img-cropping-blog {
  width: 416px;
  height: 320px;
  object-fit: cover;
}

.newsImage {
  width: 416px;
  height: 220px;
  object-fit: cover
}

.image-container {
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.title-class {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  //line-height: 1.8;
  //max-height: 3.6em;
}

.desc-class {
  color: #818181;
  font-size: 0.8rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  //line-height: 1.8;
  //max-height: 3.6em;
}

.max-width-cust {
  max-width: 57rem;
}

@media (max-width: 992px) {
  .max-width-cust {
    max-width: 45rem;
  }
}


</style>