<template>
    <div>
        <PageBanner pageTitle="Gallery" />
        <Gallery />
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import Gallery from '../Gallery/Gallery'

export default {
    components: {
        PageBanner,
        Gallery,
    }
}
</script>
