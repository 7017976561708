<template>
    <div class="main-banner-item">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="main-banner-content">
                        <span class="sub-title wow fadeInDown" data-wow-delay="1s">
                            <i class="flaticon-hashtag-symbol"></i>
                            Keeping Teeth Clean
                        </span>
                        <h1>The World Best <span>Dental Specialist</span> Treatment</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        
                        <div class="banner-btn">
                            <router-link to="/appointment" class="default-btn">
                                Book Appointment
                            </router-link>
                            
                            <router-link to="/dentist" class="optional-btn">
                                Consult A Dentist
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="main-banner-image">
                        <img src="../../assets/images/main-banner-1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>