<template>
  <div class="doctor-area" style="padding-bottom: 50px">
    <div class="container max-width-cust" v-if="isDesktop" >
<!--      <div class="section-title">-->
<!--&lt;!&ndash;                <span class="sub-title">&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="flaticon-hashtag-symbol"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                    专家介绍&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--        <h2 id="scroll">院内专业且经验丰富的专家</h2>-->
<!--      </div>-->

      <div class="row">

        <div class="title-container">
          <div class="title">院内专业且经验丰富的专家</div>
          <div class="underline"></div>
        </div>

        <div class="d-flex justify-content-between">
          <el-select v-model="ksmcSel" clearable placeholder="请选择科室" style="max-width: 28%">
            <el-option
                v-for="item in ksmcListSel"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="jzrqSel" clearable placeholder="请选择日期"  style="max-width: 28%">
            <el-option
                v-for="item in jzrqSelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input  placeholder="请输入医生名称" style="max-width: 28%;" v-model="doctorName" clearable> </el-input>
          <el-button  plain @click="handleSelect">搜索</el-button>
        </div>

        <div style="margin-top: 1.5rem" class="col-lg-3 col-md-4" v-for="(expertInfo, index) in expertsInfos" :key="expertInfo.ysid">

          <router-link v-if="!expertInfo.ystz"
              :to="{ path: detailUrl, query: { name: expertInfo.ysxm, index: expertInfo.ysid, 'type':source }}">

            <div class="single-doctor">
              <img class="img-cropping" :src="expertInfo.headimg" alt="image">
              <div class="doctor-content">
                <div style="font-size: 1.1rem">
                  {{ expertInfo.ysxm }}
                </div>
                <span class="desc-class">{{expertInfo.ksmc}}  {{ expertInfo.zhicheng }}</span>
                <span class="desc-jj">简介：{{ expertInfo.ysjj }}</span>
              </div>
            </div>
          </router-link>
          <div v-else >
            <div class="single-doctor" @click="consleTitle">
              <img class="img-cropping" :src="expertInfo.headimg" alt="image">
              <div class="doctor-content">
                <div style="font-size: 1.1rem">
                  {{ expertInfo.ysxm }}
                </div>
                <span class="desc-class">{{ expertInfo.zhicheng }}</span>
                <span class="desc-jj">简介：{{ expertInfo.ysjj }}</span>
              </div>
            </div>
          </div>

        </div>

        <!--                分页-->
        <div class="col-lg-12 col-md-12">
          <div class="pagination-area">
            <a class="prev page-numbers" v-if="pagination.pageNum !== 1" @click="handlePageNum(-1, true)">
              <i class='flaticon-left-arrow'></i>
            </a>
            <span v-for="curPage in pagination.pages" :key="curPage">
              <a class="page-numbers" v-if="pagination.pageNum !== curPage" @click="handlePageNum(curPage, false)">
                {{ curPage }}
              </a>
              <span class="page-numbers current" aria-current="page" v-else>{{ curPage }}
              </span>
            </span>
            <a class="next page-numbers" v-if="pagination.pageNum !== pagination.pages" @click="handlePageNum(1, true)">
              <i class='flaticon-right-arrow'></i>
            </a>
          </div>
        </div>

      </div>


    </div>

    <div v-else class="container">
      <div>
<!--搜索框-->
        <div>
          <div class="d-flex justify-content-between">
            <el-select v-model="ksmcSel" clearable placeholder="请选择科室" style="max-width: 47%">
              <el-option
                  v-for="item in ksmcListSel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select v-model="jzrqSel" clearable placeholder="请选择日期" style="max-width: 47%">
              <el-option
                  v-for="item in jzrqSelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="margin-top: 1rem" class="d-flex justify-content-between">
          <el-input  placeholder="请输入医生名称" style="max-width: 78%;" v-model="doctorName" clearable> </el-input>
          <el-button plain @click="handleSelect">搜索</el-button>
        </div>

        <div v-for="(expertInfo, index) in expertsInfos" :key="index">
          <router-link v-if="!expertInfo.ystz" :to="{ path: detailUrl, query: { name: expertInfo.ysxm, openid: openid, index: expertInfo.ysid,'type':source  }}"
                       aria-current="true" style="display: block">
<!--            如果为停诊就不能跳转-->
            <div class="border-bottom d-flex gap-3 py-3">
              <div class="d-flex align-items-center">
                <div class="image-container" style="flex-shrink: 0">
                  <img :src="expertInfo.headimg" alt="avatar"  class="img-fluid">
                </div>
              </div>

              <div class="mb-0" style="width: 100%">
                <div style="display: flex;justify-content: space-between">
                  <div class="mb-0" style="font-size: 1.1rem;display: inline-block;font-weight: 600">
                    {{ expertInfo.ysxm}}
                  </div>
                  <div style="display: inline-block;">
                    <button type="button" style="background-color: #804e22" class="custom-button">预约</button>
                  </div>
                </div>
                <div class="mb-0 description-class" style="font-size: 1rem">{{expertInfo.ksmc}}  {{ expertInfo.zhicheng }}</div>
                <div class="mb-0 description-class" style="font-size: 1rem">
                  {{ expertInfo.ysjj }}
                </div>
              </div>
            </div>
          </router-link>
<!--          如果为停诊就不能跳转-->
          <div v-else class="border-bottom d-flex gap-3 py-3">
            <div class="d-flex align-items-center">
              <div class="image-container" style="flex-shrink: 0">
                <img :src="expertInfo.headimg" alt="avatar"  class="img-fluid">
              </div>
            </div>

            <div class="mb-0" style="width: 100%">
              <div style="display: flex;justify-content: space-between">
                <div class="mb-0" style="font-size: 1.1rem;display: inline-block;font-weight: 600">
                  {{ expertInfo.ysxm}}
                </div>
                <div style="display: inline-block;">
                  <button type="button" style="background-color: #b00303" class="custom-button">停诊</button>
                </div>
              </div>
              <div class="mb-0 description-class" style="font-size: 1rem">{{ expertInfo.zhicheng }}</div>
              <div class="mb-0 description-class" style="font-size: 1rem">
                {{ expertInfo.ysjj }}
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, h, onBeforeUnmount, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useDoctorStore} from "@/stores/useDoctorStore.ts";
import Api from "@/request/api";
import {ElNotification} from "element-plus";

const props = defineProps({
  name: {
    type: String,
    default: 'Doctor'
  },source:{
    type: String,
    default: ''
  }
});
const route = useRoute()

const openid = route.query.openid

const toDetail = ref(false)

let doctorName = ref('')
let jzrqSel = ref('')
let jzrqSelList = ref([])
let ksmcSel = ref('')
let ksmcListSel = ref([
  {
    label: '全部科室',
    value: '-1'
  },
  {
    label: '中医科',
    value: 1
  },
  {
    label: '内科',
    value: 3
  },
  {
    label: '口腔科',
    value: 2
  }
])



const windowWidth = ref(window.innerWidth);
const handleResize = () => {
  windowWidth.value = window.innerWidth; // 更新窗口宽度
  if(isDesktop.value){
    handlePageNum(pagination.value.pageNum, false)
  }
  else {
    expertsInfos.value = wholeExperts;
  }
};
const isDesktop = computed(() => windowWidth.value >= 768);


const detailUrl = computed(() => {
  if (!route.path.startsWith('/app')) {
    return "/dentist-details";
  } else {
    return "/app/dentist-details";
  }
})
const curPageNum = route.query.page;

const pagination = ref({
  pageNum: 1,
  pageSize: 8,
  total: 0,
  pages: 1,
})
let wholeExperts = [];
const expertsInfos = ref([]);

const loadDate = () => {
  const today = new Date();
  jzrqSelList.value.push({
    label: '全部日期',
    value: '-1'
  });
  for (let i = 0; i < 7; i++) {
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + i);
    const formattedDate = nextDate.toISOString().split('T')[0];
    jzrqSelList.value.push({
      label: formattedDate,
      value: formattedDate
    });
  }
}

const handleSelect = async () => {
  const params = ref({
    doctorName: doctorName.value,
    ksmcSel: ksmcSel.value,
    jzrqSel: jzrqSel.value
  })
  console.log(params.value)

  await Api.getDoctorList(params.value)
      .then((res) => {
        console.log("======================")
        console.log(res)
        wholeExperts = res.data;
        if(isDesktop.value){
          expertsInfos.value = wholeExperts.slice(0, pagination.value.pageSize);
          console.log(expertsInfos)
          console.log("-=-=-=-=-=-=-=-")
          pagination.value.total = res.data.length;
          if(res.data.length % pagination.value.pageSize==0){
            pagination.value.pages = Math.floor(res.data.length / pagination.value.pageSize);
          }else{
            pagination.value.pages = Math.floor(res.data.length / pagination.value.pageSize) + 1;
          }
          if (curPageNum) {
            handlePageNum(curPageNum, false);
          }
        }
        else {
          expertsInfos.value = wholeExperts
        }
      })
}


onMounted(async () => {
  window.addEventListener('resize', handleResize); // 添加事件监听器

  loadDate()
  handleSelect()
  /*await getDoctorInfo()
      .then((data) => {
        // 这里处理成功返回的数据
        wholeExperts = data.data[0].contents;
        if(isDesktop.value){
          expertsInfos.value = wholeExperts.slice(0, pagination.value.pageSize);
          pagination.value.total = data.data[0].contents.length;
          if(data.data[0].contents.length % pagination.value.pageSize==0){
            pagination.value.pages = Math.floor(data.data[0].contents.length / pagination.value.pageSize);
          }else{
            pagination.value.pages = Math.floor(data.data[0].contents.length / pagination.value.pageSize) + 1;
          }
          if (curPageNum) {
            handlePageNum(curPageNum, false);
          }
        }
        else {
          expertsInfos.value = wholeExperts
        }
      })
      .catch((error) => {
        // 这里处理错误
        console.error('Failed to get doctor info:', error);
      });*/
  console.log(useDoctorStore().doctors)




})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize); // 移除事件监听器
});

function handlePageNum(pageNum, isArrow) {
  if (isArrow) {
    if (pageNum === 1) {
      pagination.value.pageNum++;
    } else if (pageNum === -1) {
      pagination.value.pageNum--;
    }
  } else {
    pagination.value.pageNum = parseInt(pageNum);
  }
  const pageSize = pagination.value.pageSize
  expertsInfos.value = wholeExperts.slice((pagination.value.pageNum - 1) * pageSize, pagination.value.pageNum * pageSize);
  document.getElementById('scroll').scrollIntoView()
}

const consleTitle = () => {
  ElNotification({
    title: '专家介绍',
    message: h(
        'i',
        { style: 'color: black' },
        "该医生暂时停诊！",
    ),
  });
}

</script >
<style scoped>
.img-cropping{
  //height: 345px;
  height: 250px;
  //width: 350px;
  width: 200px;
  object-fit: cover
}
.title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
}
.title-container {
  width: 100%;
  text-align: left;
  //margin: 20px 0;
  margin-bottom: 20px;
  position: relative;
}

.image-container {
  width: 60px;          /* 固定宽度 */
  height: 60px;         /* 固定高度 */
  overflow: hidden;     /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%;   /* 使容器为圆形 */
  position: relative;    /* 以便于定位内部的 img 标签 */
}

.image-container img {
  width: 100%;           /* 图片填满容器 */
  height: 100%;          /* 图片填满容器 */
  object-fit: cover;     /* 确保保持比例的同时填充整个容器 */
}

/*>>> span{
  display: inline-block;
  text-indent: 2em;
  font-size: 1rem;
}*/
>>> p{
  margin-bottom: 0rem;
}

.desc-class {
  font-size: 0.9rem !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /*line-height: 1.8rem !important;
  max-height: 3.6rem !important;*/
}
.desc-jj {
  font-size: 0.9rem !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /*line-height: 1.8rem !important;
  max-height: 3.6rem !important;*/
}

.title-container {
  width: 100%;
  text-align: left;
  /*margin: 20px 0;*/
  position: relative;
}

.underline {
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #804e22;
  z-index: -1;
}

.custom-button {
  font-size: 15px;
  padding: 1px 15px;
  border-radius: 20px;
  border: none;
  //background-color: #804e22;
  color: #fff;
  text-align: center;
}

.description-class {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-width-cust{
  max-width: 57rem;
}

@media (max-width: 992px) {
  .max-width-cust{
    max-width: 45rem;
  }
}

>>> .el-input {
  --el-input-focus-border-color: #804e22;
}

>>> .el-button:hover{
  color: white;
}

>>> .el-select__wrapper.is-focused{
  box-shadow: 0 0 0 1px #804e22 inset;
}


.el-select-dropdown__item.is-selected {
  color: #804e22;
  font-weight: bold;
}

.el-select__wrapper:hover {
   box-shadow: 0 0 0 1px #dcdfe6 inset;
}







</style>