<template>
    <div>
        <PageBanner pageTitle="医院挂号" :type="type"/>
        <MakeAnAppointment :type="type"/>
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import AboutOurHospital from '../about-us/AboutOurHospital'
import MakeAnAppointment from "@/components/Common/MakeAnAppointment.vue";

export default {
    components: {
      MakeAnAppointment,
      PageBanner,
    },data: () => {
        return {
            type: ''
        }
    },
    created() {
        this.type = this.$route.query?.type
    }
}
</script>
