<template>
    <div class="review-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="section-title-warp">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            Our Clients Review
                        </span>
                        <h2>Real Review From Our Real Customer</h2>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="section-warp-btn">
                        <router-link to="/testimonials" class="default-btn">
                            View All
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="review-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings' 
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-review-item">
                            <div class="icon">
                                <i :class="slide.icon"></i>
                            </div>
                            <p>{{slide.description}}</p>

                            <div class="review-info">
                                <img :src="slide.image" alt="image">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template> 
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'OurClients',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                icon: 'flaticon-left-quote',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                image: require('../../assets/images/review/review-1.jpg'),
                name: 'Dr. Sarah Taylor',
                position: 'Nephrologists',
            },
            {
                id: 2,
                icon: 'flaticon-left-quote',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                image: require('../../assets/images/review/review-2.jpg'),
                name: 'Dr. Aiken Ward',
                position: 'Endocrinologists',
            },
            {
                id: 3,
                icon: 'flaticon-left-quote',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                image: require('../../assets/images/review/review-3.jpg'),
                name: 'Dr. Eachann Jhon',
                position: 'Cardiologists',
            },
            
            {
                id: 4,
                icon: 'flaticon-left-quote',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                image: require('../../assets/images/review/review-1.jpg'),
                name: 'Dr. Sarah Taylor',
                position: 'Nephrologists',
            },
            {
                id: 5,
                icon: 'flaticon-left-quote',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                image: require('../../assets/images/review/review-2.jpg'),
                name: 'Dr. Aiken Ward',
                position: 'Endocrinologists',
            },
            {
                id: 6,
                icon: 'flaticon-left-quote',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                image: require('../../assets/images/review/review-3.jpg'),
                name: 'Dr. Eachann Jhon',
                position: 'Cardiologists',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>