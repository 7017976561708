<template>
    <Preloader v-if="isLoading" />
    <TopHeader class="d-none d-sm-block" v-if="showHeaderAndFooter && !isScreenPath"/>
    <MiddleHeader class="d-none d-sm-block" v-if="showHeaderAndFooter && !isScreenPath"/>
    <Navbar v-if="!isScreenPath"/>
    <router-view v-slot="{ Component }">
        <transition 
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut" 
            mode="out-in" 
            :duration="600" 
            @after-leave="$root.$emit('triggerScroll')"
        >
            <component :is="Component" />
        </transition>
    </router-view>
    <Footer v-if="showHeaderAndFooter  && !isScreenPath"/>
    <GoTop v-if="showHeaderAndFooter  && !isScreenPath"/>
</template>

<script>
import Preloader from './components/Layouts/Preloader'
import TopHeader from './components/Layouts/TopHeader'
import MiddleHeader from './components/Layouts/MiddleHeader'
import Navbar from './components/Layouts/Navbar'
import Footer from './components/Layouts/Footer'
import GoTop from './components/Layouts/GoTop'

export default {
    name: "App",
    components: {
        Preloader,
        TopHeader,
        MiddleHeader,
        Navbar,
        Footer,
        GoTop,
    },
    data() {
        return {
            isLoading: true
        }
    },
    mounted() {
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    },
    computed: {
      showHeaderAndFooter() {
        return !this.$route.path.startsWith('/app');
      },
      isScreenPath() {
        // 使用 this.$route 获取当前路由对象
        return this.$route.path === '/board/screen';
      }
    }
};
</script>
<style>


/*
//导航
>>> .page-banner-area {
  background-color: #804e22 !important;
}

//地址图标
>>> .top-header-information li i {
  color: #804e22 !important;
}

//联系方式图标
>>> .middle-header-content li i {
  color: #804e22 !important;
}

//首页等字体颜色
>>> .main-navbar .navbar .navbar-nav .nav-item:hover a, .main-navbar .navbar .navbar-nav .nav-item.active a {
  color: #804e22 !important;
}
>>> .main-navbar .navbar .navbar-nav .nav-item a:hover, .main-navbar .navbar .navbar-nav .nav-item a:focus, .main-navbar .navbar .navbar-nav .nav-item a.active {
  color: #804e22 !important;
}
//下方灯带颜色
>>> .main-navbar .navbar .navbar-nav .nav-item a::before {
  background-color: #da0606 !important;
}


//底部
>>> .footer-area {
  background-color: #571111 !important;
}
>>> .copyright-area {
  background-color: #571111 !important;
}
//底部中间横线
>>> .copyright-area-content {
  text-align: center;
  border-top: 1px solid #ffffff !important;
}
//底部图标颜色
>>> .single-footer-widget .footer-information li i {
  color: #ffffff !important;
}

//手机号悬浮
a:hover, a:focus {
    color: #571111;
}

//按钮 & 悬浮
.default-btn {
  background-color: #804e22;
}

..main-navbar .navbar .others-options .option-item .navbar-btn .default-btn:hover {
  background-color: #571111;
}

.default-btn:hover{
  background-color: #571111;
}

//首页loading
.preloader{

}
*/
.el-button:hover {
  background-color: #804e22 !important;
  border-color: #804e22 !important;
}

.el-button--primary {
  --el-button-bg-color: #804e22 !important;
  --el-button-border-color: #804e22 !important;
}
.el-button:hover{
  color: white;
}
</style>
