<template>

  <div style="padding-bottom: 1.6rem">
    <div class="blog-area ">
      <div class="container">

        <div class="row align-items-center">
          <div class="col-lg-3">
            <div class="section-title-warp">
              <span class="sub-title">
                <i class="flaticon-hashtag-symbol"></i>
                新闻动态
              </span>
              <div style="font-size: 1.4rem;margin-top: 0.5rem">
                随时关注医院的最新动态
              </div>
            </div>
          </div>

<!--          <div class="col-lg-3">-->
<!--            <div class="section-warp-btn" style="overflow: visible;">-->
<!--              <router-link to="/blog" class="default-btn">-->
<!--                查看更多-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <!--      <div class="row">-->
        <!--        <div class="col-lg-4 col-md-6 list-group mx-1">-->
        <!--          <router-link :to="{ path: '/blog-details', query: { index: index }}" v-for="(info, index) in newsInfo" :key="info.title"-->
        <!--                       class="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">-->
        <!--            <div class="custom-image-container" style="flex-shrink: 0">-->
        <!--              <img :src="info.cover" alt="avatar"  class="img-fluid">-->
        <!--            </div>-->
        <!--            <div class="d-flex gap-2 w-100 justify-content-between">-->
        <!--              <div>-->
        <!--                <h6 class="mb-0">{{ info.title }}</h6>-->
        <!--                <p class="mb-0 opacity-75 desc">{{ info.desc }}</p>-->
        <!--              </div>-->
        <!--              <small class="opacity-50 text-nowrap">time</small>-->
        <!--            </div>-->
        <!--          </router-link>-->
        <!--        </div>-->
        <!--      </div>-->
      </div>



    </div>


    <div class="container" >
      <div class="row" >
        <div class="">
          <div v-for="(info, index) in newsInfo" :key="info.title">
            <router-link :to="{ path: '/blog-details', query: { index: info.article_id }}"
                         class="list-group-item list-group-item-action  gap-3" aria-current="true"  style="display: block;">
              <div class="border-bottom d-flex gap-3 py-3">
                <div class="d-flex align-items-center">
                  <div class="image-container" style="flex-shrink: 0">
                    <img :src="info.thumb_url" alt="avatar" class="img-fluid">
                  </div>
                </div>
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <div class="mb-0 title-class" style="font-size: 1.1rem">{{ info.title }}</div>
                    <div class="mb-0 desc-class" style="font-size: 0.9rem">{{ info.digest }}</div>
                  </div>
                  <!--                <small class="opacity-50 text-nowrap pt-1">08-11</small>-->
                </div>
              </div>

            </router-link>
          </div>

        </div>
      </div>
    </div>

    <router-link to="/blog" style="width: 100%">
      <div style="text-align: center;font-weight: 400" class="mt-4">
        点击查看更多
      </div>
    </router-link>

  </div>


</template>

<script setup>

import {ref} from "vue";
import api from "@/request/api";
import Api from "@/request/api";

defineProps({
  name: {
    type: String,
    default: 'BlogHome'
  }
});

let wholeNews = [];
const newsInfo = ref([]);

/*api.getRHYYInfo()
    .then(res => {
      console.log(res)
      wholeNews = res.data[4].contents;
      newsInfo.value = wholeNews.slice(0, 6);
    })
    .catch(err => {
      console.log(err);
    })*/

Api.findNewsList(4, 0)
    .then(res => {
      console.log("新闻接口返回", res);
      newsInfo.value = res.data

    })



</script>
<style scoped>
.custom-image-container {
  width: 60px !important;
  height: 60px !important;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.custom-image-container img {
  width: 100% !important;           /* 图片填满容器 */
  height: 100% !important;          /* 图片填满容器 */
  object-fit: cover;     /* 确保保持比例的同时填充整个容器 */
}

.desc {
  display: -webkit-box;           /* 使用 flexbox 包装器 */
  -webkit-box-orient: vertical;  /* 向上/向下排列元素 */
  -webkit-line-clamp: 2;         /* 限制为 2 行 */
  overflow: hidden;               /* 隐藏溢出内容 */
  text-overflow: ellipsis;       /* 省略号 */
  height: 3em;                   /* 给定高度以适应 2 行文本 */
  line-height: 1.5em;            /* 设置行高以控制行与行之间的间距 */
}

* {
  box-sizing: border-box; /* 所有元素使用 border-box 模型 */
}


.title-class {
  font-size: 1.3rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8;
  max-height: 3.6em;
}

.desc-class {
  color: #818181;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8;
  max-height: 3.6em;
}

</style>