<template>
  <div class="features-area" style="padding-top: 2rem">
    <div class="container d-flex justify-content-center">
      <div class="row" style="max-width: 51.5rem">

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 little">
          <div class="single-features little-item outer-class" :style="{ backgroundImage: 'url(' + 'https://api.zhycit.com/rhyy' + hospitalInfoBGImg + ')' }">

            <router-link to="/about-us" >
              <row class="d-flex">
                <div class="d-flex justify-content-center align-items-center img-margin-left" style="max-width: 33px;">
                  <img :src="'https://api.zhycit.com/rhyy' + hospitalInfo" alt="动态图片"/>
                </div>
                <div class="position-class">
                  <div>
                    医院介绍
                  </div>
                  <p style="font-size: 0.8rem">{{ hospitalInfoDesc }}</p>
                </div>
              </row>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 little">
          <div class="single-features little-item outer-class" :style="{ backgroundImage: 'url(' + 'https://api.zhycit.com/rhyy' + departBGImg + ')' }">
            <router-link to="/rooms">
              <row class="d-flex">
                <div class="d-flex justify-content-center align-items-center img-margin-left" style="max-width: 36px;">
                  <img :src="'https://api.zhycit.com/rhyy' + depart" alt="动态图片"/>
                </div>
                <div class="position-class">
                  <div>
                    特色科室
                  </div>
                  <p style="font-size: 0.8rem">{{ departDesc }}</p>
                </div>
              </row>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 little">
          <div class="single-features little-item outer-class " :style="{ backgroundImage: 'url(' + 'https://api.zhycit.com/rhyy' + (isMobile ? navToHospitalBGImg : expertBGImg) + ')' }">
            <router-link to="/dentist">
              <row class="d-flex">
                <div class="d-flex justify-content-center align-items-center img-margin-left" style="max-width: 32px;">
                  <img :src="'https://api.zhycit.com/rhyy' + expert" alt="动态图片"/>
                </div>
                <div class="position-class">
                  <div>
                    专家推荐
                  </div>
                  <p style="font-size: 0.8rem">{{ expertDesc }}</p>
                </div>
              </row>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 little">
          <div class="single-features little-item outer-class" :style="{ backgroundImage: 'url(' + 'https://api.zhycit.com/rhyy' + (isMobile ? expertBGImg : navToHospitalBGImg) + ')' }">

<!--            <div class="ball"></div>-->
            <router-link to="/to-hospital-nav" class="top-class">
              <row class="d-flex">
                <div class="d-flex justify-content-center align-items-center img-margin-left" style="max-width: 34px;">
                  <img :src="'https://api.zhycit.com/rhyy' + navToHospital" alt="动态图片"/>
                </div>
                <div class="position-class">
                  <div>
                    来院导航
                  </div>
                  <p style="font-size: 0.8rem">{{ navToHospitalDesc }}</p>
                </div>
              </row>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 little">
          <div class="single-features little-item outer-class" :style="{ backgroundImage: 'url(' + 'https://api.zhycit.com/rhyy' + guideBGImg + ')' }">
            <router-link to="/medical-guidelines">
              <row class="d-flex">
                <div class="d-flex justify-content-center align-items-center img-margin-left" style="max-width: 33px;">
                  <img :src="'https://api.zhycit.com/rhyy' + guide" alt="动态图片"/>
                </div>
                <div class="position-class">
                  <div>
                    就医指南
                  </div>
                  <p style="font-size: 0.8rem">{{ guideDesc }}</p>
                </div>
              </row>
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 little">
          <div class="single-features little-item outer-class" :style="{ backgroundImage: 'url(' + 'https://api.zhycit.com/rhyy' + registeredBGImg  + ')' }">
<!--            <div class="ball"></div>-->
            <router-link to="/dentist" class="top-class">
              <row class="d-flex">
                <div class="d-flex justify-content-center align-items-center img-margin-left" style="max-width: 30px;">
                  <img :src="'https://api.zhycit.com/rhyy' + registered" alt="动态图片"/>
                </div>
                <div class="position-class">
                  <div>
                    预约挂号
                  </div>
                  <p style="font-size: 0.8rem">{{ registeredDesc }}</p>
                </div>
              </row>
            </router-link>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/request/api";

export default {
  data() {
    return {
      hospitalInfo: "",
      hospitalInfoBGImg: "",
      hospitalInfoDesc: "",
      depart: "",
      departBGImg: "",
      departDesc: "",
      expert: "",
      expertBGImg: "",
      expertDesc: "",
      navToHospital: "",
      navToHospitalBGImg: "",
      navToHospitalDesc: "",
      guide: "",
      guideBGImg: "",
      guideDesc: "",
      registered: "",
      registeredBGImg: "",
      registeredDesc: "",
      isMobile: false,
    }
  },
  name: 'Features',
  methods: {
    getInfo() {
      Api.getXCXInfo()
          .then((res) => {
            console.log("--------------------")
            this.hospitalInfo = res.data[0].contents[0].attributes.iconImg
            this.hospitalInfoBGImg = res.data[0].contents[0].attributes.bgImg
            this.hospitalInfoDesc = res.data[0].contents[0].attributes.desc

            this.guide = res.data[0].contents[4].attributes.iconImg
            this.guideBGImg = res.data[0].contents[4].attributes.bgImg
            this.guideDesc = res.data[0].contents[4].attributes.desc

            this.expert = res.data[0].contents[2].attributes.iconImg
            this.expertBGImg = res.data[0].contents[2].attributes.bgImg
            this.expertDesc = res.data[0].contents[2].attributes.desc

            this.navToHospital = res.data[0].contents[3].attributes.iconImg
            this.navToHospitalBGImg = res.data[0].contents[3].attributes.bgImg
            this.navToHospitalDesc = res.data[0].contents[3].attributes.desc

            this.registered = res.data[0].contents[5].attributes.iconImg
            this.registeredBGImg = res.data[0].contents[5].attributes.bgImg
            this.registeredDesc = res.data[0].contents[5].attributes.desc

            this.depart = res.data[0].contents[1].attributes.iconImg
            this.departBGImg = res.data[0].contents[1].attributes.bgImg
            this.departDesc = res.data[0].contents[1].attributes.desc

          })
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 992; // 更新 isMobile 的状态
    }
  },
  mounted() {
    this.getInfo();
    this.isMobile = window.innerWidth <= 992; // 初始化 isMobile
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>
<style scoped>
>>> a {
  color: white;
  font-size: 1rem;
}

>>> p {
  color: white;
  font-size: 0.9rem;
}

/*.image-container {
  width: 60px;          !* 固定宽度 *!
  height: 60px;         !* 固定高度 *!
  !*overflow: hidden;     !* 溢出隐藏，以确保只显示中间的部分 *!*!
  !*border-radius: 50%;   !* 使容器为圆形 *!*!
  position: relative;    !* 以便于定位内部的 img 标签 *!
}

.image-container img {
  width: 100%;           !* 图片填满容器 *!
  height: 100%;          !* 图片填满容器 *!
  !*object-fit: cover;     !* 确保保持比例的同时填充整个容器 *!*!
}*/

.position-class {
  color: white;
  margin-left: 1rem;
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 574px) {
  .little{
    display: flex;
    justify-content: center;
  }
  .little-item{
    width: 60%;
  }
}
.ball {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgb(128, 78, 34);
  border-radius: 50%;
  left: -17px;
  bottom: 16%;
  transform: translateY(50%);
  z-index: 1;
}


.top-class{
  z-index: 10;
  position: relative
}

/*.outer-class{
  background-color: rgb(116 69 26);
  position: relative;
  overflow: hidden
}*/
.outer-class {
  position: relative; /* 确保子元素可以绝对定位 */
  background-size: cover; /* 覆盖整个容器 */
  background-position: center; /* 将背景图像居中 */
  border-radius: 10px; /* 可选：添加圆角 */
  overflow: hidden; /* 确保超出部分隐藏 */
}
@media (max-width: 767.98px) {
  .col-xs-6 {
    width: 50%;
    float: left;
  }
}
/*@media (max-width: 574px) {
  .little-item[data-v-37618306] {
    width: 100% !important;
  }
}*/
@media (max-width: 574px) {
  >>>  .little-item {
    width: 100%;
  }
}


.img-margin-left{
  margin-left: 10px
}
@media (max-width: 574px) {
  .img-margin-left{
    margin-left: 0px!important;
  }
}
</style>