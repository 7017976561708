<template>
    <div>
        <PageBanner pageTitle="科室详情" secondPageTitle="特色科室" :secondPageUrl="secondPageUrl" :type="type"/>
        <RoomDetails @sendPage="sendPage"/>
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import RoomDetails from "@/components/Room/RoomDetails.vue";
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
export default {
    components: {
      RoomDetails,
      PageBanner,
    },
    data: () => {
        return {
            type: ''
        }
    },
    created() {
        this.type = this.$route.query?.type
        console.log('detail = ',this.type)
    },
  setup() {
    const route = useRoute();
    const secondPageUrl = ref({
      url: '',
      query: { page: 1 },
    });

    const sendPage = (data) => {
      secondPageUrl.value.query.page = data;
    };

    onMounted(() => {
      if (route.path.startsWith('/app')) {
        secondPageUrl.value.url = '/app/rooms';
        secondPageUrl.value.query = { page: 1,'type':route.query?.type };
        console.log(secondPageUrl.value.url);
      } else {
        secondPageUrl.value.url = '/rooms';
      }
    });

    return {
      secondPageUrl,
      sendPage,
    };
  }
}
</script>
