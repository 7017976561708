<template>
    <div>
        <PageBanner pageTitle="Terms of Service" />
        <TermsService />
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import TermsService from '../terms-of-service/TermsService'

export default {
    components: {
        PageBanner,
        TermsService,
    }
}
</script>