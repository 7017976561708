import { defineStore } from 'pinia';

export const useDoctorStore = defineStore('doctor', {
    state: () => ({
        doctors: [],  // 存放医生列表
    }),
    actions: {
        setDoctors(doctorList) {
            this.doctors = doctorList;  // 设置医生列表
            this.saveToLocalStorage();
        },
        clearDoctors() {
            this.doctors = [];  // 清空医生列表
        },
        saveToLocalStorage() {
            localStorage.setItem('doctors', JSON.stringify(this.doctors));  // 保存数据到 localStorage
        },
        loadFromLocalStorage() {
            const storedData = localStorage.getItem('doctors');
            if (storedData) {
                this.doctors = JSON.parse(storedData);  // 从 localStorage 读取数据
            }
        },
    },
    getters: {
        getDoctorById: (state) => {
            return (id) => {
                return state.doctors.find(doctor => doctor.ysId === id) || null;
            }
        },
        getDoctorByName: (state) => {
            return (name) => {
                return state.doctors.find(doctor => doctor.ysxm === name) || null;
            }
        }
    }
});