<template>
    <div class="widget-area">
        <div class="widget widget_search">
            <form class="search-form">
                <input type="search" class="search-field" placeholder="Search...">
                <button type="submit"><i class='bx bx-search-alt'></i></button>
            </form>
        </div>

        <div class="widget widget_info">
            <div class="image">
                <img src="../../assets/images/doctor/doctor-4.jpg" alt="image">
            </div>
            <div class="content">
                <h3>Daisy Gabriela</h3>
                <p>Lorem ipsum dolor sit amet, consectetur  incidunt ut labore et dolore magnam </p>

                <div class="share-link">
                    <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                    <a href="https://twitter.com/?lang=en" target="_blank"><i class='bx bxl-twitter'></i></a>
                    <a href="https://www.linkedin.com/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                    <a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a>
                </div>
            </div>
        </div>

        <div class="widget widget_grin_posts_thumb">
            <h3 class="widget-title">Recent posts</h3>

            <div class="item">
                <router-link to="/blog-details" class="thumb">
                    <span class="fullimage cover bg1" role="img"></span>
                </router-link>
                <div class="info">
                    <span>November 27, 2022</span>
                    <h4 class="title usmall">
                        <router-link to="/blog-details">
                            New Technology Make for Dental Operation
                        </router-link>
                    </h4>
                </div>
            </div>

            <div class="item">
                <router-link to="/blog-details" class="thumb">
                    <span class="fullimage cover bg2" role="img"></span>
                </router-link>
                <div class="info">
                    <span>November 27, 2022</span>
                    <h4 class="title usmall">
                        <router-link to="/blog-details">
                            Regular Dental care make Your Smile Brighter
                        </router-link>
                    </h4>
                </div>
            </div>

            <div class="item">
                <router-link to="/blog-details" class="thumb">
                    <span class="fullimage cover bg3" role="img"></span>
                </router-link>
                <div class="info">
                    <span>November 27, 2022</span>
                    <h4 class="title usmall">
                        <router-link to="/blog-details">Dental Hygiene for All Age to Make Smile</router-link>
                    </h4>
                </div>
            </div>
        </div>

        <div class="widget widget_categories">
            <h3 class="widget-title">Categories</h3>

            <ul>
                <li><router-link to="/blog-right-sidebar">Dental</router-link></li>
                <li><router-link to="/blog-right-sidebar">Medical</router-link></li>
                <li><router-link to="/blog-right-sidebar">Dentist</router-link></li>
                <li><router-link to="/blog-right-sidebar">Hygiene</router-link></li>
                <li><router-link to="/blog-right-sidebar">Hospital</router-link></li>
                <li><router-link to="/blog-right-sidebar">Child Care</router-link></li>
            </ul>
        </div>

        <div class="widget widget_tag_cloud">
            <h3 class="widget-title">Popular Tags</h3>

            <div class="tagcloud">
                <router-link to="/blog-right-sidebar">Business</router-link>
                <router-link to="/blog-right-sidebar">Privacy</router-link>
                <router-link to="/blog-right-sidebar">Technology</router-link>
                <router-link to="/blog-right-sidebar">Tips</router-link>
                <router-link to="/blog-right-sidebar">Uncategorized</router-link>
            </div>
        </div>

        <div class="widget widget_instagram">
            <h3 class="widget-title">Instagram Images</h3>

            <ul>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-1.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-2.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-3.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-4.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-5.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-6.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-7.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-8.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
                <li>
                    <div class="box">
                        <img src="../../assets/images/gallery/gallery-9.jpg" alt="image">
                        <i class="bx bxl-instagram"></i>
                        <a href="https://www.instagram.com/" target="_blank" class="link-btn"></a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogSidebar'
}
</script>