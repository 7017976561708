<template>
  <div class="features-area pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="single-features">
            <div class="content">
              <div class="icon">
                <i class="flaticon-doctor"></i>
              </div>
              <h3>
                <router-link to="/dentist">专业医生团队</router-link>
              </h3>
              <p>经验丰富的中西医专家为您提供高质量医疗服务。</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-features bg-f57e57">
            <div class="content">
              <div class="icon">
                <i class="flaticon-chair"></i>
              </div>
              <h3>
                <router-link to="/rooms">特色科室</router-link>
              </h3>
              <p>提供中西医结合的特色治疗，满足多种疾病需求。</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
          <div class="single-features bg-4a6577">
            <div class="content">
              <div class="icon">
                <i class="flaticon-healthcare"></i>
              </div>
              <h3>
                <router-link to="/blog">新闻动态</router-link>
              </h3>
              <p>了解医院最新新闻动态与健康资讯，掌握第一手医疗信息。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>