<template>
    <div>
        <div class="appointment-overview-area pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <div class="appointment-overview-contact">
                            <img src="../../assets/images/appointment/appointment-1.jpg" alt="image">

                            <div class="appointment-info">
                                <ul class="call-info">
                                    <li>
                                        <i class="flaticon-emergency-call"></i>
                                        Call Today
                                        <span><a href="tel:088123654987">+088 123 654 987</a></span>
                                    </li>
                                </ul>
                                <p>Aenean sollicitudin, here lorem lorem quis auctor nisi elit lorem nec.</p>
                                <router-link to="/contact-us" class="default-btn">
                                    Contact Us
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <div class="appointment-overview-box">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="content">
                                        <h4>We Are a Certified and Award-winning Hospital</h4>
                                        
                                        <div class="box-image">
                                            <img src="../../assets/images/appointment/appointment-2.jpg" alt="image">

                                            <div 
                                                class="video-btn popup-youtube"
                                                v-on:click="isPopupMethod(isPopup)"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="make-appointment-content">
                                        <h4>
                                            <i class="flaticon-calendar"></i> 
                                            Make An Appointment
                                        </h4>

                                        <form>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Email">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Phone">
                                            </div>
            
                                            <button type="submit" class="default-btn">Book Appointment</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Appointment',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>