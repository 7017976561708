<template>
  <div class="doctor-area pt-4 pb-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="section-title-warp">
              <span class="sub-title">
                <i class="flaticon-hashtag-symbol"></i>
                专家介绍
              </span>
            <div style="font-size: 1.4rem;margin-top: 0.5rem">
              院内的特色科室
            </div>
          </div>
        </div>

<!--        <div class="col-lg-5">-->
<!--          <div class="section-warp-btn">-->
<!--            <router-link to="/dentist" class="default-btn">-->
<!--              查看更多-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="row">


        <!--                <div class="col-lg-4 col-md-6 list-group mx-1">-->
        <!--                  <router-link :to="{ path: '/dentist-details', query: { name: expertInfo.author, index: index  }}" v-for="(expertInfo, index) in expertsInfos" :key="index" class="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">-->
        <!--                    <div class="image-container" style="flex-shrink: 0">-->
        <!--                      <img :src="expertInfo.cover" alt="avatar"  class="img-fluid">-->
        <!--                    </div>-->
        <!--                    <div class="d-flex gap-2 w-100 justify-content-between">-->
        <!--                      <div>-->
        <!--                        <h6 class="mb-0">{{ expertInfo.author }}</h6>-->
        <!--                        <p class="mb-0 opacity-75">{{ expertInfo.attributes.job }}</p>-->
        <!--                      </div>-->
        <!--                      <small class="opacity-50 text-nowrap">{{ expertInfo.attributes.visitTime }}</small>-->
        <!--                    </div>-->
        <!--                  </router-link>-->
        <!--                </div>-->
      </div>

      <div >
        <div >
          <router-link :to="{ path: '/dentist-details', query: { name: expertInfo.ysxm, index: expertInfo.ysid  }}" v-for="(expertInfo, index) in expertsInfos" :key="index"
                       aria-current="true">
            <div class="border-bottom d-flex gap-3 py-3">
              <div class="d-flex align-items-center">
                <div class="image-container" style="flex-shrink: 0">
                  <img :src="expertInfo.headimg" alt="avatar"  class="img-fluid">
                </div>
              </div>

              <div class="mb-0" style="width: 100%">
                <div style="display: flex;justify-content: space-between">
                  <div class="mb-0" style="font-size: 1.1rem;display: inline-block;font-weight: 600">
                    {{ expertInfo.ysxm}}
                  </div>
                  <div style="display: inline-block;">
                    <button type="button" class="custom-button">预约</button>
                  </div>
                </div>
                <div class="mb-0 description-class" style="font-size: 1rem">{{ expertInfo.zhicheng }}</div>
                <div class="mb-0 description-class" style="font-size: 1rem">
                  {{ expertInfo.ysjj }}
                </div>
              </div>
            </div>
          </router-link>

        </div>
      </div>

      <router-link to="/dentist" style="width: 100%">
        <div style="text-align: center;font-weight: 400" class="mt-4">
          点击查看更多
        </div>
      </router-link>

    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {getDoctorInfo} from "@/api/doctor/doctorApi";
import Api from "@/request/api";

defineProps({
  name: {
    type: String,
    default: 'DoctorHome'
  }
});

let wholeExperts = [];
const expertsInfos = ref([]);

onMounted(() => {

  const params = ref({
    doctorName: '',
    ksmcSel: null,
    jzrqSel: ''
  })
  Api.getDoctorList(params.value)
      .then((res) => {
        wholeExperts = res.data;
        expertsInfos.value = wholeExperts.slice(0, 4);
      })

})
</script>
<style>
.img-cropping {
  height: 416px;
  width: 416px;
  object-fit: cover
}

.image-container {
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}
.custom-button {
  font-size: 15px;
  padding: 1px 15px;
  border-radius: 20px;
  border: none;
  background-color: #804e22;
  color: #fff;
  text-align: center;
}

.description-class {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>