<template>
  <div class="home-slides">
    <carousel
        :autoplay="5000"
        :wrap-around="true"
    >
      <slide
          v-for="(image, index) in imgList"
          :key="index"
      >
        <div class="main-slides-item-cust"
             :style="{ backgroundImage: 'url(' + image + ')' }">
          <!--                    <div class="container">-->
          <!--                        <div class="main-slides-content">-->
          <!--                            <span class="sub-title">-->
          <!--                                <i class="flaticon-hashtag-symbol"></i>-->
          <!--                                {{slide.subtitle}}-->
          <!--                            </span>-->
          <!--                            <h1>{{slide.heading}}</h1>-->
          <!--                            <p>{{slide.description}}</p>-->

          <!--                            <div class="slides-btn">-->
          <!--                                <router-link-->
          <!--                                    :to="slide.btnLinkOne"-->
          <!--                                    class="default-btn"-->
          <!--                                >-->
          <!--                                    {{slide.btnTextOne}}-->
          <!--                                </router-link>-->

          <!--                                <div class="checkup-content">-->
          <!--                                    <div class="icon">-->
          <!--                                        <i class="flaticon-chair"></i>-->
          <!--                                    </div>-->
          <!--                                    <span>-->
          <!--                                        <router-link :to="slide.btnLinkTwo">-->
          <!--                                            {{slide.btnTextTwo}}-->
          <!--                                        </router-link>-->
          <!--                                    </span>-->
          <!--                                </div>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
        </div>
      </slide>

      <template #addons>
        <Navigation/>
      </template>
    </carousel>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {Carousel, Slide, Navigation} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import api from "@/request/api";

export default defineComponent({
  name: 'MainBanner',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    imgList:[],
    imageUrls: [
      'https://api.zhycit.com/rhyy/upload/2024/07-04/15-20-190816-736301778.png',
      'https://api.zhycit.com/rhyy/upload/2024/07-04/14-59-100169312586804.jpg',
      'https://api.zhycit.com/rhyy/upload/2024/07-04/14-58-540156141668546.jpg',
      // ...更多图片路径
    ],
    carouselItems: [
      {
        id: 1,
        class: 'main-slides-item',
        subtitle: 'Keeping Teeth Clean',
        heading: 'Personalized Dental Care',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        btnTextOne: 'Book Appointment',
        btnTextTwo: 'Get Free Checkup',
        btnLinkOne: '/appointment',
        btnLinkTwo: '/contact-us',
      },

      // {
      //     id: 2,
      //     class: 'main-slides-item item-bg2',
      //     subtitle: 'Keeping Teeth Clean',
      //     heading: 'Exceptional Care for Women',
      //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //     btnTextOne: 'Book Appointment',
      //     btnTextTwo: 'Get Free Checkup',
      //     btnLinkOne: '/appointment',
      //     btnLinkTwo: '/contact-us',
      // },
      //
      // {
      //     id: 3,
      //     class: 'main-slides-item item-bg3',
      //     subtitle: 'Keeping Teeth Clean',
      //     heading: 'Your Health is Our Top Priority',
      //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //     btnTextOne: 'Book Appointment',
      //     btnTextTwo: 'Get Free Checkup',
      //     btnLinkOne: '/appointment',
      //     btnLinkTwo: '/contact-us',
      // },
    ],
  }),
  methods:{
    init(){
      api.getRHYYInfo()
          .then(res => {
            console.log(res);
            if (res){
              this.imgList = res.data[5].contents[1].images;
            }
          })
          .catch(err => {
            console.log("About :: init :: getRHYYInfo() :: error: ", err);
          })
    }
  },
  created() {
    this.init()
  }
})
</script>
<style>
.main-slides-item-cust {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 490px;
  padding-bottom: 250px;
  width: 100%;
  text-align: left;
}
</style>