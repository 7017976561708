<script setup>
import {computed, h, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useUserStore} from "@/stores/useUserStore.ts";
import Api from "@/request/api";
import {ElNotification} from "element-plus";
import EventBus from "@/utils/event-bus";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";
import CryptoJS from 'crypto-js';


const route = useRoute();
const openid = route.query.openid;
const patientId = route.query.patientId;
console.log("openid", openid);
const documentNumber = ref("")

onMounted(() => {
  console.log("patientId", patientId)
  if (patientId != null && patientId != '' && patientId != undefined){
    Api.findPatientInfoBy_Id(patientId)
        .then(res => {
          console.log("patientId res",res)
          form.value.name = res.data.name;
          form.value.gender = res.data.gender;
          form.value.phone = res.data.phone;
          form.value.documentType = res.data.documentType;
          form.value.address = res.data.address;
          documentNumber.value = decryptAES(res.data.documentNumber);
          form.value._id = patientId
        })
  }
})

const isApp = computed(() => {
  if (!route.path.startsWith('/app')) {
    return false;
  } else {
    return true;
  }
})

const form = ref({
  name: '',
  gender: '1', // 默认男性
  phone: '',
  documentType: '1', // 默认身份证
  documentNumber: '',
  address: '',
});

const error = ref("")

const validate = () => {
  error.value = '';
  // 校验必填项
  if (!form.value.name) {
    error.value = '姓名是必填项';
    return false;
  }
  if (form.value.name){
    const nameRegex = /^[a-zA-Z\u4E00-\u9FA5]+$/;
    if (!nameRegex.test(form.value.name)) {
      error.value = '姓名只能是汉字或字母';
      return false;
    }
  }
  if (!form.value.phone) {
    error.value = '手机号是必填项';
    return false;
  } else if (!/^1[3-9]\d{9}$/.test(form.value.phone)) { // 验证手机号格式
    error.value = '手机号格式不正确';
    return false;
  }
  if (!documentNumber.value) {
    error.value = '证件号是必填项';
    return false;
  }
  if(form.value.documentType === '1'){
    const idCardRegex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; // 身份证正则
    if (!idCardRegex.test(documentNumber.value)) {
      error.value = '身份证号码格式不正确';
      return false;
    }
  }
  console.log("form.value.documentType", form.value.documentType)
  console.log("form.value.documentType", form.value.documentType === '2')
  if(form.value.documentType === '2'){
    // const passportRegex = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/; // 护照正则
    const passportRegex = /^[a-zA-Z0-9]{5,17}$/; // 护照正则
    if (passportRegex.test(documentNumber.value) || validateSecIdCard15(documentNumber.value) || isForeignerIdCard18(documentNumber.value)) {
      return true;
    }
    error.value = '护照号码格式不正确';
    return false;
  }

  return true;
};

const router = useRouter();

const handleSubmit = () => {
  if(isApp.value){
    form.value.openId = useUserXcxStore().getOpenid()
  } else {
    if(!useUserStore().isLogin()){
      EventBus.emit("goLogin",'')
    }
    form.value.openId = useUserStore().getUserInfo().openId
  }

  if (validate()){

    // console.log('decrypt = ', decrypt(form.value.documentNumber))
    console.log("form.value.documentNumber", documentNumber.value)
    form.value.documentNumber = CryptoJS.AES.encrypt(documentNumber.value, CryptoJS.enc.Utf8.parse('1234567890123456'), {
      iv: CryptoJS.enc.Utf8.parse('InitializationVe'),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    console.log("form.value.documentNumber111", form.value)
    console.log("form.value.documentNumber", form.value.documentNumber)

    Api.savePatientInfo(form.value)
        .then(res => {
          if (res.status === 1){
            ElNotification({
              title: '添加就诊人',
              message: h(
                  'i',
                  { style: 'color: black' },
                  res.data,
              ),
            });
            router.back();
          } else {
            ElNotification({
              title: '添加就诊人',
              message: h(
                  'i',
                  { style: 'color: black' },
                  res.data,
              ),
            });
          }
        })
  } else {
    console.log("error", error.value)
    ElNotification({
      title: '添加就诊人',
      message: h(
          'i',
          { style: 'color: black' },
          error.value,
      ),
    });
  }

}
//解密
const decrypt = (ciphertext) => {
  const decrypted = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse('1234567890123456'), {
    iv: CryptoJS.enc.Utf8.parse('InitializationVe'),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8); // 返回解密后的明文
}
function decryptAES(encryptedData) {
  const key = CryptoJS.enc.Utf8.parse("1234567890123456"); // 16字节秘钥
  const iv = CryptoJS.enc.Utf8.parse("InitializationVe"); // 16字节初始化向量

  // Base64 解码加密的数据
  const encryptedHex = CryptoJS.enc.Base64.parse(encryptedData);

  // 创建 CipherParams 对象
  const cipherParams = {
    ciphertext: encryptedHex
  };

  // 进行解密
  const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  // 返回解密后的字符串
  return decrypted.toString(CryptoJS.enc.Utf8); // 返回 UTF-8 字符串
}

function validateSecIdCard15 (value) {
  if (!value) return true
  var iSum = 0
  var sId = value
  var aCity = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙',
    21: '辽宁',
    22: '吉林',
    23: '黑龙',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    83: '台湾',
    91: '国外'
  }
  // 这里使用了简单正则，要是想，可以使用上面那个版的正则
  if (!/^\d{17}(\d|x)$/i.test(sId)) {
    return false
  }
  sId = sId.replace(/x$/i, 'a')
  // 非法地区
  if (aCity[parseInt(sId.substr(0, 2))] === null) {
    return false
  }
  var sBirthday = sId.substr(6, 4) + '-' + Number(sId.substr(10, 2)) +
      '-' + Number(sId.substr(12, 2))
  var d = new Date(sBirthday.replace(/-/g, '/'))
  // 非法生日
  if (sBirthday !== (d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d
      .getDate())) {
    return false
  }
  for (let i = 17; i >= 0; i--) {
    iSum += (Math.pow(2, i) % 11) * parseInt(sId.charAt(17 - i), 11)
  }
  if (iSum % 11 !== 1) {
    return false
  }
  return true
}

const FOREIGNER_ID_CARD_CHECK_CODE = [
  "1",
  "0",
  "X",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2"
];
function isForeignerIdCard18(idCardStr) {
  if (idCardStr.length !== 18) {
    return false;
  }

  // 本体码
  const a = new Array(17);
  // 加权因子
  const weights = new Array(17);
  // 乘积
  const product = new Array(17);
  const charArray = idCardStr.split("");
  for (let i = 18; i > 1; i--) {
    a[18 - i] = charArray[18 - i] - "0";
    weights[18 - i] = Math.pow(2, i - 1) % 11;
    product[18 - i] = a[18 - i] * weights[18 - i];
  }

  // 乘积之和取模
  const modulus = product.reduce((a, b) => a + b) % 11;
  const checkDigit = charArray[17] - "0";
  var s1 = FOREIGNER_ID_CARD_CHECK_CODE[modulus];
  var s2 = String(checkDigit);
  var flag = s1 === s2;
  debugger;
  // 校验码比对
  return FOREIGNER_ID_CARD_CHECK_CODE[modulus] === String(checkDigit);
}

</script>

<template>
  <!--  <div class="d-flex; justify-content-center align-items-center">-->
  <!--   -->
  <!--  </div>-->

  <!--    <div class="d-flex align-items-center">-->
  <!--      <div class="kv-list" style="width: 53rem;">-->
  <!--        <div class="kv-item">-->
  <!--          <span class="kv-key">姓名:</span>-->
  <!--          <span class="kv-value">-->
  <!--          <input type="text" class="form-control" placeholder="请输入就诊人姓名" aria-label="Username"-->
  <!--                 aria-describedby="addon-wrapping">-->
  <!--        </span>-->
  <!--        </div>-->
  <!--        <hr/>-->
  <!--        <div class="kv-item">-->
  <!--          <span class="kv-key">性别:</span>-->
  <!--          <span class="kv-value d-flex justify-content-between">-->
  <!--            <div class="form-check" style="margin-left: 0.7rem">-->
  <!--              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>-->
  <!--                <label class="form-check-label" for="exampleRadios1">男</label>-->
  <!--            </div>-->
  <!--            <div class="form-check" style="margin-left: 1.5rem">-->
  <!--              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>-->
  <!--                <label class="form-check-label" for="exampleRadios1">女</label>-->
  <!--            </div>-->
  <!--          </span>-->
  <!--        </div>-->
  <!--        <hr/>-->
  <!--        <div class="kv-item">-->
  <!--          <span class="kv-key">电话:</span>-->
  <!--          <span class="kv-value">-->
  <!--          <input type="text" class="form-control" placeholder="请输入就诊人电话" aria-label="Username"-->
  <!--                 aria-describedby="addon-wrapping">-->
  <!--        </span>-->
  <!--        </div>-->
  <!--        <hr/>-->
  <!--        <div class="kv-item">-->
  <!--          <span class="kv-key">证件类型:</span>-->
  <!--          <span class="kv-value">-->
  <!--                <input type="text" class="form-control" placeholder="请输入就诊人证件类型" aria-label="Username"-->
  <!--                       aria-describedby="addon-wrapping">-->

  <!--        </span>-->
  <!--        </div>-->
  <!--        <hr/>-->
  <!--        <div class="kv-item">-->
  <!--          <span class="kv-key">证件号:</span>-->
  <!--          <span class="kv-value">-->
  <!--                <input type="text" class="form-control" placeholder="请输入就诊人证件号" aria-label="Username"-->
  <!--                       aria-describedby="addon-wrapping">-->
  <!--        </span>-->
  <!--        </div>-->
  <!--        <hr/>-->
  <!--        <div class="kv-item">-->
  <!--          <span class="kv-key">地址:</span>-->
  <!--          <span class="kv-value">-->
  <!--                <input type="text" class="form-control" placeholder="请输入就诊人地址" aria-label="Username"-->
  <!--                       aria-describedby="addon-wrapping">-->
  <!--        </span>-->
  <!--        </div>-->

  <!--      </div>-->
  <!--    </div>-->

  <div class="d-flex justify-content-center" style="padding-bottom: 50px">
    <div style="width: 53rem;">
      <div class="container">
        <div class="login-form">
          <h4>添加就诊人</h4>

          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label>姓名</label>
              <input type="text" class="form-control" placeholder="请输入姓名" v-model="form.name">
            </div>

            <div class="form-group">
              <label>性别</label>
              <span class="kv-value d-flex justify-content-start">
                <div class="form-check" style="margin-left: 0.7rem">
                  <input class="form-check-input" type="radio" name="gender" id="genderMale" value="1" v-model="form.gender">
                  <label class="form-check-label" for="genderMale">男</label>
                </div>
                <div class="form-check" style="margin-left: 1.5rem">
                  <input class="form-check-input" type="radio" name="gender" id="genderFemale" value="2" v-model="form.gender">
                  <label class="form-check-label" for="genderFemale">女</label>
                </div>
              </span>
            </div>

            <div class="form-group">
              <label>手机号</label>
              <input type="text" class="form-control" v-model="form.phone" placeholder="请输入手机号">
            </div>

            <div class="form-group">
              <label>证件类型（永居证请选择护照）</label>
              <span class="kv-value d-flex justify-content-start">
                <div class="form-check" style="margin-left: 0.7rem">
                  <input class="form-check-input" type="radio" name="documentType" id="documentIdCard" value="1" v-model="form.documentType">
                  <label class="form-check-label" for="documentIdCard">身份证</label>
                </div>
                <div class="form-check" style="margin-left: 1.5rem">
                  <input class="form-check-input" type="radio" name="documentType" id="documentPassport" value="2" v-model="form.documentType">
                  <label class="form-check-label" for="documentPassport">护照</label>
                </div>
              </span>
            </div>

            <div class="form-group">
              <label>证件号</label>
              <input type="text" class="form-control" placeholder="请输入身份证号" v-model="documentNumber">
            </div>

            <div class="form-group">
              <label>地址</label>
              <input type="text" class="form-control" placeholder="请输入地址" v-model="form.address">
            </div>


            <button type="submit">添加</button>
          </form>
        </div>
      </div>
    </div>
  </div>


</template>

<style scoped>
/*kv-list {
  max-width: 85%;
  margin: auto;
  padding: 20px;
}

.kv-item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px 0;
  font-size: 16px;
}

.kv-key {
  width: 25%;
  margin-right: 10px;
}

hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 5px 0;
}

>>> .form-control {
  border: none;
}*/

>>> .login-form form .form-group .form-control {
  height: 2.5rem;
}

>>> .login-form form .form-group {
  margin-bottom: 15px;
}

>>> .form-check-input:checked {
  background-color: #804e22;
  border-color: #804e22;
}

/* 改变单选按钮选中后的样式 */
.custom-radio input[type="radio"]:checked ~ .custom-control-label::before {
  background-color: red; /* 修改为你想要的颜色 */
  border-color: red; /* 修改为你想要的颜色 */
}

/* 可选：设置hover状态时的背景颜色 */
.custom-radio input[type="radio"]:hover ~ .custom-control-label::before {
  background-color: #ffcccc; /* 修改为你想要的颜色 */
}
</style>