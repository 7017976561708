<template>
    <div class="core-features-area pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="section-title-warp">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            Specialty and Our Features
                        </span>
                        <h2>Features That You Will Love Us</h2>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="section-warp-btn">
                        <router-link to="/services" class="default-btn">View All</router-link>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-core-features">
                        <div class="icon">
                            <i class="flaticon-caduceus"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Quality Brackets</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-core-features">
                        <div class="icon">
                            <i class="flaticon-policy"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Certified Dentists</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-core-features">
                        <div class="icon">
                            <i class="flaticon-diamond"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Experience</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-core-features">
                        <div class="icon">
                            <i class="flaticon-hospitalisation"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Patient Care</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpecialtyFeatures'
}
</script>