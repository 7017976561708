<template>
    <div class="doctor-area ptb-cust d-flex justify-content-center">
        <div class="container max-width-cust" >
          <div class="row align-items-center">
            <div class="col-lg-7">
              <div class="section-title-warp">
              <span class="sub-title">
                <i class="flaticon-hashtag-symbol"></i>
                专家介绍
              </span>
                <div style="font-size: 1.4rem; margin-top: 10px;margin-bottom: 1rem">
                  院内专业且经验丰富的专家
                </div>
              </div>
            </div>

            <div class="col-lg-5">
              <div class="section-warp-btn">
                <router-link to="/dentist" class="default-btn">
                  查看更多
                </router-link>
              </div>
            </div>
          </div>

            <div class="row">

<!--                <div class="col-lg-4 col-md-6" v-for="(expertInfo, index) in expertsInfos" :key="expertInfo.title">-->

<!--                  <router-link :to="{ path: '/dentist-details', query: { name: expertInfo.author, index: index  }}">-->
<!--                    <div class="single-doctor">-->
<!--                      &lt;!&ndash;                        <router-link :to="{ path: '/dentist-details', query: { name: expertInfo.author, index: (pagination.pageNum-1) * pagination.pageSize + index }}">&ndash;&gt;-->
<!--                      &lt;!&ndash;&lt;!&ndash;                          此处可删除&ndash;&gt;&ndash;&gt;-->
<!--                      &lt;!&ndash;                            <img src="../../assets/images/doctor/doctor-1.jpg" alt="image">&ndash;&gt;-->
<!--                      &lt;!&ndash;                        </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                      <router-link :to="{ path: '/dentist-details', query: { name: index }}">&ndash;&gt;-->
<!--                        &lt;!&ndash;                        此处可删除&ndash;&gt;-->
<!--                        <img class="img-cropping" :src="expertInfo.cover" alt="image">-->
<!--&lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--                      <div class="doctor-content">-->
<!--                        <h3>-->
<!--&lt;!&ndash;                          <router-link :to="{ path: '/dentist-details', query: { index: index }}">&ndash;&gt;-->
<!--                            {{ expertInfo.author }}-->
<!--&lt;!&ndash;                          </router-link>&ndash;&gt;-->
<!--                        </h3>-->
<!--                        <span>{{ expertInfo.attributes.job }}</span>-->
<!--                        <br/>-->
<!--                        <span>出诊时间：{{ expertInfo.attributes.visitTime }}</span>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                  </router-link>-->




<!--                </div>-->

              <div class="col-lg-3 col-md-4" v-for="(expertInfo, index) in expertsInfos" :key="expertInfo.ysid">

                <router-link :to="{ path: '/dentist-details', query: { name: expertInfo.ysxm, index: expertInfo.ysid,'type':'' }}">
                <div class="single-doctor">
                    <img class="img-cropping" :src="expertInfo.headimg" alt="image">
                    <div class="doctor-content">
                      <div style="font-size: 1.1rem">
                        {{ expertInfo.ysxm }}
                      </div>
                      <span class="desc-class">{{ expertInfo.zhicheng }}</span>
                      <span class="desc-jj">简介：{{ expertInfo.ysjj }}</span>
                    </div>
                  </div>
                </router-link>



              </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {getDoctorInfo} from "@/api/doctor/doctorApi";
import Api from "@/request/api";

  defineProps({
    name: {
      type: String,
      default: 'DoctorHome'
    }
  });

  let wholeExperts = [];
  const expertsInfos = ref([]);

onMounted(() => {

  const params = ref({
    doctorName: '',
    ksmcSel: null,
    jzrqSel: ''
  })

  Api.getDoctorList(params.value)
      .then((res) => {
        wholeExperts = res.data;
        expertsInfos.value = wholeExperts.slice(0, 4);
      })

})
</script>
<style scoped>
.img-cropping{
  //height: 345px;
  height: 250px;
  //width: 350px;
  width: 200px;
  object-fit: cover
}
.desc-class {
  font-size: 0.9rem !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /*line-height: 1.8rem !important;
  max-height: 3.6rem !important;*/
}
.desc-jj {
  font-size: 0.9rem !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /*line-height: 1.8rem !important;
  max-height: 3.6rem !important;*/
}

.ptb-cust {
  padding-top: 50px;
  padding-bottom: 50px;
}


.title-container {
  width: 100%;
  text-align: left;
  /*margin: 20px 0;*/
  position: relative;
}
.max-width-cust{
  max-width: 57rem;
}

@media (max-width: 992px) {
  .max-width-cust{
    max-width: 45rem;
  }
}


</style>