<script setup>
import Api from "@/request/api"; // 假设Api是您配置的Axios实例
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useUserXcxStore } from "@/stores/useUserXcxStore.ts";

const route = useRoute();
const type = route.query?.type
if (route.path.startsWith('/app')) {
  useUserXcxStore().setOpenid(route.query.openid)
}


const info = reactive({ data: [] });  // 使用 reactive 创建响应式对象

const fetchAppointments = () => {
  console.log('++++')
  Api.getAppointment()
    .then(res => {
      console.log(res);
      info.data = res.data; // 更新 info.data
    })
    .catch(error => {
      console.error("获取预约数据错误:", error);
    });
};

onMounted(() => {
  fetchAppointments();
  const intervalId = setInterval(fetchAppointments, 60000);
  onBeforeUnmount(() => {
    clearInterval(intervalId);
  });
});
</script>

<template>
  <div class="schedule-container" style="min-height: 100vh">
    <table>
      <thead>
        <tr>
          <th>序号</th>
          <th>科室</th>
          <th>医生</th>
          <th>患者</th>
          <!--        <th>性别</th>-->
          <th>就诊日期</th>
        </tr>
      </thead>
      <tbody>
        <!-- 遍历info.data -->
        <tr v-for="appointment in info.data" :key="appointment.xh" class="appointment-row">
          <td>{{ appointment.xh }}</td>
          <td>{{ appointment.ksmc }}</td>
          <td>{{ appointment.ysxm }}</td>
          <td>{{ appointment.name }}</td>
          <!--        <td>{{ appointment.sex }}</td>-->
          <td>{{ appointment.jzrq }} {{ appointment.sxw }}</td>
        </tr>
        <tr class="blank-row" v-for="n in 4" :key="n">
          <td colspan="5"></td> <!-- 调整空白行以匹配新列 -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.schedule-container {
  background: linear-gradient(to bottom, #804e22, #9f5f29);
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

th {
  color: white;
  font-size: 1.2em;
}

/* 单元格样式 */
td {
  color: white;
  font-size: 1em;
  padding: 15px;
  border: none;
  /* 去掉单元格边框 */
}

/* 整行样式 */
.appointment-row {
  background-color: #804e22;
  /* 行的背景颜色 */
  color: white;
  /* 字体颜色 */
  border-radius: 10px;
  /* 圆角 */
  margin-bottom: 10px;
  /* 行间距 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 阴影效果 */
}

@media (max-width:768px) {
  .schedule-container {
    padding: 30px 10px;

  }

  .appointment-row {
    border-bottom: 1px solid #935620;
  }

  td {
    padding: 15px 5px;
  }
}

.appointment-row:last-child {
  border: none !important;
}

/* 空行样式 */
.blank-row td {
  background-color: transparent;
  /* 让空行不显示背景 */
  height: 20px;
  /* 高度可以根据需要调整 */
}
</style>