<template>
    <div class="about-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="about-content">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            About Our Hospital
                        </span>
                        <h3>Our Clients are Our Priority, We Offer Quality Dental Services <span>of Specialists</span></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <ul class="about-list">
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Complete Crown
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Dental Implants
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Dental X-Ray
                                    </li>
                                </ul>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <ul class="about-list">
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Cosmetic Filling
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Cosmetic Filling
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Root Canal
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="about-info">
                                    <i class="flaticon-caduceus"></i>
                                    <h4>25 Years</h4>
                                    <span>Dental Experienced</span>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="about-info">
                                    <i class="flaticon-chair"></i>
                                    <h4>6405+</h4>
                                    <span>Dental Services</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="about-image">
                        <img src="../../assets/images/about/about-1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutHospital'
}
</script>