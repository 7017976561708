<template>
  <div style="margin-bottom: 50px">



<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-5 col-md-6">-->
<!--          <div class="dentist-details-image">-->

<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-lg-7 col-md-6">-->
<!--          <div class="">-->
<!--            <p class="alignText" style="white-space: pre-line" v-html="decodeHtmlEntities(text)"></p>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->

    <div class="about-area">
      <div class="container content">


        <div class="row align-items-center">
          <div>
            <div class="about-content">

              <div class="alignText">
                <p v-html="decodeHtmlEntities(text)"></p>
              </div>

            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <img :src="cover" alt="image">
        </div>

      </div>
    </div>


  </div>

</template>

<script>


import axios from "axios";
import api from "@/request/api";

export default {
  name: 'AboutOurHospital',
  data() {
    return {
      name: '',
      text: '',
      cover: ''
    }
  },
  methods: {
    init() {
      api.getRHYYInfo()
          .then(res => {
            console.log(res);
            this.name = res.data[5].name
            this.cover = res.data[5].contents[0].cover
            this.text = res.data[5].contents[0].attributes.text.replace(/\/n/g, '<br/>');
          })
          .catch(err => {
            console.log("就医指南 :: init :: getRHYYInfo() :: error: ", err);
          })

    },
    decodeHtmlEntities(text) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = text;
      return textarea.value;
    }
  },
  created() {
    this.init();
  },
}
</script>

<style scoped>
/*================================================
小程序两端文字对齐 CSS
=================================================*/
.alignText {
  text-align: justify;
}

.alignText::after {
  width: 100%;
  display: inline-block;
  /*content: '';*/
}
>>> span::before{
  content: ''; /* 创建一个伪元素 */
  display: inline-block; /* 确保其行为像块元素 */
  width: 2em; /* 设置空格宽度 */
}

.dentist-details-image {
  display: flex;
  justify-content: center;
}
/*>>> span{
  display: inline-block;
  text-indent: 2em;
  font-size: 1rem;
}*/
>>> p{
  margin-bottom: 0rem;
}

/*>>> .content img {
  width: 92% !important;
}
@media (max-width:768px) {
  >>> .content img {
    width: 100% !important;
  }
}*/

.content{
  max-width: 56.5rem;
}
@media (max-width: 992px) {
  .content{
    max-width: 45rem;
  }
}
>>> img{
  margin: 0.4rem 0;
  border-radius: 0.5rem;
}


</style>