<script>
import AddPatient from "@/components/patient/AddPatient.vue";
import PageBanner from "@/components/Common/PageBanner.vue";

export default {
  name: "AddPatientPage",
  components: {AddPatient, PageBanner},
  data:() => {
    return {
      type: '',
      openid: '',
      patientId: '',
      secondPageUrl: {
        url: '/dentist',
        type: '',
        query: {page: 1},
        openid:''
      },
    }
  },
  created() {
    this.type = this.$route.query?.type
    this.openid = this.$route.query?.openid
    this.patientId = this.$route.query?.patientId
    const route = this.$route;
    if (route.path.startsWith('/app')) {
      this.secondPageUrl.url = '/app/dentist';
      // this.secondPageUrl.url = this.type;
      this.secondPageUrl.query = {page: 1,'type':this.type, 'openid':this.openid};
    } else {
      this.secondPageUrl.url = '/dentist';
    }
  },
}
</script>

<template>
  <div>
    <PageBanner pageTitle="添加就诊人" secondPageTitle="专家介绍" :secondPageUrl="secondPageUrl"  :type="type"/>
    <AddPatient :openid="openid" :patientId="patientId"/>
  </div>
</template>

<style scoped>

</style>