<template>
    <div>
        <PageBanner pageTitle="Contact Us" :type="type"/>
        <ContactUs :type="type"/>
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import ContactUs from '../contact-us/ContactUs'

export default {
    components: {
        PageBanner,
        ContactUs,
    },data: () => {
        return {
            type: ''
        }
    },
    created() {
        this.type = this.$route.query?.type
    }
}
</script>
