<template>
  <div class="doctor-area overview-area d-flex justify-content-center" style="padding-bottom: 50px">
    <div class="container max-width-cust">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="section-title-warp">
              <span class="sub-title">
                <i class="flaticon-hashtag-symbol"></i>
                特色科室
              </span>

          </div>
          <div style="font-size: 1.4rem; margin-top: 10px; ;margin-bottom: 1rem">
            院内的特色科室
          </div>
        </div>

        <div class="col-lg-5">
          <div class="section-warp-btn">
            <router-link to="/rooms" class="default-btn">
              查看更多
            </router-link>
          </div>
        </div>
      </div>


      <div class="row">

        <div class="col-lg-3 col-md-6 d-flex" v-for="(roomInfo, index) in RoomInfos" :key="roomInfo.title">

          <div class="single-core-features center-class">
            <router-link :to="{ path: '/room-details', query: { index }}">

              <div class="d-flex justify-content-center">
                <div class="image-container" style="flex-shrink: 0">
                  <img alt="avatar" :src="roomInfo.cover" class="img-fluid">
                </div>
              </div>
              <div class="room-name" style="font-size: 1rem; padding-top: 1rem;text-align: center;">
                {{ roomInfo.title}}
              </div>
              <div class="room-name" style="font-size: 0.8rem;text-align: center;">
                {{ roomInfo.desc}}
              </div>

            </router-link>

          </div>

        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {getDoctorInfo, getJSDoctorInfo} from "@/api/doctor/doctorApi";

defineProps({
  name: {
    type: String,
    default: 'RoomsHome'
  }
});

let wholeRooms = [];
const RoomInfos = ref([]);

onMounted(() => {
  getJSDoctorInfo()
      .then((data) => {
        // 这里处理成功返回的数据
        wholeRooms = data.data[1].contents;
        RoomInfos.value = wholeRooms.slice(0, 8);
        // RoomInfos.value = wholeRooms.slice(0, pagination.value.pageSize);
      })
      .catch((error) => {
        // 这里处理错误
        console.error('Failed to get doctor info:', error);
      });

})


</script>
<style scoped>
.title-container {
  width: 100%;
  text-align: left;
  //margin: 20px 0;
  margin-bottom: 20px;
  position: relative;
}

.title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
}

.underline {
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #804e22;
  z-index: -1;
}

.image-container {
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.image-container-little {
  width: 50px; /* 固定宽度 */
  height: 50px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.image-container-little img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.center-class {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.room-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.max-width-cust{
  max-width: 57rem;
}

@media (max-width: 992px) {
  .max-width-cust{
    max-width: 45rem;
  }
}
</style>