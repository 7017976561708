<template>
    <div>
        <PageBanner pageTitle="Register" :type="type"/>
        <Register :type="type"/>
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import Register from '../Register/Register'

export default {
    components: {
        PageBanner,
        Register,
    },data: () => {
        return {
            type: ''
        }
    },
    created() {
        this.type = this.$route.query?.type
    }
}
</script>
