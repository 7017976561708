<template>
    <div class="register-area ptb-100">
        <div class="container">
            <div class="register-form">
                <h2>Register</h2>

                <form>
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" placeholder="Name">
                    </div>

                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control" placeholder="Email">
                    </div>

                    <div class="form-group">
                        <label>Phone</label>
                        <input type="text" class="form-control" placeholder="Phone">
                    </div>

                    <div class="form-group">
                        <label>Password</label>
                        <input type="text" class="form-control" placeholder="Password">
                    </div>

                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input type="text" class="form-control" placeholder="Confirm Password">
                    </div>

                    <p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p>

                    <button type="submit">Register Now</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Register'
}
</script>