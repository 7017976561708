<script setup>
import {computed, h, onMounted, reactive, ref} from "vue";
import Api from "@/request/api";
import {useUserStore} from "@/stores/useUserStore.ts";
import {ElMessageBox, ElNotification} from "element-plus";
import {useRoute} from "vue-router";
import wx from "weixin-js-sdk";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

const route = useRoute()
const openid = route.query.openid;
const appointmentList = reactive({data: []});

onMounted(() => {
  if (isApp.value) {
    //小程序
    // if (openid != null && openid !== '' && openid !== undefined) {
    if (useUserXcxStore().isLogin()) {
      Api.findAllAppointment(useUserXcxStore().getOpenid())
          .then((res) => {
            console.log("==--=-=---");
            console.log(res);
            appointmentList.data = res.data || [];
          });
    } else {
      //跳转登录
      wx.miniProgram.redirectTo({
        url: '/pages/user/wxLogin',
        success: function () {
          console.log("111")
        },
        fail: function () {
          console.log("222")
        }
      })
    }
  } else {
    //pc
    if (useUserStore().isLogin()) {
      //已经登录 初始化数据
      init()
    } else {
      //跳转登录
      this.$EventBus.emit('goLogin', '');
    }

  }

});


const isApp = computed(() => {
  if (!route.path.startsWith('/app')) {
    return false;
  } else {
    return true;
  }
})

function cancel1() {
  if (isApp.value) {
    Api.findAllAppointment(useUserXcxStore().getOpenid())
        .then((res) => {
          console.log("==--=-=---");
          console.log(res);
          appointmentList.data = res.data || [];
        });
  } else {
    init()
  }
}

const init = () => {
  Api.findAllAppointment(useUserStore().getUserInfo().openId)
      .then((res) => {
        console.log("==--=-=---");
        console.log(res);
        appointmentList.data = res.data || [];
      });
}



const cancel = (openId, xh, payState, orderNo) => {
  console.log("openId:", openId)
  console.log("xh:", xh)

  if(payState == "支付成功"){
    ElMessageBox.confirm('是否确认进行退款并取消本次预约?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
        .then(() => {

          Api.cancelAppointmentRefunds(openId, xh, orderNo, "主动取消预约")
              .then((res) => {
                ElNotification({
                  title: '取消预约挂号',
                  message: h(
                      'i',
                      {style: 'color: black'},
                      res.data,
                  ),

                });

                const timers = ref(null)
                timers.value = setInterval(() => {
                  Api.findOrderStatus(orderNo)
                      .then(res => {
                        console.log("查询退款 =>", res)
                        if(res.data == "已退款"){
                          clearInterval(timers.value)
                          timers.value = null
                          ElNotification({
                            title: '预约挂号',
                            message: h(
                                'i',
                                { style: 'color: black' },
                                res.data,
                            ),
                          });
                          cancel1();
                        }

                      })
                },2000)
                //这里需要加一些逻辑 一直查询退款订单状态 查询到退款成功就更新整个数据


              })
        })
  } else {
    ElMessageBox.confirm('是否确认取消本次预约?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
        .then(() => {
          Api.cancelAppointment(openId, xh)
              .then((res) => {
                ElNotification({
                  title: '取消预约挂号',
                  message: h(
                      'i',
                      {style: 'color: black'},
                      res.data,
                  ),
                });
                cancel1();
              })
        })
  }


}
</script>

<template>
  <div class="container d-flex justify-content-center max-width-class " style="min-height: 40rem">
    <div class="row justify-content-between w-100">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 gap-1"
           v-for="(appointment, index) in appointmentList.data"
           :key="index">
        <div class="card single-doctor">
          <div class="row" style="margin-bottom: 4px">
            <div class="col-8">
              <span class="rectangle"></span>
              <span style="margin-left: 6px">预约挂号</span>
              <div class="name">{{ appointment.ysxm }}</div>
            </div>
            <div v-if="appointment.state === '已取消'"
                 class="col-4 d-flex align-items-center justify-content-center">
              <span class="cancelled">已取消</span>
            </div>
            <div v-else-if="appointment.state === '已预约'"
                 class="col-4 d-flex align-items-center justify-content-center">
              <button type="button" class="custom-button" @click="cancel(appointment.openId, appointment.xhafter, appointment.payState, appointment.orderNo)">取消预约
              </button>
            </div>
            <div v-else-if="appointment.state === '已完成'"
                 class="col-4 d-flex align-items-center justify-content-center">
              <span class="cancelled">已完成</span>
            </div>
            <div v-else-if="appointment.state === '已退款'"
                 class="col-4 d-flex align-items-center justify-content-center">
              <span class="cancelled">已退款</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="details">
            <div class="detail-item">
              <span class="key">就诊人</span>
              <span class="value">{{ appointment.name }}</span>
            </div>
            <div class="detail-item">
              <span class="key">序号</span>
              <span class="value">{{ appointment.xhafter }}</span>
            </div>
            <div class="detail-item">
              <span class="key">就诊日期</span>
              <span class="value">{{ appointment.jzrq }} {{ appointment.sxw }}</span>
            </div>
            <div class="detail-item">
              <span class="key">就诊院区</span>
              <span class="value">{{ appointment.jzyq }}</span>
            </div>
            <div class="detail-item">
              <span class="key">就诊科室</span>
              <span class="value">{{ appointment.ksmc }}</span>
            </div>
            <div class="detail-item">
              <span class="key">就诊地址</span>
              <span class="value">{{ appointment.jzdz }}</span>
            </div>
            <div v-if="appointment.payerTotal" class="detail-item">
              <span class="key">预约费</span>
              <span class="value" style="color: red">
                <span class="price">￥{{ appointment.yyf }}（线上支付）</span>
              </span>
            </div>
            <div class="detail-item">
              <span class="key">挂号费</span>
              <span class="value" style="color: red">
                <span class="price">￥{{ appointment.ghf }}（到院支付）</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 8px;
}

.cancelled {
  color: gray;
  font-weight: bold;
}

.name {
  margin: 3px 0;
  font-size: 1.2em;
  font-weight: bold;
}

.details {
  margin: 8px 0;
}

.detail-item {
  display: flex;
  justify-content: left;
  padding: 4px 0;
}

.key {
  color: #949393;
  min-width: 90px;
  font-size: 1rem;
}

.value {
  color: #333;
  text-align: left;
  font-size: 1rem;
}

.line {
  height: 1px;
  background-color: #f1f0f0;
  width: 100%;
  margin: 2px 0;
}

.rectangle {
  display: inline-block;
  width: 3px;
  height: 15px;
  background-color: #804e22;
  transform: translateY(2px);
}

.max-width-class {
  max-width: 58rem;
  width: 100%;
}

@media (max-width: 992px) {
  .max-width-class {
    max-width: 46.5rem;
  }
}

@media (max-width: 768px) {
  .max-width-class {
    max-width: 36rem;
  }
}

.custom-button {
  font-size: 13px;
  padding: 5px 15px;
  border-radius: 20px;
  border: none;
  background-color: #804e22;
  color: #fff;
  text-align: center;
}


</style>