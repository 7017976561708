<template>
    <div class="doctor-area pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="section-title-warp">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            Our Dentist
                        </span>
                        <h2>Our Specialized and Experienced Dentist</h2>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="section-warp-btn">
                        <router-link to="/dentist" class="default-btn">View All</router-link>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-doctor">
                        <router-link to="/dentist-details">
                            <img src="../../assets/images/doctor/doctor-1.jpg" alt="image">
                        </router-link>

                        <div class="doctor-content">
                            <h3>
                                <router-link to="/dentist-details">
                                    Dr. Johnson Melbourne
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="share-link">
                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                <a href="https://twitter.com/?lang=en" target="_blank"><i class='bx bxl-twitter'></i></a>
                                <a href="https://www.linkedin.com/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                <a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-doctor">
                        <router-link to="/dentist-details">
                            <img src="../../assets/images/doctor/doctor-2.jpg" alt="image">
                        </router-link>

                        <div class="doctor-content">
                            <h3>
                                <router-link to="/dentist-details">
                                    Dr. Ena Dicrosa
                                </router-link>
                            </h3>
                            <span>Aesthetic Dentistry</span>

                            <div class="share-link">
                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                <a href="https://twitter.com/?lang=en" target="_blank"><i class='bx bxl-twitter'></i></a>
                                <a href="https://www.linkedin.com/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                <a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-doctor">
                        <router-link to="/dentist-details">
                            <img src="../../assets/images/doctor/doctor-3.jpg" alt="image">
                        </router-link>

                        <div class="doctor-content">
                            <h3>
                                <router-link to="/dentist-details">
                                    Dr. Addison Smith
                                </router-link>
                            </h3>
                            <span>Gastroenterologists</span>

                            <div class="share-link">
                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                <a href="https://twitter.com/?lang=en" target="_blank"><i class='bx bxl-twitter'></i></a>
                                <a href="https://www.linkedin.com/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                <a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurDentist'
}
</script>