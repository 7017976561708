<script setup>
import {useRoute} from "vue-router";
import router from "@/router";
import {computed, h, onMounted, ref} from "vue";
import {useUserStore} from "@/stores/useUserStore.ts";
import Api from "@/request/api";
import {useAppointmentStore} from "@/stores/useAppointmentStore.ts";
import {ElNotification} from "element-plus";
import wx from "weixin-js-sdk";
import EventBus from "@/utils/event-bus";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";
import api from "@/request/api";

const route = useRoute();
let type = ref('')
let isCenter = ref(false)
type = route.query.type;
isCenter = route.query.isCenter;
console.log("type", type)
console.log("isCenter", isCenter)
const patients = ref([])
let selectedPatient = ref({})
const openId = route.query.auth;
console.log("openId", openId)
const openid = route.query.openid;

onMounted(() => {
  if (isApp.value) {
    console.log("测试跳转 现在是小程序")
    //小程序
    // if(openid != null && openid !== '' && openid !== undefined){
    if (useUserXcxStore().isLogin()) {
      console.log("测试跳转 现在是小程序 已经登录")
      console.log(useUserXcxStore().getOpenid())

      api.isLogin(useUserXcxStore().getOpenid())
          .then(res => {
            console.log("是否登录", res)

            if (res == true){
              Api.findAllPatientInfo(useUserXcxStore().getOpenid())
                  .then(res => {
                    console.log("查询患者列表 =>", res)
                    patients.value = res.data
                  })
            } else {
              console.log("测试跳转 现在是小程序 还没登录 跳转登录")
              //跳转登录
              wx.miniProgram.redirectTo({
                url: '/pages/user/wxLogin',
                success: function () {
                  console.log("111")
                },
                fail: function () {
                  console.log("222")
                }
              })
            }

          })

    }

  } else {
    //pc
    console.log("现在是pc")
    if (useUserStore().isLogin()) {
      //已经登录 直接取数据
      console.log("现在是pc 已经登录")
      init();
    } else {
      //还没登录
      if (openId != null && openId !== '' && openId !== undefined) {
        //后端跳转过来的 获取用户信息
        Api.getUserInfo(openId)
            .then(res => {
              console.log("userInfo", res)
              console.log("userInfo", res.data)
              useUserStore().setUserInfo(res.data)

              Api.findAllPatientInfo(res.data.openId)
                  .then(res => {
                    console.log(res)
                    patients.value = res.data
                  })
            })
      } else {
        //跳转登录
        console.log("现在是pc 还没登录")
        EventBus.emit("goLogin", '')
      }

    }
  }


})


function init() {
  if (useUserStore().getUserInfo()) {
    Api.findAllPatientInfo(useUserStore().getUserInfo().openId)
        .then(res => {
          console.log(res)
          patients.value = res.data
        })
  }
}

const isApp = computed(() => {
  if (!route.path.startsWith('/app')) {
    return false;
  } else {
    return true;
  }
})


function submitSelection() {
  if (selectedPatient.value.openId) {
    // useAppointmentStore().setOpenId(selectedPatient.value.openId)
    // useAppointmentStore().setDocumentNumber(selectedPatient.value.documentNumber)
    useAppointmentStore().setPaId(selectedPatient.value._id)
    if (!route.path.startsWith('/app')) {
      router.push({path: '/yuyue-doctor'})
    } else {
      router.push({path: '/app/yuyue-doctor', query: {type: 'xcx', openid: openid}})
    }

  } else {
    ElNotification({
      title: '选择就诊人',
      message: h(
          'i',
          {style: 'color: black'},
          '请先选择就诊人！',
      ),
    });
  }
}

function toAddPatient() {
  if (!route.path.startsWith('/app')) {
    router.push({path: '/add-patient'})
  } else {
    router.push({path: '/app/add-patient', query: {type: 'xcx', openid: openid}})
  }
}

function toUpdatePatient(patientId){
  console.log("patientId select", patientId)
  if (!route.path.startsWith('/app')) {
    router.push({path: '/add-patient', query:{patientId: patientId}})
  } else {
    router.push({path: '/app/add-patient', query: {type: 'xcx', openid: openid, patientId: patientId}})
  }
}
</script>

<template>
  <div style="display: flex; justify-content: center">
    <div style="width: 55rem;padding: 0 15px;min-height: 40rem">
      <div class="position-class" style="padding: 0 15px">

        <div class="addCard">
          <div class="card-content" @click="toAddPatient()">
            <svg t="1722825714648" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="4212" width="1em" height="1em">
              <path
                  d="M512 958.016611c-119.648434 0-232.1288-46.367961-316.736783-130.559656-84.640667-84.255342-131.263217-196.255772-131.263217-315.455235 0-119.168499 46.624271-231.199892 131.232254-315.424271 84.607983-84.191695 197.088348-130.559656 316.736783-130.559656s232.1288 46.367961 316.704099 130.559656c84.67163 84.224378 131.263217 196.255772 131.263217 315.391587 0.032684 119.199462-46.591587 231.232576-131.263217 315.455235C744.1288 911.615966 631.648434 958.016611 512 958.016611zM512 129.983389c-102.623626 0-199.071738 39.743475-271.583282 111.936783-72.480581 72.12794-112.416718 168.063432-112.416718 270.079828s39.903454 197.951888 112.384034 270.047144c72.511544 72.191587 168.959656 111.936783 271.583282 111.936783 102.592662 0 199.071738-39.743475 271.583282-111.936783 72.480581-72.160624 112.416718-168.063432 112.384034-270.079828 0-102.016396-39.903454-197.919204-112.384034-270.016181C711.071738 169.759548 614.592662 129.983389 512 129.983389z"
                  fill="#575B66" p-id="4213"></path>
              <path
                  d="M736.00086 480.00086 544.00086 480.00086 544.00086 288.00086c0-17.664722-14.336138-32.00086-32.00086-32.00086s-32.00086 14.336138-32.00086 32.00086l0 192L288.00086 480.00086c-17.664722 0-32.00086 14.336138-32.00086 32.00086s14.336138 32.00086 32.00086 32.00086l192 0 0 192c0 17.695686 14.336138 32.00086 32.00086 32.00086s32.00086-14.303454 32.00086-32.00086L544.00258 544.00086l192 0c17.695686 0 32.00086-14.336138 32.00086-32.00086S753.696546 480.00086 736.00086 480.00086z"
                  fill="#575B66" p-id="4214"></path>
            </svg>
            添加就诊人
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="userInfoCart" style="margin-top: 20px" v-for="(patient, index) in patients"
                 :key="patient.openId">
              <div class="row">
                <div class="col-10">
                  <div>
                    <!--                    屈宗千-->
                    {{ patient.name }}
                  </div>
                  <div class="phone">
                    手机号：{{ patient.phone }}
                  </div>
                </div>

                <div v-if="isCenter != 'true'" class="col d-flex align-items-center justify-content-center">
                  <div class="col">
                    <div class="select">
                      <input
                      class="form-check-input" type="radio"
                          :name="'exampleRadios'"
                          :id="'exampleRadios' + index"
                          :value="patient"
                          v-model="selectedPatient">
                    </div>
                  </div>
                </div>

                <div v-else class="col d-flex align-items-center justify-content-end" @click="toUpdatePatient(patient._id)">
                  <svg style="display: inline-block;margin-top: -1px" class="bi bi-chevron-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </div>


              </div>
            </div>
          </div>

          <div v-if="isCenter != 'true'" class="commit-button">
            <button type="button" class="custom-button" @click="submitSelection">选择就诊人</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<style scoped>

.position-class {
  width: 90%;
  margin: 20px auto;
}

.addCard {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  border: none;
}

.userInfoCart {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: start;
  border: none;
  margin-top: 10px;
}

.phone {
  font-size: 14px;
  color: #9f9f9f
}

.select {
  display: flex;
  justify-content: end;
  height: 100%;
  align-items: center;
}

>>> .form-check-input:checked {
  background-color: #804e22;
  border-color: #804e22;
}
>>> .form-check-input:focus{border-color: #805e42;outline:0;box-shadow:0 0 0 .25rem rgba(184, 156, 97, 0.25)}
.commit-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button {
  width: 60%;
  font-size: 16px;
  margin: 20px auto;
  color: #ffffff;
  background-color: #804e22;
  border-radius: 3px;
  border: none;
  height: 35px;
}

@media (min-width: 768px) {
  >>> .custom-button {
    width: 20% !important;
  }
}




</style>