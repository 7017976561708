<template>
    <div class="blog-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-1.jpg" alt="image">
                                    </router-link>

                                    <div class="tag">10 Jun</div>
                                    <div class="tag-two">
                                        <router-link to="/blog">Technology</router-link>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <h3>
                                        <router-link to="/blog-details">
                                            New Technology Make for Dental Operation
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                                    <router-link to="/blog-details" class="blog-btn">
                                        Read More
                                    </router-link>

                                    <ul class="entry-meta">
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            <span>By 
                                                <router-link to="/blog-right-sidebar">
                                                    Daisy Gabriela
                                                </router-link>
                                            </span>
                                        </li>
                                        <li>
                                            <i class='bx bxs-comment'></i>
                                            2 Comments
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-2.jpg" alt="image">
                                    </router-link>

                                    <div class="tag">11 Jun</div>
                                    <div class="tag-two">
                                        <router-link to="/blog">Dental</router-link>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <h3>
                                        <router-link to="/blog-details">
                                            Regular Dental care make Your Smile Brighter
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                                    <router-link to="/blog-details" class="blog-btn">
                                        Read More
                                    </router-link>
                                    
                                    <ul class="entry-meta">
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            <span>By 
                                                <router-link to="/blog-right-sidebar">
                                                    Babatunde Jon
                                                </router-link>
                                            </span>
                                        </li>
                                        <li>
                                            <i class='bx bxs-comment'></i>
                                            2 Comments
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-3.jpg" alt="image">
                                    </router-link>

                                    <div class="tag">12 Jun</div>
                                    <div class="tag-two">
                                        <router-link to="/blog">Hygiene</router-link>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <h3>
                                        <router-link to="/blog-details">
                                            Dental Hygiene for All Age to Make Smile
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                                    <router-link to="/blog-details" class="blog-btn">
                                        Read More
                                    </router-link>
                                    
                                    <ul class="entry-meta">
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            <span>By 
                                                <router-link to="/blog-right-sidebar">
                                                    Daisy Gabriela
                                                </router-link>
                                            </span>
                                        </li>
                                        <li>
                                            <i class='bx bxs-comment'></i>
                                            2 Comments
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-4.jpg" alt="image">
                                    </router-link>

                                    <div class="tag">10 Jun</div>
                                    <div class="tag-two">
                                        <router-link to="/blog">Technology</router-link>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <h3>
                                        <router-link to="/blog-details">
                                            10 Dental Technology That Really Help
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                                    <router-link to="/blog-details" class="blog-btn">
                                        Read More
                                    </router-link>

                                    <ul class="entry-meta">
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            <span>By 
                                                <router-link to="/blog-right-sidebar">
                                                    Ronnie Aaron
                                                </router-link>
                                            </span>
                                        </li>
                                        <li>
                                            <i class='bx bxs-comment'></i>
                                            2 Comments
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-5.jpg" alt="image">
                                    </router-link>

                                    <div class="tag">11 Jun</div>
                                    <div class="tag-two">
                                        <router-link to="/blog">Dental</router-link>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <h3>
                                        <router-link to="/blog-details">
                                            World Best Dentist Meetup in Workshop
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                                    <router-link to="/blog-details" class="blog-btn">
                                        Read More
                                    </router-link>
                                    
                                    <ul class="entry-meta">
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            <span>By 
                                                <router-link to="/blog-right-sidebar">
                                                    Daisy Gabriela
                                                </router-link>
                                            </span>
                                        </li>
                                        <li>
                                            <i class='bx bxs-comment'></i>
                                            2 Comments
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-6.jpg" alt="image">
                                    </router-link>

                                    <div class="tag">12 Jun</div>
                                    <div class="tag-two">
                                        <router-link to="/blog">Hygiene</router-link>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <h3>
                                        <router-link to="/blog-details">
                                            Hygiene for All Age to Make Smile Brighter
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod.</p>
                                    <router-link to="/blog-details" class="blog-btn">
                                        Read More
                                    </router-link>
                                    
                                    <ul class="entry-meta">
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            <span>By 
                                                <router-link to="/blog-right-sidebar">
                                                    Babatunde Jon
                                                </router-link>
                                            </span>
                                        </li>
                                        <li>
                                            <i class='bx bxs-comment'></i>
                                            2 Comments
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area">
                                <a href="/blog-right-sidebar" class="prev page-numbers">
                                    <i class='flaticon-left-arrow'></i>
                                </a>
                                <span class="page-numbers current" aria-current="page">1</span>
                                <a href="/blog-right-sidebar" class="page-numbers">2</a>
                                <a href="/blog-right-sidebar" class="page-numbers">3</a>
                                <a href="/blog-right-sidebar" class="page-numbers">4</a>
                                <a href="/blog-right-sidebar" class="page-numbers">5</a>
                                <a href="/blog-right-sidebar" class="next page-numbers">
                                    <i class='flaticon-right-arrow'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <BlogSidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlogSidebar from '../Common/BlogSidebar'

export default {
    name: 'BlogRightSidebar',
    components: {
        BlogSidebar
    }
}
</script>