<template>
    <div class="services-area pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="section-title-warp">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            Our Best Dental Services
                        </span>
                        <h2>Enjoy Specialized Care Through Precision, Artistry, and Experience</h2>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="section-warp-btn">
                        <router-link to="/services" class="default-btn">
                            View All
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-services-item">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-7.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-chair"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    General Dentistry
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>

                            <router-link to="/services-details" class="default-btn">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-item">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-8.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-dental-care"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Cosmetic Dentistry
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>

                            <router-link to="/services-details" class="default-btn">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-item">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-3.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-dental-implant"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Dental Implants
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>

                            <router-link to="/services-details" class="default-btn">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-item">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-4.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-tooth"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Orthodontics
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>

                            <router-link to="/services-details" class="default-btn">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-item">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-5.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-tooth-1"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Teeth Whitening
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>

                            <router-link to="/services-details" class="default-btn">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-item">
                        <div class="services-image">
                            <router-link to="/services-details">
                                <img src="../../assets/images/services/services-6.jpg" alt="image">
                            </router-link>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-tooth-2"></i>
                                </router-link>
                            </div>
                        </div>

                        <div class="services-content">
                            <h3>
                                <router-link to="/services-details">
                                    Teeth Cleaning
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>

                            <router-link to="/services-details" class="default-btn">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BestDentalServices'
}
</script>