<template>
  <div>
    <PageBanner pageTitle="专家详情" secondPageTitle="专家介绍" :openid="openid" :secondPageUrl="secondPageUrl" :type="type" />
    <DentistDetails @sendPage="sendPage" :openid="openid"/>
  </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import DentistDetails from '../Doctor/DoctorDetails.vue'


export default {
  components: {
    PageBanner,
    DentistDetails,
  },
  data() {
    return {
      secondPageUrl: {
        url: '/dentist',
        type: '',
        query: {page: 1},
        openid:''
      },
      type:'',
      openid:''
    }
  },
  methods: {
    sendPage(data) {
      this.secondPageUrl.query.page = data;
    }
  },
  created() {
    this.type = this.$route.query?.type
    this.openid = this.$route.query?.openid
    const route = this.$route;
    console.log("this.openid  ",this.openid )
    if (route.path.startsWith('/app')) {
      this.secondPageUrl.url = '/app/dentist';
      // this.secondPageUrl.url = this.type;
      this.secondPageUrl.query = {page: 1,'type':this.type, 'openid':this.openid};
    } else {
      this.secondPageUrl.url = '/dentist';
    }
  }
}
</script>
