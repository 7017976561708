<template>
  <div :class="['navbar-area', {'is-sticky': isSticky}]">
    <div class="main-navbar">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <div class="logo">
            <router-link to="/">
              <!--              <img src="../../assets/images/logo.png" alt="logo">-->
              <div>北京京师中医医院</div>
            </router-link>
          </div>

          <div
              class="navbar-toggler"
              @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ 'active': button_active_state }"
              v-on:click="button_active_state = !button_active_state"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: active }" is-nav>
            <ul class="navbar-nav">

              <li class="nav-item" style="cursor: pointer">
                <a @click.prevent="goHomeOrXCXHome('/')" class="nav-link">
                  首页
                </a>
              </li>

              <!--              <li class="nav-item">
                              <a href="#" class="nav-link">
                                About
                                <i class='bx bx-caret-down'></i>
                              </a>
                              <router-link to="/about-us" class="nav-link">
                                About Us
                              </router-link>
                            </li>-->

              <li class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/about-us')" class="nav-link">
                  医院简介
                </a>
              </li>

              <li class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/blog')" class="nav-link">
                  新闻动态
                </a>
              </li>

              <li class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/dentist')" class="nav-link">
                  专家介绍
                </a>
              </li>

              <li class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/rooms')" class="nav-link">
                  特色科室
                </a>
              </li>

              <li class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/medical-guidelines')" class="nav-link">
                  就医指南
                </a>
              </li>

              <li class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/to-hospital-nav')" class="nav-link">
                  来院导航
                </a>
              </li>

              <li v-if="isLoginXcx && isAppPath" class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/my-appointment')" class="nav-link">
                  我的预约
                </a>
              </li>
              <li v-if="isLoginXcx && isAppPath" class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/select-patient')" class="nav-link">
                  我的就诊人
                </a>
              </li>

              <li v-if="isLoginXcx && isAppPath" class="nav-item" style="cursor: pointer">
                <a @click.prevent="gogogo('/screen')" class="nav-link">
                  看板
                </a>
              </li>


              <!--              <li  class="nav-item" style="cursor: pointer">
                              <a @click.prevent="gogogo('/person-center')" class="nav-link">
                                个人中心
                              </a>
                            </li>-->

<!--              v-if="loginFlag"-->
              <li class="nav-item" v-if="isLogin && !isAppPath">
                <a href="#" class="nav-link">
                  个人中心
                  <i class='bx bx-caret-down'></i>
                </a>

                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="" @click.prevent="gogogo('/my-appointment')" class="nav-link">
                      我的预约
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="" @click.prevent="gogogoCenter('/select-patient')" class="nav-link">
                      我的就诊人
                    </router-link>
                  </li>

                  <li class="nav-item" style="cursor: pointer">
                    <a @click.prevent="gogogo('/screen')" class="nav-link">
                      看板
                    </a>
                  </li>

                </ul>
              </li>





            </ul>
          </div>

          <div class="others-options d-flex align-items-center">

            <div class="option-item">
              <div class="navbar-btn">

                <router-link v-if="!loginFlag && !this.showLoginButtom" style="margin-right: 10px" to="" @click.prevent="personCenter"
                             class="default-btn">
                  <div>
                    <span>
                      登
                    </span>
                    <span style="margin-left: 18px">
                      录
                    </span>
                  </div>
                </router-link>

                <router-link v-if="isAppPath" :to="{path: '/app/dentist',query:{type:'xcx', openid: this.toXcxDoctor}}" class="default-btn">
                  预约挂号
                </router-link>
                <router-link v-else to="/dentist" class="default-btn">
                  预约挂号
                </router-link>

              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div v-if="loginFlag"
       style="position: fixed;z-index: 9998;top: 0;width: 100%;height: 100%;background-color: #00000080;display: flex;transition: opacity .3s ease;flex-direction: column">
    <div>

    </div>
    <div id="login_container"
         style="display: none;display: block;min-width: 15rem;margin: auto auto 0 auto;padding: 10px;background-color: #fff;border-radius: 5px;box-shadow: 0 2px 8px #00000054;transition: all .3s ease;border: 1px solid #3E3A4A;"> </div>
    <button @click="closeLogin" type="button" class="btn btn-outline-secondary"
            style="display: block; margin: 10px auto auto; padding: 10px 20px; background: #f1f1f1; border: none; border-radius: 5px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
      返回
    </button>
  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import Api from "@/request/api";
import {v4 as uuidv4} from 'uuid';
import {useUserStore} from "@/stores/useUserStore.ts";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

export default {
  name: 'Navbar',
  data() {
    return {
      isSticky: false,
      isSearch: false,
      active: false,
      button_active_state: false,
      loginFlag: false
    }
  },
  created() {

  },
  mounted() {
    const that = this
    window.addEventListener('scroll', () => {
      let scrollPos = window.scrollY
      if (scrollPos >= 100) {
        that.isSticky = true
      } else {
        that.isSticky = false
      }
    })
    this.$EventBus.on("goLogin", this.handleLogin);
    this.$EventBus.on("hideLogin", this.handleHideLogin);
  },
  beforeUnmount() {
    this.$EventBus.off("goLogin", this.handleLogin);
    this.$EventBus.off("hideLogin", this.handleHideLogin);
  },
  methods: {
    handleHideLogin(){
      console.log("跳转没？？？")
      this.loginFlag = true;
    },
    handleLogin(){
      console.log("跳转没1111")
      this.personCenter();
    },
    closeLogin() {
      console.log("???")
      this.loginFlag = false
    },
    isSearchMethod(isSearch) {
      console.log(this.isSearch)
      return this.isSearch = !isSearch
    },
    gogogo(path) {
      const currentPath = this.$route.path;
      if (currentPath.startsWith('/app')) {
        // No redundant slashes
        this.$router.push({path: `/app${path.startsWith('/') ? path : '/' + path}`, query: {type: 'xcx', openid: this.toXcxDoctor}});
      } else {
        this.$router.push(path);
      }
    },
    gogogoCenter(path) {
      const currentPath = this.$route.path;
      if (currentPath.startsWith('/app')) {
        // No redundant slashes
        this.$router.push({
          path: `/app${path.startsWith('/') ? path : '/' + path}`,
          query: {type: 'xcx', isCenter: 'true'}
        });
      } else {
        this.$router.push({path: path, query: {isCenter: 'true'}});
      }
    },
    goHomeOrXCXHome(path) {
      const currentPath = this.$route.path;
      if (currentPath.startsWith('/app')) {
        // No redundant slashes
        this.navToXcx();
      } else {
        this.$router.push('/');
      }
    },
    navToXcx() {
      console.log("navToXcx=>type=", this.type)
      wx.miniProgram.redirectTo({
        url: '/pages/index/index?current=homePage',
        success: function () {
          console.log("111")
        },
        fail: function () {
          console.log("222")
        }
      })
    },
    personCenter() {
      if (this.isAppPath){
        wx.miniProgram.redirectTo({
          url: '/pages/user/wxLogin',
          success: function () {
            console.log("111")
          },
          fail: function () {
            console.log("222")
          }
        })
      } else{
        console.log("11111111")
        // appid uri
        //js uuid state
        let state = uuidv4();

        Api.getGoLoginHome()
            .then((res) => {
              console.log("GoLoginHome", res)
              const [uri, appid] = res.data.split(":");
              console.log(appid)
              console.log(uri)
              // eslint-disable-next-line no-undef
              var obj = new WxLogin({
                self_redirect: false,
                id: "login_container",
                appid: appid,
                scope: "snsapi_login",
                redirect_uri: uri,
                state: state,
                style: "",
                href: "https://api.zhycit.com/hooparts/weixin/qrcode.css"
              });
              this.loginFlag = true;
              document.getElementById('login_container').style.display = 'block';
            })
        this.loginFlag = true
      }
    }
  },
  computed: {
    isAppPath() {
      return this.$route.path.startsWith('/app');
    },
    isLogin(){
      return useUserStore().isLogin();
    },
    showLoginButtom(){
      if (this.isAppPath){
        if (this.isLoginXcx){
          return true;
        } else {
          return false
        }
      } else {
        if (this.isLogin){
          return true
        } else {
          return false;
        }
      }
    },
    isLoginXcx(){
      return useUserXcxStore().isLogin();
    },
    toXcxDoctor(){
      return useUserXcxStore().getOpenid()
    }
  },

}
</script>