<template>
  <div>
    <PageBanner pageTitle="专家介绍" :type="type"/>
    <Doctor :source="type" :openid="openid"/>
  </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import Doctor from '../Doctor/Doctor'
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

export default {
  components: {
    PageBanner,
    Doctor,
  },
  data: () => {
    return {
      type: '',
      openid:''
    }
  },
  created() {
    this.type = this.$route.query?.type
    this.openid = this.$route.query?.openid
    console.log("登录类型", this.type)
    console.log("如果是小程序 登录openid", this.openid)
    if (this.$route.path.startsWith("/app")&&this.$route.query&&this.$route.query.openid) {
      useUserXcxStore().setOpenid(this.$route.query?.openid)
    }
    console.log("小程序id", useUserXcxStore().getOpenid())
  }
}
</script>
