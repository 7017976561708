<template>
  <div>
    <PageBanner pageTitle="医院简介" :type="type"/>
    <AboutOurHospital :type="type"/>
    <!--        <FunFacts />-->
    <!--        <OurDentist class="pt-100" />-->
    <!--        <Pricing />-->
    <!--        <OurClients />-->
  </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import AboutOurHospital from '../about-us/AboutOurHospital'
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";
/*import FunFacts from '../about-us/FunFacts'
import OurDentist from '../Common/OurDentist'
import Pricing from '../Common/Pricing'
import OurClients from '../Common/OurClients'*/

export default {
  components: {
    PageBanner,
    AboutOurHospital,
    /*FunFacts,
    OurDentist,
    Pricing,
    OurClients,*/
  }, data: () => {
    return {
      type: '',
      openid:''
    }
  },
  created() {
    this.type = this.$route.query?.type
    this.openid = this.$route.query?.openid
    if(this.$route.path.startsWith("/app")){
      useUserXcxStore().setOpenid(this.openid)
    }
    
  }
}
</script>
