<template>
    <div>
        <PageBanner pageTitle="预约挂号" :type="type"/>
<!--        <Features />-->
      <Features class="bg-ffffff pt-100" :type="type" />
        <MakeAnAppointment :type="type"/>
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import Features from '../Appointment/Features'
import MakeAnAppointment from '../Common/MakeAnAppointment'

export default {
    components: {
        PageBanner,
        Features,
        MakeAnAppointment,
    },data: () => {
        return {
            type: ''
        }
    },
    created() {
        this.type = this.$route.query?.type
    }
}
</script>
