<template>
    <div>
        <MainBanner />
        <Features class="bg-ffffff pt-100" />
        <BestDentalServices />
        <MakeAnAppointment />
        <AboutOurHospital />
        <OurRecentWork />
        <OurDentist />
        <OurClients />
        <Blog />
    </div>
</template>

<script>
import MainBanner from '../home-three/MainBanner'
import Features from '../Common/Features'
import BestDentalServices from '../home-three/BestDentalServices'
import MakeAnAppointment from '../Common/MakeAnAppointment'
import AboutOurHospital from '../home-three/AboutOurHospital'
import OurRecentWork from '../home-two/OurRecentWork'
import OurDentist from '../Common/OurDentist'
import OurClients from '../Common/OurClients'
import Blog from '../Common/Blog'

export default {
    name: 'HomePageThree',
    components: {
        MainBanner,
        Features,
        BestDentalServices,
        MakeAnAppointment,
        AboutOurHospital,
        OurRecentWork,
        OurDentist,
        OurClients,
        Blog,
    }
}
</script>
