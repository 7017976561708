<template>
  <div class="blog-area" style="margin-bottom: 50px">
    <div class="container" v-if="isDesktop" style="width: 57rem">
      <!--      <div class="section-title">-->
      <!--        &lt;!&ndash;                <span class="sub-title">&ndash;&gt;-->
      <!--        &lt;!&ndash;                    <i class="flaticon-hashtag-symbol"></i>&ndash;&gt;-->
      <!--        &lt;!&ndash;                    新闻动态&ndash;&gt;-->
      <!--        &lt;!&ndash;                </span>&ndash;&gt;-->
      <!--&lt;!&ndash;        <div style="font-size: 2rem">&ndash;&gt;-->
      <!--&lt;!&ndash;          随时关注医院的最新动态&ndash;&gt;-->
      <!--&lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      </div>-->

      <div class="row">

        <div class="title-container">
          <div class="title">随时关注医院的最新动态</div>
          <div class="underline"></div>
        </div>

        <div class="col-lg-4 col-md-6" v-for="(info, index) in newsInfo" :key="info.title">

          <div class="single-blog">

            <router-link
                :to="{ path: detailUrl, query: { index: info.article_id,'type':type }}">

              <div class="blog-image">
                <img class="newsImage" :src="info.thumb_url" alt="image">
              </div>

              <div class="blog-content">
                <div class="title-class">
                  {{ info.title }}
                </div>
                <div class="desc-class">
                  {{ info.digest }}
                </div>

                <ul class="entry-meta">
                  <li>
                    <svg style="margin: -3px 3px 0 -25px" t="1724297023783" class="icon" viewBox="0 0 1024 1024"
                         version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10705" width="1.5rem" height="1.5rem">
                      <path
                          d="M768 213.333333h-42.666667V170.666667c0-25.6-17.066667-42.666667-42.666666-42.666667s-42.666667 17.066667-42.666667 42.666667v42.666666H384V170.666667c0-25.6-17.066667-42.666667-42.666667-42.666667s-42.666667 17.066667-42.666666 42.666667v42.666666H256C183.466667 213.333333 128 268.8 128 341.333333v426.666667c0 72.533333 55.466667 128 128 128h512c72.533333 0 128-55.466667 128-128V341.333333c0-72.533333-55.466667-128-128-128z m42.666667 554.666667c0 25.6-17.066667 42.666667-42.666667 42.666667H256c-25.6 0-42.666667-17.066667-42.666667-42.666667v-256h597.333334v256zM213.333333 426.666667V341.333333c0-25.6 17.066667-42.666667 42.666667-42.666666h42.666667v42.666666c0 25.6 17.066667 42.666667 42.666666 42.666667s42.666667-17.066667 42.666667-42.666667V298.666667h256v42.666666c0 25.6 17.066667 42.666667 42.666667 42.666667s42.666667-17.066667 42.666666-42.666667V298.666667h42.666667c25.6 0 42.666667 17.066667 42.666667 42.666666v85.333334H213.333333z"
                          fill="#804e22" p-id="10706"></path>
                      <path
                          d="M341.333333 682.666667h341.333334c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666667H341.333333c-25.6 0-42.666667 17.066667-42.666666 42.666667s17.066667 42.666667 42.666666 42.666667z"
                          fill="#804e22" p-id="10707"></path>
                    </svg>
                    <span style="font-size: 0.8rem">{{ info.update_time }}</span>
                  </li>
                </ul>
              </div>

            </router-link>


          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="pagination-area">
            <a class="prev page-numbers" v-if="pagination.pageNum !== 1" @click="handlePageNum(-1, true)">
              <i class='flaticon-left-arrow'></i>
            </a>
            <span v-for="curPage in pagination.pages">
                        <span class="page-numbers current" aria-current="page"
                              v-if="pagination.pageNum === curPage">{{ curPage }}</span>
                        <a class="page-numbers" v-if="pagination.pageNum !== curPage"
                           @click="handlePageNum(curPage, false)">{{ curPage }}</a>
                      </span>
            <a class="next page-numbers" v-if="pagination.pageNum !== pagination.pages" @click="handlePageNum(1, true)">
              <i class='flaticon-right-arrow'></i>
            </a>
          </div>
        </div>
      </div>



    </div>


    <div class="container" v-else>
      <div class="row">
        <div class="">
          <router-link :to="{ path: detailUrl, query: { index: info.article_id,'type':type }}"
                       v-for="(info, index) in newsInfo" :key="info.title"
                       aria-current="true" style="display: block;">
            <div class="border-bottom d-flex gap-3 py-3">
              <div class="d-flex align-items-center">
                <div class="image-container" style="flex-shrink: 0">
                  <img :src="info.thumb_url" alt="avatar" class="img-fluid">
                </div>
              </div>
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <div class="mb-0 title-class" style="font-size: 1.1rem">{{ info.title }}</div>
                  <div class="mb-0 desc-class" style="font-size: 0.9rem">{{ info.digest }}</div>
                </div>
<!--                <small class="opacity-50 text-nowrap pt-1">{{info.update_t}}</small>-->
              </div>
            </div>

          </router-link>

          <div v-if="pagination.pageNum < pagination.pages" style="text-align: center;font-weight: 400" class="mt-4"
               @click="loadOther">
            点击查看更多
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, computed, onBeforeUnmount} from "vue";
import {useRoute} from "vue-router";
import Api from "@/request/api";

defineProps({
  name: {
    type: String,
    default: 'Blog'
  },
  type: {
    type: String,
    default: ''
  }
});


const loadOther = () => {
  console.log("111111")
  console.log(pagination.value.pageNum)
  console.log(pagination.value.pages)
  if (pagination.value.pageNum < pagination.value.pages) {
    Api.findNewsList(pagination.value.pageSize, ++pagination.value.pageNum)
        .then(res => {
          console.log("新闻接口返回", res);
          /*newsInfo.value = res.data.data*/
          newsInfo.value.push(...res.data)
        })
  }
}


const route = useRoute()
const detailUrl = computed(() => {
  if (!route.path.startsWith('/app')) {
    return "/blog-details";
  } else {
    return "/app/blog-details";
  }
})
const windowWidth = ref(window.innerWidth);
const handleResize = () => {
  windowWidth.value = window.innerWidth; // 更新窗口宽度
  if (isDesktop.value) {
    handlePageNum(pagination.value.pageNum, false)
  } else {
    newsInfo.value = wholeNews;
  }
};
const isDesktop = computed(() => windowWidth.value >= 768);

const pagination = ref({
  pageNum: 1,
  pageSize: 3,
  total: 0,
  pages: 1,
})
let wholeNews = [];
const newsInfo = ref([]);
const curPageNum = route.query.page;

onMounted(() => {


  Api.findNewsList(pagination.value.pageSize, pagination.value.pageNum)
      .then(res => {
        console.log("新闻接口返回", res);
        newsInfo.value = res.data.map(item => {
          const date = new Date(item.update_time * 1000);
          const formattedDate = date.toISOString().split('T')[0];
          return {
            ...item,
            update_time: formattedDate // 更新为格式化后的日期
          };
        });
        pagination.value.total = parseInt(res.msg, 10);
        if (pagination.value.total % pagination.value.pageSize === 0) {
          pagination.value.pages = Math.floor(pagination.value.total / pagination.value.pageSize)
        } else {
          pagination.value.pages = Math.floor(pagination.value.total / pagination.value.pageSize) + 1
        }
      })


  window.addEventListener('resize', handleResize); // 添加事件监听器
  /*getDoctorInfo()
      .then((data) => {
        // 这里处理成功返回的数据
        wholeNews = data.data[4].contents;
        if (isDesktop.value) {
          newsInfo.value = wholeNews.slice(0, pagination.value.pageSize);
          pagination.value.total = data.data[4].contents.length;
          console.log(data.data[4].contents.length)
          console.log(pagination.value.pageSize)
          console.log(data.data[4].contents.length % pagination.value.pageSize)
          if(data.data[4].contents.length % pagination.value.pageSize==0){
            pagination.value.pages = Math.floor(data.data[4].contents.length / pagination.value.pageSize);
          }else{
            pagination.value.pages = Math.floor(data.data[4].contents.length / pagination.value.pageSize) + 1;
          }

          // pagination.value.pages = Math.floor(data.data[4].contents.length / pagination.value.pageSize) + 1;
          if (curPageNum) {
            handlePageNum(curPageNum, false);
          }
        } else {
          newsInfo.value = wholeNews;
        }
      })
      .catch((error) => {
        // 这里处理错误
        console.error('Failed to get doctor info:', error);
      });*/

})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize); // 移除事件监听器
});

function handlePageNum(pageNum, isArrow) {
  if (isArrow) {
    if (pageNum === 1) {
      pagination.value.pageNum++;
    } else if (pageNum === -1) {
      pagination.value.pageNum--;
    }
  } else {
    pagination.value.pageNum = parseInt(pageNum);
  }
  /*const pageSize = pagination.value.pageSize
  newsInfo.value = wholeNews.slice((pagination.value.pageNum - 1) * pageSize, pagination.value.pageNum * pageSize);*/

  Api.findNewsList(pagination.value.pageSize, pagination.value.pageNum)
      .then(res => {
        console.log("新闻接口返回", res);
        newsInfo.value = res.data.map(item => {
          const date = new Date(item.update_time * 1000);
          const formattedDate = date.toISOString().split('T')[0];
          return {
            ...item,
            update_time: formattedDate // 更新为格式化后的日期
          };
        });
        /*pagination.value.total = parseInt(res.data.msg, 10);
        console.log(parseInt(res.data.msg, 10))
        if (pagination.value.total % pagination.value.pageSize === 0){
          pagination.value.pages = Math.floor(pagination.value.total / pagination.value.pageSize)
        } else {
          pagination.value.pages = Math.floor(pagination.value.total / pagination.value.pageSize) + 1
        }*/
      })


  document.getElementById('scroll').scrollIntoView()
}
</script>

<style scoped>
.newsImage {
  width: 416px;
  height: 220px;
  object-fit: cover
}

.image-container {
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}

.title-class {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  //line-height: 1.8;
  //max-height: 3.6em;
}

.desc-class {
  color: #818181;
  font-size: 0.8rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  //line-height: 1.8;
  //max-height: 3.6em;
}

.title-container {
  width: 100%;
  text-align: left;
  //margin: 20px 0;
  margin-bottom: 20px;
  position: relative;
}

.title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
}

.underline {
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #804e22;
  z-index: -1;
}

.image-container {
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}


</style>