import axios from "axios";

export function getDoctorInfo(){
    // return axios.get('/rhyy/hospital/info.json')
    return axios.get('https://api.zhycit.com/jszyy/hospital/info.json')
}

export function getJSDoctorInfo(){
    // return axios.get('/rhyy/hospital/info.json')
    return axios.get('https://api.zhycit.com/jszyy/hospital/info.json')
}

//获取医生排班信息
export function getDoctorDetail(){
    // return axios.get('/rhzxyy/rhyy/doctorVisitTimes')
    return axios.get('http://localhost:59016/rhzxyy/rhyy/doctorVisitTimes')
}
