<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-content">
                        <span class="sub-title">
                            <i class="flaticon-hashtag-symbol"></i>
                            About Our Hospital
                        </span>
                        <h3>Enjoy Specialized Care Through <span>Precision Experience</span></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <ul class="about-list">
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Complete Crown
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Dental Implants
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Dental X-Ray
                                    </li>
                                </ul>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <ul class="about-list">
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Cosmetic Filling
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Cosmetic Filling
                                    </li>
                                    <li>
                                        <i class="flaticon-check"></i>
                                        Root Canal
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="about-info">
                                    <i class="flaticon-caduceus"></i>
                                    <h4>25 Years</h4>
                                    <span>Dental Experienced</span>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="about-info">
                                    <i class="flaticon-chair"></i>
                                    <h4>6405+</h4>
                                    <span>Dental Services</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="about-image">
                        <img src="../../assets/images/about/about-3.jpg" alt="image">

                        <div class="about-inner-box">
                            <div class="content">
                                <div class="icon">
                                    <i class="flaticon-doctor"></i>
                                </div>
                                <h3>
                                    <router-link to="/dentist-details">
                                        Certified Dentist
                                    </router-link>
                                </h3>
                                <p>Proin gravida nibh velit auctor aliquet aenean.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOurHospital'
}
</script>