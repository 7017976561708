<template>
    <div class="gallery-area pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">
                    <i class="flaticon-hashtag-symbol"></i>
                    Our Recent Work
                </span>
                <h2>Our Cases & Patient Stories We Create  Beautiful Smiles</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-1.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Aesthetic Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-2.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Periodontists Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-gallery">
                        <img src="../../assets/images/gallery/gallery-3.jpg" alt="image">

                        <div class="content">
                            <h3>
                                <router-link to="/services-details">
                                    Endodontists Dentistry
                                </router-link>
                            </h3>
                            <span>Prosthodontics Dentist</span>

                            <div class="icon">
                                <router-link to="/services-details">
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurRecentWork'
}
</script>