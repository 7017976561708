<script>
import PageBanner from "@/components/Common/PageBanner.vue";
import DoctorAppointment from "@/components/Doctor/DoctorAppointment.vue";

export default {
  name: "DoctorAppointmentPage",
  components: {DoctorAppointment, PageBanner},
  data:() => {
    return {
      type: '',
      ysId: '',
      czb_index: '',
      openid: '',
      secondPageUrl: {
        url: '/dentist',
        type: '',
        query: {page: 1},
        openid:""
      },
    }
  },
  created() {
    this.initRouteQuery()
  },
  methods: {
    initRouteQuery(){
      this.ysId = this.$route.query.ysId
      this.czb_index = this.$route.query.czb_index
      this.type = this.$route.query?.type
      this.openid = this.$route.query?.openid
      const route = this.$route;
      if (route.path.startsWith('/app')) {
        this.secondPageUrl.url = '/app/dentist';
        // this.secondPageUrl.url = this.type;
        this.secondPageUrl.query = {page: 1,'type':this.type, 'openid':this.openid};
      } else {
        this.secondPageUrl.url = '/dentist';
      }
    }
  }
}
</script>

<template>
  <div>
    <PageBanner pageTitle="预约挂号" secondPageTitle="专家介绍" :secondPageUrl="secondPageUrl" :type="type"/>
    <DoctorAppointment :ysId="ysId" :czb_index="czb_index" :openid="openid"/>
  </div>
</template>

<style scoped>

</style>