<template>
  <div class="about-area">
    <div class="container">


      <div class="row align-items-center">
        <div >
          <div class="about-content">

            <div class="alignText">
              <p v-html="decodeHtmlEntities(text)" class="content"></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api from "@/request/api";

export default {
  name: 'AboutOurHospital',
  data() {
    return {
      name: '',
      text:'',
    }
  },
  methods: {
    init() {
      api.getRHYYInfo()
          .then(res => {
            console.log(res);
            if (res){
              this.name = res.data[2].name
              this.text = res.data[2].contents[0].attributes.text.replace(/\/n/g, '<br/>');
            }
          })
          .catch(err => {
            console.log("About :: init :: getRHYYInfo() :: error: ", err);
          })
    },
    decodeHtmlEntities(text) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = text;
      return textarea.value;
    }
  },
  created() {
    this.init();
  },
}
</script>

<style scoped>
/*================================================
小程序两端文字对齐 CSS
=================================================*/
.alignText{
  text-align: justify;
  justify-content: center;
  display: grid;
}
/*>>> span::before{
  content: ''; !* 创建一个伪元素 *!
  display: inline-block; !* 确保其行为像块元素 *!
  width: 2em; !* 设置空格宽度 *!
}*/
.alignText::after{
  width: 100%;
  display: inline-block;
  /*content: '';*/
}




/*
>>> .content img {
  width: 92% !important;
}

@media (max-width:768px) {
  >>> .content img {
    width: 100% !important;
  }
}
*/

.content{
  max-width: 55rem;
}


@media (max-width: 992px) {
  .content{
    max-width: 47rem;
  }
}


/*>>> span{
  display: inline-block;
  text-indent: 2em;
  font-size: 1rem;
}*/
>>> p{
  margin-bottom: 0rem;
}

>>> img{
  margin: 0.4rem 0;
  border-radius: 0.5rem;
}
.title-container {
  width: 100%;
  text-align: left;
  margin: 20px 0;
  position: relative;
}
.underline {
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #804e22;
  z-index: -1;
}




</style>