<template>
    <div class="dentist-details-area"  style="padding-bottom: 50px;">
        <div class="container">
          <div class="container-center">
            <div class="content">
              <div class="title-class">
                {{roomInfo.title}}
              </div>

              <div class="about-content">
                <div class="alignText" v-html="decodeHtmlEntities(text)"></div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import {getDoctorInfo, getJSDoctorInfo} from "@/api/doctor/doctorApi";

  defineProps({
    name: {
      type: String,
      default: 'RoomDetails'
    }
  });

  const route = useRoute();
  const index = route.query.index;
  let wholeExperts = [];
  const roomInfo = ref({});
  const text = ref();
  const page = ref(1);

  const emit = defineEmits(['sendPage']);

  const sendPage = () => {
    emit('sendPage', (Math.floor(index/9) + 1))
  }

  onMounted(() => {
    getJSDoctorInfo()
        .then((data) => {
          // 这里处理成功返回的数据
          wholeExperts = data.data[1].contents;
          roomInfo.value = wholeExperts[index];
          text.value = roomInfo.value.attributes.text.replace(/\/n/g, '<br/>');
          sendPage()
        })
        .catch((error) => {
          // 这里处理错误
          console.error('Failed to get doctor info:', error);
        });
  })

  function  decodeHtmlEntities(text) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }
</script>

<style scoped>
/*================================================
小程序两端文字对齐 CSS
=================================================*/
/*.alignText{
  text-align: justify;
}*/
/*.alignText::after{
  width: 100%;
  display: inline-block;
}*/
/*>>> p:empty {
  display: none; !* 隐藏空的 p 标签 *!
}*/
/*>>> span::before{
  content: ''; !* 创建一个伪元素 *!
  display: inline-block; !* 确保其行为像块元素 *!
  width: 2em; !* 设置空格宽度 *!
}*/
.container-center{
  display: flex;
  justify-content: center;
}
/*>>> .content img {
  max-width: 92% !important;
}*/

/*@media (min-width: 768px) {
  >>> .content img {
    max-width: 92% !important;
  }
}*/

.content{
  max-width: 55rem;
}
>>> p{
  margin-bottom: 0rem;
}
.title-class{
  font-size: 1.3rem;
  margin-bottom:0.3rem;
}
>>> img{
  margin: 0.4rem 0;
  border-radius: 0.5rem;
}

/*>>> p{
  text-align: justify !important;
}*/

</style>