<template>
  <div class="dentist-details-area">
    <div class="container">
      <div style="margin-bottom: 50px">
        <div class="about-area">
          <div class="container content">
            <div class="row align-items-center">
              <div>
                <div class="about-content">
                  <div class="alignText">
                    <p v-html="decodeHtmlEntities(text)"></p>
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="d-flex justify-content-center">
              <img
                  src="https://restapi.amap.com/v3/staticmap?location=116.523570,39.794353&zoom=13&size=400*280&markers=mid,,医:116.523570,39.794353&key=6ad2fd67dfd37889f830515ae7fbfd14&scale=2"
              >
            </div>-->
            <div class="d-flex justify-content-center">
              <div id="container" style="width: 100%; height: 400px;"></div> <!-- 设置固定高度 -->
            </div>
<!--
            <div>
              <a href="https://uri.amap.com/navigation?from=&to=116.523570,39.794353,北京京师中医医院&mode=bus&policy=0&src=&coordinate=&callnative=0">高德WEB</a>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api from "@/request/api";
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: 'AboutOurHospital',
  data() {
    return {
      name: '',
      text: '',
      infoWindow: null,
    }
  },
  methods: {
    init() {
      api.getRHYYInfo()
          .then(res => {
            if (res) {
              this.name = res.data[3].name;
              this.text = res.data[3].contents[0].attributes.text.replace(/\/n/g, '<br/>');
            }
          })
          .catch(err => {
            console.log("About :: init :: getRHYYInfo() :: error: ", err);
          });
    },
    decodeHtmlEntities(text) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = text;
      return textarea.value;
    },
    initAMap() {
      window._AMapSecurityConfig = {
        // securityJsCode: 'f06ce8a921ddcbd6dcbf038d0a33e0c1',
        securityJsCode: 'd77004577a7734965165f6929e4f960c',
      };



      AMapLoader.load({
        // key: "6ad2fd67dfd37889f830515ae7fbfd14", // 申请好的Web端开发者Key，首次调用 load 时必填
        key: "39f75a6380bd6ebff42983301e4bd3f9", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      })
          .then((AMap) => {

            // 创建一个 Icon
            var startIcon = new AMap.Icon({
              size: new AMap.Size(25, 34),
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
              imageSize: new AMap.Size(135, 40),
              imageOffset: new AMap.Pixel(-95, -3)
            });

            var marker = new AMap.Marker({
              position: [116.469334,39.932844],
              // 将 html 传给 content
              icon: startIcon,
              // 以 icon 的 [center bottom] 为原点
              offset: new AMap.Pixel(-13, -30)
            });


            this.map = new AMap.Map("container", {
              // 设置地图容器id
              viewMode: "3D", // 是否为3D地图模式
              zoom: 15, // 初始化地图级别
              center: [116.469334,39.932844], // 初始化地图中心点位置
            });

            // this.map.add(marker)

            // 创建信息窗体
            this.infoWindow = new AMap.InfoWindow({
              content: `<div style="padding:7px 0px 0px 0px;">北京京师中医医院</div>`,
            });

            // 打开信息窗体
            this.infoWindow.open(this.map, this.map.getCenter());

          })
          .catch((e) => {
            console.log(e);
          });

    },


  },
  created() {
    this.init();
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
}
</script>

<style scoped>
/*================================================
小程序两端文字对齐 CSS
=================================================*/
.alignText {
  text-align: justify;
}

.alignText::after {
  width: 100%;
  display: inline-block;
  /*content: '';*/
}
/*>>> span::before{
  content: ''; !* 创建一个伪元素 *!
  display: inline-block; !* 确保其行为像块元素 *!
  width: 2em; !* 设置空格宽度 *!
}*/
/*@media (min-width: 768px) {
  .content img {
    width: 92% !important;
  }
}*/
.dentist-details-image {
  display: flex;
  justify-content: center;
}
/*>>> span{
  display: inline-block;
  text-indent: 2em;
  font-size: 1rem;
}*/
>>> p{
  margin-bottom: 0rem;
}


.content{
  max-width: 56.5rem;
}
@media (max-width: 992px) {
  .content{
    max-width: 45rem;
  }
}
>>> img{
  margin: 0.4rem 0;
  border-radius: 0.5rem;
}

#container{
  padding:0px;
  margin: 0px;
  width: 100%;
  height: 800px;
}

.custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
}

.custom-content-marker .close-btn:hover{
  background: #666;
}

#mapFrame {
  width: 100%;
  height: 100vh; /* 让 iframe 充满视口 */
  border: none;   /* 移除边框 */
}
</style>