<template>
  <div class="doctor-area overview-area pt-5 pb-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="section-title-warp">
              <span class="sub-title">
                <i class="flaticon-hashtag-symbol"></i>
                特色科室
              </span>
            <div style="font-size: 1.4rem;margin-top: 0.5rem">
              院内的特色科室
            </div>
          </div>
        </div>

        <!--            <div class="col-lg-5">-->
        <!--              <div class="section-warp-btn">-->
        <!--                <router-link to="/rooms" class="default-btn">-->
        <!--                  查看更多-->
        <!--                </router-link>-->
        <!--              </div>-->
        <!--            </div>-->
      </div>

      <div class="row">
        <!--            <div class="col-lg-4 col-md-6 list-group mx-1">-->
        <!--              <router-link :to="{ path: '/room-details', query: { index }}" v-for="(roomInfo, index) in RoomInfos" :key="roomInfo.title" class="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">-->
        <!--                <div class="d-flex gap-2 w-100 justify-content-between">-->
        <!--                  <div>-->
        <!--                    <h6 class="mb-0">{{ roomInfo.title }}</h6>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </router-link>-->
        <!--            </div>-->


        <router-link :to="{ path: '/room-details', query: { index }}" v-for="(roomInfo, index) in RoomInfos"
                     :key="roomInfo.title" class=""
                     aria-current="true">
          <div class="border-bottom d-flex gap-3 py-3 align-items-center" style="width: 100%; padding-bottom: 1rem">
            <div class="d-flex align-items-center">
              <div class="image-container" style="flex-shrink: 0">
                <img alt="avatar" :src="roomInfo.cover" class="img-fluid">
              </div>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-between">
              <div class="d-flex flex-column">
                <div class="mb-0 title-class" style="font-size: 1rem">{{ roomInfo.title }}</div>
                <div class="mb-0 desc-class">{{ roomInfo.desc }}</div>
              </div>
            </div>
          </div>
        </router-link>

      </div>

      <router-link to="/rooms" style="width: 100%">
        <div style="text-align: center;font-weight: 400" class="mt-4">
          点击查看更多
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {getDoctorInfo, getJSDoctorInfo} from "@/api/doctor/doctorApi";

defineProps({
  name: {
    type: String,
    default: 'RoomsHome'
  }
});

let wholeRooms = [];
const RoomInfos = ref([]);

onMounted(() => {
  getJSDoctorInfo()
      .then((data) => {
        // 这里处理成功返回的数据
        wholeRooms = data.data[1].contents;
        RoomInfos.value = wholeRooms.slice(0, 8);
        // RoomInfos.value = wholeRooms.slice(0, pagination.value.pageSize);
      })
      .catch((error) => {
        // 这里处理错误
        console.error('Failed to get doctor info:', error);
      });

})


</script>
<style>
.desc-class {
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  //line-height: 1.8;
  //max-height: 3.6em;
}

.image-container {
  width: 50px; /* 固定宽度 */
  height: 50px; /* 固定高度 */
  overflow: hidden; /* 溢出隐藏，以确保只显示中间的部分 */
  border-radius: 50%; /* 使容器为圆形 */
  position: relative; /* 以便于定位内部的 img 标签 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.image-container img {
  width: 100%; /* 图片填满容器 */
  height: 100%; /* 图片填满容器 */
  object-fit: cover; /* 确保保持比例的同时填充整个容器 */
}
</style>