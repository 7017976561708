<template>
    <div>
        <ComingSoon :type="type"/>
    </div>
</template>

<script>
import ComingSoon from '../coming-soon/ComingSoon'

export default {
    components: {
        ComingSoon,
    },data: () => {
        return {
            type: ''
        }
    },
    created() {
        this.type = this.$route.query?.type
    }
}
</script>
