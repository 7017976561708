<template>
  <div class="middle-header-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="middle-header">
            <h1 class="d-flex align-items-center">
              <router-link to="/" style="font-size: 1.7rem; font-weight: normal">北京京师中医医院</router-link>
            </h1>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <ul class="middle-header-content">
            <li>
              <i class="flaticon-emergency-call"></i>
              联系方式
              <span><a href="`tel:${phone}`">{{ phone }}</a></span>
            </li>

            <li>
              <i class="flaticon-wall-clock"></i>
              开放时间
              <span>08:00 AM to 17:00 PM</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/request/api";

export default {
  name: 'MiddleHeader',
  data() {
    return {
      phone: ''
    }
  },
  methods: {
    init() {
      api.getRHYYInfo()
          .then(res => {
            // console.log(res);
            if (res) {
              this.phone = res.data[3].contents[0].attributes.phone
            }
          })
          .catch(err => {
            console.log("MiddleHeader :: init :: getRHYYInfo() :: error: ", err);
          })
    }
  },
  created() {
    this.init()
  }
}
</script>