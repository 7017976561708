<template>
    <div>
        <MainBanner />
        <AboutHospital />
        <OurServices />
        <OurFeatures />
        <OurRecentWork class="bg-091e3e pt-100" />
        <MakeAnAppointment />
        <OurDentist class="pt-100" />
        <OurClients />
        <Blog />
    </div>
</template>

<script>
import MainBanner from '../home-two/MainBanner'
import AboutHospital from '../home-two/AboutHospital'
import OurServices from '../home-two/OurServices'
import OurFeatures from '../home-two/OurFeatures'
import OurRecentWork from '../home-two/OurRecentWork'
import MakeAnAppointment from '../home-two/MakeAnAppointment'
import OurDentist from '../Common/OurDentist'
import OurClients from '../Common/OurClients'
import Blog from '../Common/Blog'

export default {
    name: 'HomePageTwo',
    components: {
        MainBanner,
        AboutHospital,
        OurServices,
        OurFeatures,
        OurRecentWork,
        MakeAnAppointment,
        OurDentist,
        OurClients,
        Blog,
    }
}
</script>
