<template>
    <div>
        <PageBanner pageTitle="Testimonials" />
        <Testimonials />
    </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import Testimonials from '../Testimonials/Testimonials'

export default {
    components: {
        PageBanner,
        Testimonials,
    }
}
</script>
