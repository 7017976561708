<template>
  <div class="top-header-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <ul class="top-header-information">
            <li>
              <i class='bx bxs-map'></i>
              {{address}}
            </li>

<!--            <li>-->
<!--              <i class='bx bx-envelope-open'></i>-->
<!--&lt;!&ndash;              <a href="mailto:support@jszyy.com">support@jszyy.com</a>&ndash;&gt;-->
<!--            </li>-->
          </ul>
        </div>

<!--                <div class="col-lg-6 col-md-12">-->
<!--                    <ul class="top-header-optional">-->
<!--                        <li>-->
<!--                            <a href="https://www.facebook.com/" target="_blank">-->
<!--                                <i class='bx bxl-facebook'></i>-->
<!--                            </a>-->
<!--                            <a href="https://twitter.com/?lang=en" target="_blank">-->
<!--                                <i class='bx bxl-twitter'></i>-->
<!--                            </a>-->
<!--                            <a href="https://www.linkedin.com/" target="_blank">-->
<!--                                <i class='bx bxl-linkedin'></i>-->
<!--                            </a>-->
<!--                            <a href="https://www.instagram.com/" target="_blank">-->
<!--                                <i class='bx bxl-instagram'></i>-->
<!--                            </a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/request/api";

export default {
  name: 'TopHeader',
  data() {
    return {
      address: ''
    }
  },
  methods: {
    init() {
      api.getRHYYInfo()
          .then(res => {
            // console.log(res);
            if (res) {
              this.address = res.data[3].contents[0].attributes.address
            }
          })
          .catch(err => {
            console.log("TopHeader :: init :: getRHYYInfo() :: error: ", err);
          })
    }
  },
  created() {
    this.init()
  }
}
</script>