<template>
    <div class="services-area bg-eef9ff pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">
                    <i class="flaticon-hashtag-symbol"></i>
                    Our Services
                </span>
                <h2>Enjoy Specialized Care Through Precision, Artistry, and Experience</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-chair"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">
                                General Dentistry
                            </router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details" class="services-btn">
                            Read More
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-dental-care"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">
                                Cosmetic Dentistry
                            </router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details" class="services-btn">
                            Read More
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-dental-implant"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">
                                Dental Implants
                            </router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details" class="services-btn">
                            Read More
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-tooth"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">
                                Orthodontics
                            </router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details" class="services-btn">
                            Read More
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-tooth-1"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">
                                Teeth Whitening
                            </router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details" class="services-btn">
                            Read More
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="flaticon-tooth-2"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">
                                Teeth Cleaning
                            </router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details" class="services-btn">
                            Read More
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurServices'
}
</script>