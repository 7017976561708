<template>
  <div>
    <PageBanner pageTitle="特色科室" :type="type"/>
    <Rooms :source="type"/>
  </div>
</template>

<script>
import PageBanner from '../Common/PageBanner'
import Rooms from "@/components/Room/Rooms.vue";
import {useUserXcxStore} from "@/stores/useUserXcxStore.ts";

export default {
  components: {
    Rooms,
    PageBanner,
  },
  data: () => {
    return {
      type: ''
    }
  },
  created() {
    this.type = this.$route.query?.type
    if (this.$route.path.startsWith("/app")&&this.$route.query&&this.$route.query.openid) {
      useUserXcxStore().setOpenid(this.$route.query?.openid)
    }
  }
}
</script>
