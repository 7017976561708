import myaxios from "./myaxios";
import axios from "axios";

const api = {


    //提交
    //获取荣华医院详细信息cms
    getRHYYInfo: () => {
        return axios.get('/jszyy/hospital/info.json', {});
        // return axios.get('https://api.zhycit.com/jszyy/hospital/info.json')
    },

    //获取小程序信息
    getXCXInfo: () => {
        return axios.get('/jszyy/xcx/indexInfo.json', {});
        // return axios.get('https://api.zhycit.com/jszyy/xcx/indexInfo.json')
    },

    //获取小程序信息
    getRegistrationNotice: () => {
        return myaxios.get('/rhzxyy/rhyyz/registrationNotice', {});
        // return myaxios.get('http://localhost:59016/rhzxyy/rhyyz/registrationNotice')
    },

    //获取小程序信息
    getUserInfo: (openid) => {
        return myaxios.get(`/rhzxyy/user/${openid}`, {});
        // return myaxios.get(`http://localhost:59016/rhzxyy/user/${openid}`)
    },

    getDoctorList:(doctorParamsDto) => {
        return myaxios.post("/rhzxyy/doctor/queryList", doctorParamsDto)
        // return myaxios.post("http://localhost:59016/rhzxyy/doctor/queryList", doctorParamsDto)
    },

    //获取his中医生排班列表
    getSchedulingList:(ysid) => {
        return myaxios.get(`/rhzxyy/scheduling/queryList/${ysid}`)
        // return myaxios.get(`http://localhost:59016/rhzxyy/scheduling/queryList/${ysid}`)
    },

    getDoctorById:(ysid) => {
        return myaxios.get(`/rhzxyy/doctor/${ysid}`)
        // return myaxios.get(`http://localhost:59016/rhzxyy/doctor/${ysid}`)
    },

    //出诊表详情
    getSchedulingDetail:(scId) => {
        return myaxios.get(`/rhzxyy/scheduling/${scId}`)
        // return myaxios.get(`http://localhost:59016/rhzxyy/scheduling/${scId}`)
    },

    //获取appid和uri
    getGoLoginHome:() => {
        return axios.get('/rhzxyy/auth/goLoginJszyy')
        // return axios.get('http://localhost:59016/rhzxyy/auth/goLoginJszyy')
    },

    //保存患者信息
    savePatientInfo:(info) => {
        return myaxios.post('/rhzxyy/patient/saveInfo', info)
        // return myaxios.post('http://localhost:59016/rhzxyy/patient/saveInfo', info)
    },

    //查询所有患者
    findAllPatientInfo:(openId) => {
        return myaxios.get('/rhzxyy/patient/queryList', {params: {openId: openId}})
        // return myaxios.get('http://localhost:59016/rhzxyy/patient/queryList', {params: {openId: openId}})
    },

    //根据患者_id查询患者信息
    findPatientInfoBy_Id:(id) => {
        return myaxios.get('/rhzxyy/patient/query1', {params: {id: id}})
        // return myaxios.get('http://localhost:59016/rhzxyy/patient/query1', {params: {id: id}})
    },

    //预约
    appointment:(info) => {
        return myaxios.post('/rhzxyy/appointment/yuyue', info)
        // return myaxios.post('http://localhost:59016/rhzxyy/appointment/yuyue', info)
    },

    //h5预约
    appointmentH5:(info) => {
        return myaxios.post('/rhzxyy/wx-pay/pay', info)
        // return myaxios.post('http://localhost:59016/rhzxyy/wx-pay/pay', info)
    },

    //取消预约
    cancelAppointment:(openId, xh) => {
        return myaxios.get('/rhzxyy/appointment/cancelAppointment', {params: {openId: openId, xh: xh}})
        // return myaxios.get('http://localhost:59016/rhzxyy/appointment/cancelAppointment', {params: {openId: openId, xh: xh}})
    },

    //取消预约(退款)
    cancelAppointmentRefunds:(openId, xh, orderNo, reason) => {
        return myaxios.get('/rhzxyy/wx-pay/refunds', {params: {openId: openId, xh: xh, orderNo: orderNo, reason: reason}})
        // return myaxios.get('http://localhost:59016/rhzxyy/wx-pay/refunds', {params: {openId: openId, xh: xh, orderNo: orderNo, reason: reason}})
    },

    //查询所有患者
    findAllAppointment:(openId) => {
        return myaxios.get('/rhzxyy/appointment/queryList', {params: {openId: openId}})
        // return myaxios.get('http://localhost:59016/rhzxyy/appointment/queryList', {params: {openId: openId}})
    },

    //分页查询新闻列表
    findNewsList(pageSize, page){
        // return myaxios.get("http://localhost:59016/rhzxyy/gzhNews/newsList", {params: {pageSize: pageSize, page: page}})
        return myaxios.get("/rhzxyy/gzhNews/newsList", {params: {pageSize: pageSize, page: page}})
    },

    //根据id查询新闻
    findNewsById(id){
        return myaxios.get("/rhzxyy/gzhNews/newsById", {params: {id: id}})
        // return myaxios.get("http://localhost:59016/rhzxyy/gzhNews/newsById", {params: {id: id}})
    },

    //看板预约信息
    getAppointment(){
        return myaxios.get("/rhzxyy/appointment/getAppointment")
        // return myaxios.get("http://localhost:59016/rhzxyy/appointment/getAppointment")
    },

    //查询是否支付完成
    findOrderStatus(orderId){
        return myaxios.get(`/rhzxyy/appointment/query-order-status/${orderId}`)
    },

    //查询是否登录
    isLogin(openid){
        return myaxios.get(`/rhzxyy/auth/isLogin/${openid}`)
    }


}

export default api;