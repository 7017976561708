import { createWebHistory, createRouter } from "vue-router";

import HomePageOne from "../components/Pages/HomePageOne";
import HomePageTwo from "../components/Pages/HomePageTwo";
import HomePageThree from "../components/Pages/HomePageThree";
import AboutPage from "../components/Pages/AboutPage";
import DentistPage from "../components/Pages/DentistPage";
import DentistDetailsPage from "../components/Pages/DentistDetailsPage";
import ServicesPage from "../components/Pages/ServicesPage";
import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
import AppointmentPage from "../components/Pages/AppointmentPage";
import PricingPage from "../components/Pages/PricingPage";
import TestimonialsPage from "../components/Pages/TestimonialsPage";
import GalleryPage from "../components/Pages/GalleryPage";
import LoginPage from "../components/Pages/LoginPage";
import RegisterPage from "../components/Pages/RegisterPage";
import FaqPage from "../components/Pages/FaqPage";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";
import BlogPage from "../components/Pages/BlogPage";
import BlogRightSidebarPage from "../components/Pages/BlogRightSidebarPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import ContactUsPage from "../components/Pages/ContactUsPage";
import RoomPage from "@/components/Pages/RoomPage.vue";
import RoomDetailsPage from "@/components/Pages/RoomDetailsPage.vue";
import medicalGuidelinesPages from "@/components/Pages/MedicalGuidelinesPages.vue";
import HospitalRegistPage from "@/components/Pages/HospitalRegistPage.vue";
import toHospitalnNavPage from "@/components/Pages/ToHospitalnNavPage.vue";
import AppointmentDoctorPage from "@/components/Pages/DoctorAppointmentPage.vue"
import SelectPatientPage from "@/components/Pages/SelectPatientPage.vue";
import AddPatientPage from "@/components/Pages/AddPatientPage.vue";
import PersonCenterPage from "@/components/Pages/PersonCenterPage.vue";
import MyAppointmentPage from "@/components/Pages/MyAppointmentPage.vue";
import Screen from "@/components/screen/Screen.vue";

const routes = [
  { path: "/", name: "HomePageOne", component: HomePageOne },
  { path: "/home-two", name: "HomePageTwo", component: HomePageTwo },
  { path: "/home-three", name: "HomePageThree", component: HomePageThree },
  { path: "/about-us", name: "AboutPage", component: AboutPage },
  { path: "/medical-guidelines", name: "MedicalGuidelines", component: medicalGuidelinesPages },
  { path: "/to-hospital-nav", name: "ToHospitalnNav", component: toHospitalnNavPage },
  { path: "/dentist", name: "DentistPage", component: DentistPage },
  {
    path: "/dentist-details",
    name: "DentistDetailsPage",
    component: DentistDetailsPage,
  },
  {
    path: "/registration", name: "HospitalRegistPage", component: HospitalRegistPage
  },
  {
    path: "/rooms", name: "RoomPage", component: RoomPage
  },
  {
    path: "/room-details", name: "RoomDetailsPage", component: RoomDetailsPage
  },
  { path: "/services", name: "ServicesPage", component: ServicesPage },
  {
    path: "/services-details",
    name: "ServicesDetailsPage",
    component: ServicesDetailsPage,
  },
  { path: "/login", name: "AppointmentPage", component: AppointmentPage },
  // { path: "/appointment", name: "AppointmentPage", component: AppointmentPage },
  { path: "/pricing", name: "PricingPage", component: PricingPage },
  {
    path: "/testimonials",
    name: "TestimonialsPage",
    component: TestimonialsPage,
  },
  { path: "/gallery", name: "GalleryPage", component: GalleryPage },
  // { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/register", name: "RegisterPage", component: RegisterPage },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  {
    path: "/terms-of-service",
    name: "TermsOfServicePage",
    component: TermsOfServicePage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  { path: "/coming-soon", name: "ComingSoonPage", component: ComingSoonPage },
  { path: "/blog", name: "BlogPage", component: BlogPage },
  {
    path: "/blog-right-sidebar",
    name: "BlogRightSidebarPage",
    component: BlogRightSidebarPage,
  },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  { path: "/contact-us", name: "ContactUsPage", component: ContactUsPage },
  { path: "/yuyue-doctor", name: "AppointmentDoctor", component: AppointmentDoctorPage},
  { path: "/select-patient", name: "SelectPatientPage", component: SelectPatientPage},
  { path: "/add-patient", name: "AddPatientPage", component: AddPatientPage},
  { path: "/person-center", name: "PersonCenterPage", component: PersonCenterPage},
  { path: "/my-appointment", name: "MyAppointmentPage", component: MyAppointmentPage},
  { path: "/screen", name: "Screen", component: Screen},
  { path: "/board/screen", name: "ScreenBoard", component: Screen},


  //小程序链接
  { path: "/app/about-us", name: "AboutPageApp", component: AboutPage },
  { path: "/app/medical-guidelines", name: "MedicalGuidelinesApp", component: medicalGuidelinesPages },
  { path: "/app/to-hospital-nav", name: "ToHospitalnNavApp", component: toHospitalnNavPage },
  { path: "/app/dentist", name: "DentistPageApp", component: DentistPage },
  {
    path: "/app/dentist-details",
    name: "DentistDetailsPageApp",
    component: DentistDetailsPage,
  },
  {
    path: "/app/rooms", name: "RoomPageApp", component: RoomPage
  },
  {
    path: "/app/room-details", name: "RoomDetailsPageApp", component: RoomDetailsPage
  },
  { path: "/app/blog", name: "BlogPageApp", component: BlogPage },
  {
    path: "/app/blog-details",
    name: "BlogDetailsPageApp",
    component: BlogDetailsPage,
  },
  { path: "/app/login", name: "AppointmentPageApp", component: AppointmentPage },
  { path: "/app/yuyue-doctor", name: "AppointmentDoctorApp", component: AppointmentDoctorPage},
  { path: "/app/select-patient", name: "SelectPatientPageApp", component: SelectPatientPage},
  { path: "/app/add-patient", name: "AddPatientPageApp", component: AddPatientPage},
  { path: "/app/person-center", name: "PersonCenterPageApp", component: PersonCenterPage},
  { path: "/app/my-appointment", name: "MyAppointmentPageApp", component: MyAppointmentPage},
  { path: "/app/screen", name: "ScreenApp", component: Screen},

];

const router = createRouter({
  history: createWebHistory("/jsz-zrin/"),
  base: process.env.NODE_ENV === "production" ? "/jsz-zrin/" : "/",
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
