<template>
    <div class="review-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">
                    <i class="flaticon-hashtag-symbol"></i>
                    Our Clients Review
                </span>
                <h2>Real Review From Our Real Customer</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-review-item mb-30">
                        <div class="icon">
                            <i class="flaticon-left-quote"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <div class="review-info">
                            <img src="../../assets/images/review/review-1.jpg" alt="image">
                            <h3>Dr. Sarah Taylor</h3>
                            <span>Nephrologists</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-review-item mb-30">
                        <div class="icon">
                            <i class="flaticon-left-quote"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <div class="review-info">
                            <img src="../../assets/images/review/review-2.jpg" alt="image">
                            <h3>Dr. Aiken Ward</h3>
                            <span>Endocrinologists</span>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6">
                    <div class="single-review-item mb-30">
                        <div class="icon">
                            <i class="flaticon-left-quote"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <div class="review-info">
                            <img src="../../assets/images/review/review-3.jpg" alt="image">
                            <h3>Dr. Eachann Jhon</h3>
                            <span>Cardiologists</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-review-item mb-30">
                        <div class="icon">
                            <i class="flaticon-left-quote"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <div class="review-info">
                            <img src="../../assets/images/review/review-4.jpg" alt="image">
                            <h3>Dr. Smith Jorge</h3>
                            <span>General Dentist</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-review-item mb-30">
                        <div class="icon">
                            <i class="flaticon-left-quote"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <div class="review-info">
                            <img src="../../assets/images/review/review-5.jpg" alt="image">
                            <h3>Dr. Aston Alison</h3>
                            <span>Orthodontist</span>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6">
                    <div class="single-review-item mb-30">
                        <div class="icon">
                            <i class="flaticon-left-quote"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <div class="review-info">
                            <img src="../../assets/images/review/review-6.jpg" alt="image">
                            <h3>Dr. Moris Johonson</h3>
                            <span>Periodontist</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="/testimonials" class="prev page-numbers">
                            <i class='flaticon-left-arrow'></i>
                        </a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="/testimonials" class="page-numbers">2</a>
                        <a href="/testimonials" class="page-numbers">3</a>
                        <a href="/testimonials" class="page-numbers">4</a>
                        <a href="/testimonials" class="page-numbers">5</a>
                        <a href="/testimonials" class="next page-numbers">
                            <i class='flaticon-right-arrow'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Testimonials'
}
</script>