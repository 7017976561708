<template>
    <div class="appointment-area ptb-100">
        <div class="container">
            <div class="login-form">
                <h4>预约挂号</h4>

              <form>
                <div class="form-group">
                  <label>身份证号</label>
                  <input type="text" class="form-control" placeholder="请输入身份证号">
                </div>

                <div class="form-group">
                  <label>手机号</label>
                  <input type="text" class="form-control" placeholder="请输入手机号">
                </div>

                <div class="form-group">
                  <label>姓名</label>
                  <input type="text" class="form-control" placeholder="请输入姓名">
                </div>

<!--                <div class="row align-items-center">-->
<!--                  <div class="col-lg-6 col-md-6 col-sm-6">-->
<!--                    <div class="form-check">-->
<!--                      <input type="checkbox" class="form-check-input" id="checkme">-->
<!--                      <label class="form-check-label" for="checkme">Remember me</label>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password">-->
<!--                    <router-link to="/register" class="lost-your-password">-->
<!--                      Forgot your password?-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </div>-->

                <button type="submit">点击预约</button>
              </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MakeAnAppointment'
}
</script>